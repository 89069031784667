import usFlag from '../assets/images/flags/us.jpg';

const languages = {
    en: {
        label: 'English',
        flag: usFlag,
    },
};

export default languages;
