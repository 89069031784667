import { UPLOAD_PRODUCT_IMAGES, UPLOAD_PRODUCT_IMAGES_SUCCESS, UPLOAD_PRODUCT_IMAGES_FAIL, UPLOAD_PRODUCT_IMAGES_RESET } from './constants';
import { notification } from '../../../components/Utils/Notification';
import { ProductImageState } from './state';

const productImageInitialState = {
    loading: false,
    success: false,
    error: null,
    images: [],
};

export const uploadProductImageReducer = (state: ProductImageState = productImageInitialState, action: any) => {
    switch (action.type) {
        case UPLOAD_PRODUCT_IMAGES:
            return { loading: true };
        case UPLOAD_PRODUCT_IMAGES_SUCCESS:
            notification('success', 'Product Images has been successfully uploaded');
            return { loading: false, success: true, images: action.payload };
        case UPLOAD_PRODUCT_IMAGES_FAIL:
            notification('error', 'Product upload failed');
            return { loading: false, error: action.payload };
        case UPLOAD_PRODUCT_IMAGES_RESET:
            return {};
        default:
            return state;
    }
};
