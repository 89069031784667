import React from 'react';
//i18n
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Col, Container, Row } from 'reactstrap';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useDashboardDataQuery } from '../../generated/graphql';
import ActivityComp from './ActivityComp';
import FKAData from './FKAData';
import FKLData from './FKLData';
import FLDData from './FLDData';
import LatestTransaction from './LatestTransaction';
import NotificationCard from './NotificationCard';
import SalesAnalytic from './SalesAnalytic';
import TotalEarning from './TotalEarning';

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
    const { t } = useTranslation();
    const { data, loading } = useDashboardDataQuery();

    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={t('Footykids')} breadcrumbItem={t('Dashboard')} />
                    {!loading && data?.dashboardData ? (
                        <>
                            <Row>
                                <Col xl='4'>
                                    <Row>
                                        <TotalEarning totalTransactions={data.dashboardData.totalTransactions} />
                                        <FKAData FKAData={data.dashboardData.FKAData} />
                                        <FKLData FKLData={data.dashboardData.FKLData} />
                                    </Row>
                                </Col>
                                <Col xl='8'>
                                    <FLDData FLDData={data.dashboardData.FLDData} />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl='4'>
                                    <NotificationCard notifications={data.dashboardData.notifications} />
                                </Col>
                                <Col xl='4'>
                                    <ActivityComp />
                                </Col>
                                <Col xl='4'>
                                    <SalesAnalytic salesAnalytics={data.dashboardData.salesAnalytics} />
                                </Col>
                            </Row>

                            <Row>
                                <Col lg='12'>
                                    <LatestTransaction transactions={data.dashboardData.latestTransactions} />
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <div className='text-center mt-4'>
                            <Loader type='ThreeDots' color='primary' height={80} width={80} timeout={0} />
                        </div>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
