import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
//i18n
import { withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
// users
import defaultUserImage from '../../../assets/images/brands/fk.svg';
import { useAdminQuery, useLogoutMutation } from '../../../generated/graphql';

interface ProfileMenuProps {
    success?: any;
    t: any;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ t }) => {
    // Declare a new state variable, which we'll call "menu"
    const history = useHistory();
    const [menu, setMenu] = useState(false);
    const [username, setUsername] = useState<string | undefined>('');

    const { data } = useAdminQuery({ fetchPolicy: 'network-only' });
    const [logout] = useLogoutMutation();
    const apolloClient = useApolloClient();

    useEffect(() => {
        if (data && data.admin) {
            setUsername(data.admin.username);
        }
    }, [data]);

    const logoutHandler = async () => {
        await logout();
        await apolloClient.clearStore();
        history.push('/');
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
                <DropdownToggle className='btn header-item waves-effect' id='page-header-user-dropdown' tag='button'>
                    {data?.admin?.profileImage ? (
                        <img className='rounded-circle header-profile-user' src={data.admin.profileImage} alt={data.admin.username} />
                    ) : (
                        <img className='rounded-circle header-profile-user' src={defaultUserImage} alt='FK' />
                    )}
                    <span className='d-none d-xl-inline-block ms-2 me-1'>{username}</span>
                    <i className='mdi mdi-chevron-down d-none d-xl-inline-block' />
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                    <DropdownItem tag='a' href='/profile' disabled={true}>
                        <i className='bx bx-user font-size-16 align-middle me-1' />
                        {t('Profile')}
                    </DropdownItem>
                    <DropdownItem tag='a' href='#' disabled={true}>
                        <span className='badge badge-success float-end'>11</span>
                        <i className='bx bx-wrench font-size-17 align-middle me-1' />
                        {t('Settings')}
                    </DropdownItem>
                    <div className='dropdown-divider' />
                    <Link to='#' onClick={logoutHandler} className='dropdown-item'>
                        <i className='bx bx-power-off font-size-16 align-middle me-1 text-danger' />
                        <span>{t('Logout')}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default withTranslation()(ProfileMenu);
