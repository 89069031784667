import { ProductVariationDataInput, ProductVariationInput } from '../generated/graphql';
import { ProductFormValues, ProductVariationData, ProductVariationFormValues } from '@footykids/common';

export const transformProductValues = (values: ProductFormValues) => {
    const { category, variations, discount, ...otherFields } = values;
    const categories = values.category?.map((value: any) => value.value);
    const finalVariations = transformProductVariations(values.variations);

    return {
        name: otherFields.name,
        description: otherFields.description,
        longDescription: otherFields.longDescription,
        category: categories,
        brand: otherFields.brand,
        isDiscount: otherFields.isDiscount,
        discount: Number(discount),
        sku: otherFields.sku,
        variations: finalVariations,
        metaTitle: otherFields.metaTitle,
        metaKeywords: otherFields.metaKeywords,
        metaDescription: otherFields.metaDescription,
    };
};

export const transformProductVariations = (variations: ProductVariationFormValues[]) => {
    let finalVariationArr: ProductVariationInput[] = [];

    variations.map((variation: ProductVariationFormValues) => {
        const { variationData, ...otherFields } = variation;

        let finalVariationDataArr: ProductVariationDataInput[] = [];
        variationData.map((data: ProductVariationData) => {
            return finalVariationDataArr.push({
                id: data.id,
                value: data.value,
                unitPrice: Number(data.unitPrice),
                unitWeight: Number(data.unitWeight),
                unitsInStock: Number(data.unitsInStock),
            });
        });

        return finalVariationArr.push({
            id: otherFields.id,
            name: otherFields.name,
            variationData: finalVariationDataArr,
        });
    });

    return finalVariationArr;
};
