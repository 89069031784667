import { FLDIsMaintenanceType } from '@footykids/common';
import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from '../../../../components/Common/InputField';
import { notification } from '../../../../components/Utils/Notification';
import { useUpdateFldIsMaintenanceMutation } from '../../../../generated/graphql';

interface FLDIsMaintenanceFormProps {
    isMaintenance?: any;
}

const FLDIsMaintenanceForm: React.FC<FLDIsMaintenanceFormProps> = ({ isMaintenance }) => {
    const [onEdit, setOnEdit] = useState<boolean>(false);
    const [isMaintenanceValue, setIsMaintenanceValue] = useState<boolean>(false);
    const [setIsMaintenance] = useUpdateFldIsMaintenanceMutation();

    useEffect(() => {
        if (isMaintenance && isMaintenance.value) {
            const data: FLDIsMaintenanceType = JSON.parse(isMaintenance.value);
            if (data && data.isMaintenance) {
                setIsMaintenanceValue(data.isMaintenance);
            }
        }
    }, [isMaintenance]);

    return (
        <React.Fragment>
            <Col xs={12} className='mb-4'>
                <FormGroup row>
                    <Label className='col-lg-2'>
                        <span className='fw-bold'>Set FLD Store Website Status</span>
                        <br />
                        <small className='text-danger'>
                            This is use to set FLD Store to maintenance mode when payment provider is on maintenance.
                        </small>
                    </Label>
                    <Col lg='10'>
                        <Formik
                            enableReinitialize
                            initialValues={{ isMaintenance: isMaintenanceValue }}
                            onSubmit={async (values) => {
                                try {
                                    await setIsMaintenance({
                                        variables: values,
                                        update: (cache: any) => {
                                            cache.evict({ fieldName: 'getFLDSettings' });
                                        },
                                    }).then(() => setOnEdit(false));
                                } catch (error) {
                                    notification('error', error.message);
                                }
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <Row>
                                        <Col md={3} className='pr-0'>
                                            <InputField
                                                switches
                                                name='isMaintenance'
                                                placeholder='FLD is on maintenance?'
                                                label='FLD Store Website Status'
                                                switchLabel='FLD Store website is on maintenance?'
                                                type='checkbox'
                                                required
                                                disabled={!onEdit}
                                            />
                                        </Col>
                                        <Col md={3} className='pl-0 align-self-center'>
                                            {onEdit ? (
                                                <Button color='success' className='mt-2 mt-md-0' type='submit' disabled={isSubmitting}>
                                                    Save
                                                </Button>
                                            ) : (
                                                <Button
                                                    color='primary'
                                                    className='mt-2 mt-md-0'
                                                    type='button'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOnEdit(true);
                                                    }}
                                                >
                                                    Edit FLD Store Status
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </FormGroup>
            </Col>
        </React.Fragment>
    );
};

export default FLDIsMaintenanceForm;
