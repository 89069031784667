import { convertCentsToPrice } from '@footykids/common';
import React from 'react';
import Loader from 'react-loader-spinner';
import { Table } from 'react-super-responsive-table';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useOrderQuery } from '../../../../generated/graphql';
import { serveImage } from '../../../../util/Helper';

interface OrderDetailsModalProps {
    orderId: number;
    isOpen: boolean;
    toggle: any;
}

const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({ orderId, isOpen, toggle }) => {
    const { data: orderData, loading: orderLoading } = useOrderQuery({ variables: { id: orderId } });

    return (
        <Modal isOpen={isOpen} role='dialog' autoFocus={true} centered={true} tabIndex={-1} toggle={toggle}>
            <div className='modal-content'>
                <ModalHeader toggle={toggle}>Order Details</ModalHeader>
                <ModalBody>
                    {orderLoading ? (
                        <div className='text-center'>
                            <Loader type='ThreeDots' color='primary' height={35} width={35} timeout={0} />
                        </div>
                    ) : (
                        orderData &&
                        orderData.order &&
                        orderData.order.orderBilling &&
                        orderData.order.orderProduct && (
                            <>
                                <div className='table-responsive'>
                                    <Table className='table table-nowrap'>
                                        <tbody>
                                            <tr>
                                                <td width='20%'>Order ID:</td>
                                                <td width='80%'>
                                                    <span className='text-primary'>#FLD-{orderData.order.orderNo}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width='20%'>Billing Name:</td>
                                                <td width='80%'>
                                                    <span className='text-primary'>{orderData.order.billingName}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width='20%' style={{ verticalAlign: 'top' }}>
                                                    Shipping Details:
                                                </td>
                                                <td width='80%'>
                                                    {orderData.order.orderBilling.shipToDifferentAddress ? (
                                                        <p className='text-primary'>
                                                            {orderData.order.orderBilling.shippingHouseUnit ? (
                                                                <>
                                                                    {orderData.order.orderBilling.shippingHouseUnit}
                                                                    <br />
                                                                </>
                                                            ) : null}
                                                            {orderData.order.orderBilling.shippingAddressLine1} <br />
                                                            {orderData.order.orderBilling.shippingAddressLine2 ? (
                                                                <>
                                                                    {orderData.order.orderBilling.shippingAddressLine2}
                                                                    <br />
                                                                </>
                                                            ) : null}
                                                            {orderData.order.orderBilling.shippingPostalCode} <br />
                                                            {orderData.order.orderBilling.shippingCity} <br />
                                                            {orderData.order.orderBilling.shippingState} <br />
                                                            {orderData.order.orderBilling.shippingCountry} <br />
                                                        </p>
                                                    ) : (
                                                        <p className='text-primary'>
                                                            {orderData.order.orderBilling.houseUnit ? (
                                                                <>
                                                                    {orderData.order.orderBilling.houseUnit}
                                                                    <br />
                                                                </>
                                                            ) : null}
                                                            {orderData.order.orderBilling.addressLine1} <br />
                                                            {orderData.order.orderBilling.addressLine2 ? (
                                                                <>
                                                                    {orderData.order.orderBilling.addressLine2}
                                                                    <br />
                                                                </>
                                                            ) : null}
                                                            {orderData.order.orderBilling.postalCode} <br />
                                                            {orderData.order.orderBilling.city} <br />
                                                            {orderData.order.orderBilling.state} <br />
                                                            {orderData.order.orderBilling.country} <br />
                                                        </p>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>

                                <div className='table-responsive' style={{ overflow: 'auto', maxHeight: '500px' }}>
                                    <Table className='table align-middle table-nowrap'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>Product</th>
                                                <th scope='col'>Product Name</th>
                                                <th scope='col'>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderData.order.orderProduct.map((product, idx) => (
                                                <tr key={idx}>
                                                    <th scope='row'>
                                                        <div>
                                                            <img src={serveImage(product.image)} alt='' className='avatar-sm' />
                                                        </div>
                                                    </th>
                                                    <td>
                                                        <div>
                                                            <h5 className='text-truncate font-size-14'>{product.name}</h5>
                                                            <p className='text-muted mb-0'>
                                                                {product.orderProductVariation.map((variation, idx, arr) => {
                                                                    return `${variation.name}: ${variation.value}${
                                                                        idx === arr.length - 1 ? '' : ', '
                                                                    }`;
                                                                })}
                                                            </p>
                                                            <p className='text-muted mb-0'>Quantity: {product.quantity}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        RM{' '}
                                                        {product.isDiscount
                                                            ? convertCentsToPrice(product.unitPrice - product.discount)
                                                            : convertCentsToPrice(product.unitPrice)}
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan={2}>
                                                    <h6 className='m-0 text-end'>Sub Total:</h6>
                                                </td>
                                                <td>
                                                    RM{' '}
                                                    {convertCentsToPrice(
                                                        orderData.order.orderProduct.reduce(
                                                            (acc, product) => product.unitPrice * product.quantity + acc,
                                                            0
                                                        )
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <h6 className='m-0 text-end'>Discount:</h6>
                                                </td>
                                                <td>RM {convertCentsToPrice(orderData.order.totalDiscount)}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <h6 className='m-0 text-end'>Total:</h6>
                                                </td>
                                                <td>RM {convertCentsToPrice(orderData.order.totalPrice)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                        )
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button type='button' color='secondary' onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    );
};

export default OrderDetailsModal;
