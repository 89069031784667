// MetisMenu
import MetisMenu from 'metismenujs';
import React, { useEffect, useRef } from 'react';
//i18n
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
//Import Scrollbar
import SimpleBar from 'simplebar-react';
import { useAdminQuery, useUnreadNotificationsQuery } from '../../generated/graphql';

interface SidebarContentProps {
    location?: any;
    t?: any;
}

const SidebarContent: React.FC<SidebarContentProps & RouteComponentProps & WithTranslationProps> = ({ location, t, ...rest }) => {
    const ref = useRef() as any;
    const { data: notificationData } = useUnreadNotificationsQuery();
    const { data: userData } = useAdminQuery();

    useEffect(() => {
        const pathName = location.pathname;

        const initMenu = () => {
            new MetisMenu('#side-menu');
            let matchingMenuItem = null;
            const ul = document.getElementById('side-menu')!;
            const items = ul.getElementsByTagName('a')!;
            for (let i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        initMenu();
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        ref.current.recalculate();
    });

    function scrollElement(item: any) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    function activateParentDropdown(item: any) {
        item.classList.add('active');
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show');
        }

        if (parent) {
            parent.classList.add('mm-active');
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add('mm-show'); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add('mm-active'); // li
                    parent3.childNodes[0].classList.add('mm-active'); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add('mm-show'); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add('mm-show'); // li
                            parent5.childNodes[0].classList.add('mm-active'); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }

    return (
        <React.Fragment>
            <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
                <div id='sidebar-menu'>
                    <ul className='metismenu list-unstyled' id='side-menu'>
                        <li className='menu-title'>{t('Menu')} </li>
                        <li>
                            <Link to='/dashboard' className='waves-effect'>
                                <i className='bx bx-home-circle'></i>
                                <span>{t('Dashboard')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/notifications' className='waves-effect'>
                                <i className='bx bxs-notification'></i>
                                {notificationData && notificationData.unreadNotifications.totalUnread > 0 && (
                                    <span className='badge rounded-pill bg-danger float-end'>
                                        {notificationData.unreadNotifications.totalUnread}
                                    </span>
                                )}
                                <span>{t('Notifications')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/news' className='waves-effect'>
                                <i className='bx bx-news'></i>
                                <span>{t('News')}</span>
                            </Link>
                        </li>
                        {userData?.admin?.adminType === 'SUPER' && (
                            <li>
                                <Link to='/user' className='waves-effect'>
                                    <i className='bx bx-user'></i>
                                    <span>{t('User')}</span>
                                </Link>
                            </li>
                        )}

                        {/* FK ACADEMY */}
                        <li className='menu-title'>{t('FK Academy')}</li>
                        <li>
                            <Link to='/academy-venues' className='waves-effect'>
                                <i className='bx bx-layer'></i>
                                <span>{t('Programs')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/academy-venues' className='waves-effect'>
                                <i className='mdi mdi-soccer-field'></i>
                                <span>{t('Venues')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/academy-players' className='waves-effect'>
                                <i className='mdi mdi-account-star'></i>
                                <span>{t('Players')}</span>
                            </Link>
                        </li>

                        {/* FK LEAGUE */}
                        <li className='menu-title'>{t('FK League')}</li>
                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bx-football'></i>
                                <span>{t('Leagues')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/league/category'>{t('Categories')}</Link>
                                </li>
                                <li>
                                    <Link to='/league/league'>{t('Leagues')}</Link>
                                </li>
                                <li>
                                    <Link to='/league/teams'>{t('Teams')}</Link>
                                </li>
                                <li>
                                    <Link to='/league/venues'>{t('Venues')}</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to='/#' className='waves-effect'>
                                <i className='bx bx-calendar-event'></i>
                                <span>{t('Fixtures')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/#' className='waves-effect'>
                                <i className='mdi mdi-whistle-outline'></i>
                                <span>{t('Results')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/#' className='waves-effect'>
                                <i className='bx bx-table'></i>
                                <span>{t('Standings')}</span>
                            </Link>
                        </li>

                        {/* FLD Store */}
                        <li className='menu-title'>{t('FLD Store')}</li>
                        <li>
                            <Link to='/orders' className='waves-effect'>
                                <i className='mdi mdi-cash-usd'></i>
                                <span>{t('Orders')}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bx-image'></i>
                                <span>{t('Banner')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/homepage-banner'>{t('Homepage Banner')}</Link>
                                </li>
                                <li>
                                    <Link to='/secondary-banner'>{t('Secondary Banner')}</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bxs-t-shirt'></i>
                                <span>{t('Products')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/product/list'>{t('Product List')}</Link>
                                </li>
                                <li>
                                    <Link to='/product/category'>{t('Product Category')}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to='/promotion-code' className='waves-effect'>
                                <i className='bx bxs-discount'></i>
                                <span>{t('Promotion Code')}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bx-slider'></i>
                                <span>{t('Settings')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/fld-settings'>{t('All Settings')}</Link>
                                </li>
                                <li>
                                    <Link to='/fld-faq'>{t('FAQ')}</Link>
                                </li>
                            </ul>
                        </li>

                        {/* <li className='menu-title'>Pages</li>
                        <li>
                            <Link to='/#' className='waves-effect'>
                                <i className='bx bx-user-circle'></i>
                                <span className='badge badge-pill badge-success float-end'>{t('New')}</span>
                                <span>{t('Authentication')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/pages-login'>{t('Login')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-login-2'>{t('Login 2')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-register'>{t('Register')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-register-2'>{t('Register 2')}</Link>
                                </li>
                                <li>
                                    <Link to='/page-recoverpw'>{t('Recover Password')}</Link>
                                </li>
                                <li>
                                    <Link to='/page-recoverpw-2'>{t('Recover Password 2')}</Link>
                                </li>
                                <li>
                                    <Link to='/auth-lock-screen'>{t('Lock Screen')}</Link>
                                </li>
                                <li>
                                    <Link to='/auth-lock-screen-2'>{t('Lock Screen 2')}</Link>
                                </li>
                                <li>
                                    <Link to='/page-confirm-mail'>{t('Confirm Mail')}</Link>
                                </li>
                                <li>
                                    <Link to='/page-confirm-mail-2'>{t('Confirm Mail 2')}</Link>
                                </li>
                                <li>
                                    <Link to='/auth-email-verification'>{t('Email verification')}</Link>
                                </li>
                                <li>
                                    <Link to='/auth-email-verification-2'>{t('Email verification 2')}</Link>
                                </li>
                                <li>
                                    <Link to='/auth-two-step-verification'>{t('Two step verification')}</Link>
                                </li>
                                <li>
                                    <Link to='/auth-two-step-verification-2'>{t('Two step verification 2')}</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bx-file'></i>
                                <span>{t('Utility')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/pages-starter'>{t('Starter Page')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-maintenance'>{t('Maintenance')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-comingsoon'>{t('Coming Soon')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-timeline'>{t('Timeline')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-faqs'>{t('FAQs')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-pricing'>{t('Pricing')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-404'>{t('Error 404')}</Link>
                                </li>
                                <li>
                                    <Link to='/pages-500'>{t('Error 500')}</Link>
                                </li>
                            </ul>
                        </li>

                        <li className='menu-title'>{t('Components')}</li>

                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bx-tone'></i>
                                <span>{t('UI Elements')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/ui-alerts'>{t('Alerts')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-buttons'>{t('Buttons')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-cards'>{t('Cards')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-carousel'>{t('Carousel')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-dropdowns'>{t('Dropdowns')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-grid'>{t('Grid')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-images'>{t('Images')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-lightbox'>{t('Lightbox')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-modals'>{t('Modals')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-rangeslider'>{t('Range Slider')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-session-timeout'>{t('Session Timeout')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-progressbars'>{t('Progress Bars')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-sweet-alert'>{t('Sweet-Alert')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-tabs-accordions'>{t('Tabs & Accordions')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-typography'>{t('Typography')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-video'>{t('Video')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-general'>{t('General')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-colors'>{t('Colors')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-rating'>{t('Rating')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-notifications'>{t('Notifications')}</Link>
                                </li>
                                <li>
                                    <Link to='/ui-image-cropper'>{t('Image Cropper')}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to='/#' className='waves-effect'>
                                <i className='bx bxs-eraser'></i>
                                <span className='badge badge-pill badge-danger float-end'>10</span>
                                <span>{t('Forms')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/form-elements'>{t('Form Elements')}</Link>
                                </li>
                                <li>
                                    <Link to='/form-layouts'>{t('Form Layouts')}</Link>
                                </li>
                                <li>
                                    <Link to='/form-validation'>{t('Form Validation')}</Link>
                                </li>
                                <li>
                                    <Link to='/form-advanced'>{t('Form Advanced')}</Link>
                                </li>
                                <li>
                                    <Link to='/form-editors'>{t('Form Editors')}</Link>
                                </li>
                                <li>
                                    <Link to='/form-uploads'>{t('Form File Upload')} </Link>
                                </li>
                                <li>
                                    <Link to='/form-xeditable'>{t('Form Xeditable')}</Link>
                                </li>
                                <li>
                                    <Link to='/form-repeater'>{t('Form Repeater')}</Link>
                                </li>
                                <li>
                                    <Link to='/form-wizard'>{t('Form Wizard')}</Link>
                                </li>
                                <li>
                                    <Link to='/form-mask'>{t('Form Mask')}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bx-list-ul'></i>
                                <span>{t('Tables')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/tables-basic'>{t('Basic Tables')}</Link>
                                </li>
                                <li>
                                    <Link to='/tables-datatable'>{t('Data Tables')}</Link>
                                </li>
                                <li>
                                    <Link to='/tables-responsive'>{t('Responsive Table')}</Link>
                                </li>
                                <li>
                                    <Link to='/tables-editable'>{t('Editable Table')}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bxs-bar-chart-alt-2'></i>
                                <span>{t('Charts')}</span>
                            </Link>

                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/apex-charts'>{t('Apex charts')}</Link>
                                </li>
                                <li>
                                    <Link to='/chartist-charts'>{t('Chartist Chart')}</Link>
                                </li>
                                <li>
                                    <Link to='/chartjs-charts'>{t('Chartjs Chart')}</Link>
                                </li>
                                <li>
                                    <Link to='/e-charts'>{t('E Chart')}</Link>
                                </li>
                                <li>
                                    <Link to='/tui-charts'>{t('Toast UI Chart')}</Link>
                                </li>
                                <li>
                                    <Link to='/sparkline-charts'>{t('Sparkline Chart')}</Link>
                                </li>
                                <li>
                                    <Link to='/charts-knob'>{t('Knob Chart')}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bx-aperture'></i>
                                <span>{t('Icons')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/icons-boxicons'>{t('Boxicons')}</Link>
                                </li>
                                <li>
                                    <Link to='/icons-materialdesign'>{t('Material Design')}</Link>
                                </li>
                                <li>
                                    <Link to='/icons-dripicons'>{t('Dripicons')}</Link>
                                </li>
                                <li>
                                    <Link to='/icons-fontawesome'>{t('Font awesome')}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bx-map'></i>
                                <span>{t('Maps')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/maps-google'>{t('Google Maps')}</Link>
                                </li>
                                <li>
                                    <Link to='/maps-vector'>{t('Vector Maps')}</Link>
                                </li>
                                <li>
                                    <Link to='/maps-leaflet'>{t('Leaflet Maps')}</Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to='/#' className='has-arrow waves-effect'>
                                <i className='bx bx-share-alt'></i>
                                <span>{t('Multi Level')}</span>
                            </Link>
                            <ul className='sub-menu'>
                                <li>
                                    <Link to='/#'>{t('Level 1.1')}</Link>
                                </li>
                                <li>
                                    <Link to='/#' className='has-arrow'>
                                        {t('Level 1.2')}
                                    </Link>
                                    <ul className='sub-menu'>
                                        <li>
                                            <Link to='/#'>{t('Level 2.1')}</Link>
                                        </li>
                                        <li>
                                            <Link to='/#'>{t('Level 2.2')}</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(SidebarContent));
