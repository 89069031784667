import { convertCentsToPrice, OrderStatus } from '@footykids/common';
import React from 'react';
import { Badge, Col, Row, Table } from 'reactstrap';
import { OrderDataFragment } from '../../../../generated/graphql';
import { serveImage } from '../../../../util/Helper';
import OrderTimeline from './OrderTimeline';

interface OrderDetailsProps {
    order: OrderDataFragment;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({ order }) => {
    return (
        <Row>
            <Col xs={12} md={4}>
                <div className='table-responsive'>
                    <Table className='table table-nowrap'>
                        <tbody>
                            <tr>
                                <td colSpan={2} className='border-top-0'>
                                    <h5>Order Details</h5>
                                </td>
                            </tr>
                            <tr>
                                <td width='30%'>Order No:</td>
                                <td>
                                    <span className='text-primary'>{order.orderNo}</span>
                                </td>
                            </tr>
                            <tr>
                                <td width='30%'>Order Status:</td>
                                <td>
                                    <span className='text-primary'>
                                        <Badge
                                            className={`font-size-12 badge-soft-${
                                                order.orderStatus === OrderStatus.FAILED ||
                                                order.orderStatus === OrderStatus.SHIPPING_FAILED ||
                                                order.orderStatus === OrderStatus.CANCELED
                                                    ? 'danger'
                                                    : order.orderStatus === OrderStatus.PENDING
                                                    ? 'warning'
                                                    : 'success'
                                            }`}
                                        >
                                            {order.orderStatus}
                                        </Badge>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td width='30%'>Promo Code:</td>
                                <td>
                                    <span className='text-primary'>{order.promoCode ? order.promoCode : '-'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td width='30%'>Total Products:</td>
                                <td>
                                    <span className='text-primary'>{order.totalQuantity}</span>
                                </td>
                            </tr>
                            <tr>
                                <td width='30%'>Total Shipping:</td>
                                <td>
                                    <span className='text-primary'>RM {convertCentsToPrice(order.shippingPrice)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td width='30%'>Total Discount:</td>
                                <td>
                                    <span className='text-primary'>RM {convertCentsToPrice(order.totalDiscount)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td width='30%'>Total Price:</td>
                                <td>
                                    <span className='text-primary'>RM {convertCentsToPrice(order.totalPrice)}</span>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <OrderTimeline orderStatus={order.orderStatus as OrderStatus} />
            </Col>
            <Col md={8} className='mt-4'>
                <div className='table-responsive'>
                    <Table className='table table-centered border table-nowrap'>
                        <thead>
                            <tr>
                                <th scope='col'>Product</th>
                                <th scope='col'>Product Name</th>
                                <th scope='col' className='text-center'>
                                    Quantity
                                </th>
                                <th scope='col' className='text-end'>
                                    Price
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.orderProduct!.map((product, idx) => (
                                <tr key={idx} className='border-bottom'>
                                    <td width='10%'>
                                        <div>
                                            <img src={serveImage(product.image)} alt='' className='avatar-md' />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <h5 className='text-truncate font-size-14'>{product.name}</h5>
                                            <p className='text-muted mb-0'>
                                                {product.orderProductVariation.map((variation, idx, arr) => {
                                                    return `${variation.name}: ${variation.value}${idx === arr.length - 1 ? '' : ', '}`;
                                                })}
                                            </p>
                                        </div>
                                    </td>
                                    <td className='text-center' width='10%'>
                                        x {product.quantity}
                                    </td>
                                    <td className='text-end' width='15%'>
                                        RM{' '}
                                        {product.isDiscount
                                            ? convertCentsToPrice(product.unitPrice - product.discount)
                                            : convertCentsToPrice(product.unitPrice)}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={3} className='border-top-0'>
                                    <h6 className='m-0 text-end'>Sub Total:</h6>
                                </td>
                                <td className='text-end border-top-0'>
                                    RM{' '}
                                    {convertCentsToPrice(
                                        order.orderProduct!.reduce(
                                            (acc, product) =>
                                                product.isDiscount
                                                    ? (product.unitPrice - product.discount) * product.quantity
                                                    : product.unitPrice * product.quantity + acc,
                                            0
                                        )
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3} className='border-top-0'>
                                    <h6 className='m-0 text-end'>Shipping:</h6>
                                </td>
                                <td className='text-end border-top-0'>RM {convertCentsToPrice(order.shippingPrice)}</td>
                            </tr>
                            <tr>
                                <td colSpan={3} className='border-top-0'>
                                    <h6 className='m-0 text-end'>Discount:</h6>
                                </td>
                                <td className='text-end border-top-0'>
                                    {order.totalDiscount > 0 ? `- RM ${convertCentsToPrice(order.totalDiscount)}` : 'RM 0.00'}
                                </td>
                            </tr>
                            <tr className='border-top-0'>
                                <td colSpan={3}>
                                    <h6 className='m-0 text-end'>Total:</h6>
                                </td>
                                <td className='text-end'>RM {convertCentsToPrice(order.totalPrice)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    );
};

export default OrderDetails;
