import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { notification } from '../components/Utils/Notification';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

const middleware = new ApolloLink((operation, forward) => {
    // console.log('Starting', operation);
    return forward(operation);
});

const afterware = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        //   console.log('Completed', operation);
        return response;
    });
});

const errorLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
            return notification('error', message);
        });
    }
    if (networkError) {
        notification('error', networkError.message);
    }
});

const httpLink = createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_URL, credentials: 'include' });
const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_URL,
    options: {
        reconnect: true,
    },
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

export default new ApolloClient({
    link: ApolloLink.from([middleware, afterware, errorLink, splitLink]),
    cache: new InMemoryCache({
        addTypename: true,
    }),
    connectToDevTools: true,
});
