import { convertCentsToPrice } from '@footykids/common';
import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { TotalTransactionData } from '../../generated/graphql';

interface TotalEarningProps {
    totalTransactions: TotalTransactionData;
}

const TotalEarning: React.FC<TotalEarningProps> = ({ totalTransactions }) => {
    return (
        <React.Fragment>
            <Col sm='12'>
                <Card className='text-center'>
                    <CardBody>
                        <CardTitle className='mb-2'>
                            <h2 className='fw-bold'>Total Earning</h2>
                        </CardTitle>
                        <Row>
                            <Col sm='6'>
                                <p className='text-muted'>This month</p>
                                <h3>RM {convertCentsToPrice(totalTransactions.currentMonthPayments)}</h3>
                            </Col>
                            <Col sm='6'>
                                <p className='text-muted'>Total</p>
                                <h3>RM {convertCentsToPrice(totalTransactions.currentTotalPayments)}</h3>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default TotalEarning;
