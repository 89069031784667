import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import PageWrapper from '../../../components/Common/PageWrapper';
import Preloader from '../../../components/Layout/Preloader';
import {
    ProductDataFragment,
    useDeleteProductMutation,
    useProductsQuery,
    useToggleIsPublishProductMutation
} from '../../../generated/graphql';
import ProductListColumns from './Components/ProductListColumn';

const ProductList: React.FC<RouteComponentProps & RouteComponentProps> = ({ ...props }) => {
    const history = useHistory();
    const { search } = props.location;
    const currentQuery = queryString.parse(search);
    const [productList, setProductList] = useState<any>([]);
    const [productSize, setProductSize] = useState<number>(0);
    const [sizePerPage] = useState<number>(15);
    const [keyword, setKeyword] = useState<string>('');
    const pageNumber = Number(currentQuery.pageNumber?.toString()) || 1;
    //const searchKeyword = currentQuery.searchKeyword?.toString() || keyword;

    const [options, setOptions] = useState({
        pageNumber: pageNumber,
    });

    const pageOptions = {
        page: pageNumber,
        sizePerPage: sizePerPage,
        totalSize: productSize,
        custom: true,
    };

    const { data, loading: productLoading } = useProductsQuery();
    const [toggleIsPublishProduct] = useToggleIsPublishProductMutation();
    const [deleteProduct] = useDeleteProductMutation();

    useEffect(() => {
        history.push({ pathname: 'list', search: queryString.stringify(options) });
    }, [options, history]);

    useEffect(() => {
        if (!productLoading && data && data.products) {
            handleProducts(data.products, pageNumber, keyword);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pageNumber, productLoading, keyword]);

    const { SearchBar } = Search;

    const handleTableChange = (type: any, { page, searchText }: any) => {
        if (type === 'search') {
            setKeyword(searchText);
            setOptions({ ...options, pageNumber: 1 });
        }
    };

    const handleProducts = (products: ProductDataFragment[], pageNumber: number, keyword: string) => {
        var productList = products;
        if (keyword !== '') {
            productList = products.filter((product: any) =>
                Object.keys(product).some((key: any) => {
                    return typeof product[key] === 'string' && product[key].toLowerCase().includes(keyword.toLowerCase());
                })
            );
        }
        var newProductList = [];

        for (var i = 0; i < productList.length; ) {
            var page_data = [];
            for (var j = 0; j < sizePerPage && i < productList.length; j++) {
                page_data.push(productList[i]);
                i++;
            }
            newProductList.push(page_data);
        }
        setProductSize(productList.length || 0);
        setProductList(newProductList[pageNumber - 1] || []);
    };

    if (productLoading) {
        return <Preloader />;
    }

    return (
        <PageWrapper pageTitle='Products' breadCrumbTitle='Products' breadCrumbItem='List' pageCategory='FLD Store'>
            <Row>
                <Col lg='12'>
                    <Card>
                        <CardBody>
                            <PaginationProvider pagination={paginationFactory(pageOptions)}>
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField='id'
                                        data={productList}
                                        columns={ProductListColumns(toggleIsPublishProduct, deleteProduct)}
                                        bootstrap4
                                        search
                                    >
                                        {(toolkitProps) => (
                                            <React.Fragment>
                                                <Row className='mb-2'>
                                                    <Col sm='12'>
                                                        <div className='w-100 search-box ms-2 mb-2 d-inline-block'>
                                                            <div className='w-100 position-relative'>
                                                                <SearchBar style={{ width: '100%' }} {...toolkitProps.searchProps} />
                                                                <i className='bx bx-search-alt search-icon' />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {/* <Col sm='8'>
                                                        <div className='text-sm-right'>
                                                            <Button
                                                                type='button'
                                                                color='primary'
                                                                className='btn-rounded waves-effect waves-light mb-2 me-2'
                                                                onClick={() => history.push('/product/add')}
                                                            >
                                                                <i className='mdi mdi-plus me-1' />
                                                                Add New Product
                                                            </Button>
                                                        </div>
                                                    </Col> */}
                                                </Row>
                                                <Row>
                                                    <Col xl='12'>
                                                        <div className='table-responsive'>
                                                            <BootstrapTable
                                                                remote
                                                                bordered={false}
                                                                striped={false}
                                                                classes={'table table-centered table-nowrap'}
                                                                headerWrapperClasses={'table-light'}
                                                                {...toolkitProps.baseProps}
                                                                onTableChange={handleTableChange}
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='align-items-md-center mt-30'>
                                                    <Col className='pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination'>
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                            page={pageNumber}
                                                            onPageChange={(page) => setOptions({ ...options, pageNumber: page })}
                                                        />
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div className='text-sm-right save-changes-button'>
                <Button
                    type='button'
                    color='primary'
                    className='btn-rounded me-1 waves-effect waves-light'
                    onClick={() => history.push('/product/add')}
                >
                    <i className='mdi mdi-plus me-1' />
                    Add New Product
                </Button>
            </div>
        </PageWrapper>
    );
};

export default withRouter(ProductList);
