import { AddProductInitialValues, AddProductSchema } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { BottomRightSaveButton } from '../../../components/Common/BottomRightSaveButton';
import PageWrapper from '../../../components/Common/PageWrapper';
import { notification } from '../../../components/Utils/Notification';
import { useCreateProductMutation } from '../../../generated/graphql';
import { transformProductValues } from '../../../util/ProductFinalValue';
import { toErrorMap } from '../../../util/toErrorMap';
import ProductInfo from './Components/ProductInfo';
import ProductVariation from './Components/ProductVariation';

const AddProduct: React.FC<{}> = () => {
    const history = useHistory();
    const [product] = useState<any>(AddProductInitialValues);

    const [createProduct, { loading: createProductLoading }] = useCreateProductMutation();

    return (
        <PageWrapper pageTitle='Add Product' pageCategory='FLD Store' breadCrumbTitle='Products' breadCrumbItem='Add Product'>
            <Formik
                enableReinitialize
                initialValues={product}
                validationSchema={AddProductSchema}
                onSubmit={async (values, { setErrors }) => {
                    try {
                        const product = transformProductValues(values);
                        const response = await createProduct({
                            variables: { input: product },
                            update: (cache) => {
                                cache.evict({ fieldName: 'products' });
                            },
                        });
                        if (response?.data?.createProduct.errors) {
                            const errors = response.data.createProduct.errors;
                            setErrors(toErrorMap(errors));
                            errors.forEach((err) => {
                                notification('error', err.message);
                            });
                        } else if (response.data?.createProduct.product) {
                            notification('success', 'Product created!');
                            history.push('/product/list');
                        }
                    } catch (error) {
                        notification('error', error.message);
                    }
                }}
            >
                {() => (
                    <Form className='fk-product'>
                        <ProductInfo />
                        <ProductVariation />
                        <BottomRightSaveButton title='Save Product' isLoading={createProductLoading} />
                    </Form>
                )}
            </Formik>
        </PageWrapper>
    );
};

export default withRouter(AddProduct);
