import React from 'react';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import PageWrapper from '../../components/Common/PageWrapper';
import NewsTable from './Components/NewsTable';

interface NewsProps {}

const News: React.FC<NewsProps> = () => {
    return (
        <PageWrapper pageTitle='News' pageCategory='Footykids' breadCrumbTitle='Footykids' breadCrumbItem='News'>
            <Row>
                <Col lg='12'>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs={12} sm={6} md={3} className='align-self-center'>
                                    <div className='app-search p-0'>
                                        <Label htmlFor='search-input'>
                                            Search News: <small>(Press enter to search)</small>
                                        </Label>
                                        <div id='search-input' className='position-relative'>
                                            <input
                                                // value={searchInput}
                                                // onChange={(e) => setSearchInput(e.target.value)}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search...'
                                                // onKeyDown={onSearchKeyword}
                                            />
                                            <span className='bx bx-search-alt' />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={5}></Col>
                                <Col xs={6} sm={6} md={2}>
                                    <div className='form-group row mx-1 mt-3 mt-md-0'>
                                        <Label htmlFor='search-category' className='p-0'>
                                            Category
                                        </Label>
                                        <select
                                            // onChange={(e) => setOrderStatus(e.target.value)}
                                            // defaultValue={orderStatus}
                                            className='form-select'
                                            id='search-category'
                                        >
                                            <option value='ALL'>All</option>
                                            <option value='PENDING'>FKA</option>
                                            <option value='SUCCESSFUL'>FKL</option>
                                            <option value='SUCCESSFUL'>FLD Store</option>
                                            <option value='SUCCESSFUL'>FK Original</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col xs={6} sm={6} md={2}>
                                    <div className='form-group row mx-1 mt-3 mt-md-0'>
                                        <Label htmlFor='search-type' className='p-0'>
                                            Type
                                        </Label>
                                        <select
                                            // onChange={(e) => setOrderStatus(e.target.value)}
                                            // defaultValue={orderStatus}
                                            className='form-select'
                                            id='search-type'
                                        >
                                            <option value='ALL'>All</option>
                                            <option value='PENDING'>Slider</option>
                                            <option value='SUCCESSFUL'>Posts</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className='table-rep-plugin p-2 w-100'>
                                    <NewsTable />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div className='text-sm-right save-changes-button'>
                <Button type='button' color='primary' className='btn-rounded me-1 waves-effect waves-light'>
                    <i className='mdi mdi-plus me-1' />
                    Add News
                </Button>
            </div>
        </PageWrapper>
    );
};

export default News;
