import { convertCentsToPrice, EditProductInitialValues, EditProductSchema } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useHistory, withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { BottomRightSaveButton } from '../../../components/Common/BottomRightSaveButton';
import PageWrapper from '../../../components/Common/PageWrapper';
import Preloader from '../../../components/Layout/Preloader';
import { notification } from '../../../components/Utils/Notification';
import { useProductQuery, useUpdateProductMutation } from '../../../generated/graphql';
import { transformProductValues } from '../../../util/ProductFinalValue';
import { toErrorMap } from '../../../util/toErrorMap';
import ProductImage from './Components/ProductImage';
import ProductInfo from './Components/ProductInfo';
import ProductVariation from './Components/ProductVariation';

interface EditProductProps {
    id: string;
}

const EditProduct: React.FC<RouteComponentProps<EditProductProps>> = ({ match }) => {
    const history = useHistory();
    const productId = match.params.id;
    const [product, setProduct] = useState<any>(null);
    const [updateProduct, { loading: updateProductLoading }] = useUpdateProductMutation();

    const { data, loading: productLoading } = useProductQuery({
        variables: {
            id: Number(productId),
        },
    });

    useEffect(() => {
        if (!productLoading && data && data?.product !== null) {
            const categories = data.product?.category.map((category: any) => {
                return { label: category, value: category };
            });

            setProduct({
                name: data.product?.name || '',
                description: data.product?.description || '',
                longDescription: data.product?.longDescription || '',
                category: categories || [],
                brand: data.product?.brand || '',
                isDiscount: data.product?.isDiscount || false,
                discount: data.product?.discount ? convertCentsToPrice(data.product?.discount) : '0.00',
                sku: data.product?.sku || '',
                metaTitle: data.product?.metaTitle || '',
                metaKeywords: data.product?.metaKeywords || '',
                metaDescription: data.product?.metaDescription || '',
                variations: data.product?.variations || [
                    { name: '', variationData: [{ value: '', unitPrice: '', unitWeight: '', unitsInStock: '' }] },
                ],
            });
        }
    }, [productId, data, productLoading]);

    if (productLoading) {
        return <Preloader />;
    }

    return (
        <PageWrapper pageTitle='Edit Product' pageCategory='FLD Store' breadCrumbTitle='Products' breadCrumbItem='Edit Product'>
            {productLoading && (
                <div className='text-center mt-4'>
                    <Loader type='ThreeDots' color='primary' height={80} width={80} timeout={0} />
                </div>
            )}
            {product === null ? (
                <p>Product not found!</p>
            ) : (
                <Formik
                    validateOnMount
                    initialValues={EditProductInitialValues(product)}
                    validationSchema={EditProductSchema}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            const product = transformProductValues(values);
                            const response = await updateProduct({ variables: { id: Number(productId), input: product } });
                            if (response?.data?.updateProduct.errors) {
                                const errors = response.data.updateProduct.errors;
                                setErrors(toErrorMap(errors));
                                errors.forEach((err) => {
                                    notification('error', err.message);
                                });
                            } else if (response.data?.updateProduct.product) {
                                notification('success', 'Product updated!');
                                history.goBack();
                            }
                        } catch (error) {
                            notification('error', error.message);
                        }
                    }}
                >
                    {() => (
                        <Form className='fk-product'>
                            <ProductInfo />
                            <ProductVariation />
                            <ProductImage productId={productId} />
                            <BottomRightSaveButton title='Save Changes' isLoading={updateProductLoading} />
                        </Form>
                    )}
                </Formik>
            )}
        </PageWrapper>
    );
};

export default withRouter(EditProduct);
