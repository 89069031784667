import { PromoCodeInitialValues, PromoCodeSchema } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';
import { InputField } from '../../../components/Common/InputField';
import { ModalPopup } from '../../../components/Common/ModalPopup';
import PageWrapper from '../../../components/Common/PageWrapper';
import { notification } from '../../../components/Utils/Notification';
import { useCreatePromoCodeMutation } from '../../../generated/graphql';
import { toErrorMap } from '../../../util/toErrorMap';
import PromoCodeListTable from './Components/PromoCodeListTable';

interface PromoCodeProps {}

const PromoCode: React.FC<PromoCodeProps> = () => {
    const [isPromoCodeModalOpen, setIsPromoCodeModalOpen] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>('No file chosen');
    const [imageToUpload, setImageToUpload] = useState<any>(undefined);
    const [promoCodeInitial] = useState<any>(PromoCodeInitialValues);

    const [createPromoCode] = useCreatePromoCodeMutation();

    const handleClosed = () => {
        setImageName('No file chosen');
        setImageToUpload(undefined);
        setIsPromoCodeModalOpen(false);
    };

    const imageupload = (e: any) => {
        if (e.target.files) {
            setImageName(e.target.files[0].name);
            setImageToUpload(e.target.files[0]);
        }
    };

    return (
        <PageWrapper pageTitle='Promotion Code' pageCategory='FLD Store' breadCrumbTitle='Promotion' breadCrumbItem='Promo Code'>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>FLD Store Promotion Code</CardTitle>
                            <CardSubtitle className='mb-2'>List of FLD Store promotion code.</CardSubtitle>
                            <PromoCodeListTable />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ModalPopup isOpen={isPromoCodeModalOpen} handleClosed={handleClosed} size='md' title='Add New Promotion Code'>
                <Formik
                    enableReinitialize
                    initialValues={promoCodeInitial}
                    validationSchema={PromoCodeSchema}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            if (values.isPromoBanner) {
                                if (imageToUpload === undefined) {
                                    return notification('error', 'Promo code image cannot be empty');
                                }
                            }
                            const response = await createPromoCode({
                                variables: {
                                    input: {
                                        title: values.title,
                                        description: values.description || null,
                                        code: values.code,
                                        quantity: values.quantity,
                                        isFreeShippingPromo: values.isFreeShippingPromo,
                                        isPercentage: values.isPercentage,
                                        discountPercentage: values.discountPercentage,
                                        discountPrice: Number(values.discountPrice),
                                        isPromoBanner: values.isPromoBanner,
                                        image: values.isPromoBanner ? imageToUpload : null,
                                    },
                                },
                                update: (cache) => {
                                    cache.evict({ fieldName: 'promoCodeList' });
                                },
                            });
                            if (response?.data?.createPromoCode.errors) {
                                const errors = response.data.createPromoCode.errors;
                                setErrors(toErrorMap(errors));
                                errors.forEach((err) => {
                                    notification('error', err.message);
                                });
                            } else if (response.data?.createPromoCode) {
                                notification('success', 'Promotion Code created!');
                                handleClosed();
                            }
                        } catch (error) {
                            notification('error', error.message);
                        }
                    }}
                >
                    {({ values, isSubmitting }) => (
                        <Form action='#' method='POST'>
                            <InputField
                                name='title'
                                label='Promotion Title'
                                placeholder='Promotion Title'
                                type='text'
                                maxLength={30}
                                required
                            />
                            <InputField
                                name='description'
                                label='Promotion Description'
                                placeholder='Promotion Description'
                                type='text'
                                maxLength={150}
                                required
                            />
                            <InputField
                                name='code'
                                label='Promotion Code'
                                placeholder='Promotion Code'
                                type='text'
                                maxLength={20}
                                required
                            />
                            <InputField
                                name='quantity'
                                label='Promotion Code Quantity'
                                placeholder='Promotion Code Quantity'
                                type='number'
                                min={1}
                                required
                            />
                            <InputField
                                switches
                                name='isFreeShippingPromo'
                                placeholder=''
                                label='Is Free Shipping Promo?'
                                switchLabel='Mark this code as a free shipping promo?'
                                type='checkbox'
                            />
                            {!values.isFreeShippingPromo && (
                                <>
                                    <InputField
                                        switches
                                        name='isPercentage'
                                        placeholder='Percentage or Price discount?'
                                        label='Promotion Discount Type'
                                        switchLabel='Switch between price or percentage discount'
                                        type='checkbox'
                                    />
                                    {values.isPercentage ? (
                                        <InputField
                                            name='discountPercentage'
                                            label='Discount %'
                                            placeholder='Discount %'
                                            type='number'
                                            defaultValue={0}
                                            required
                                        />
                                    ) : (
                                        <InputField
                                            name='discountPrice'
                                            placeholder='Discount RM'
                                            label='Discount Price'
                                            type='text'
                                            required
                                        />
                                    )}
                                </>
                            )}

                            <InputField
                                switches
                                name='isPromoBanner'
                                placeholder='Promotion has banner?'
                                label='Promotion has banner?'
                                switchLabel='Promotion has banner?'
                                type='checkbox'
                            />
                            {values.isPromoBanner ? (
                                <InputField
                                    name='image'
                                    label='Promotion Banner Image'
                                    placeholder='Promotion Banner Image'
                                    type='file'
                                    fileInput
                                    fileInputLabel={imageName}
                                    accept='image/*'
                                    onChange={imageupload}
                                    required
                                />
                            ) : null}
                            <div className='modal-footer p-0'>
                                <Button type='submit' color='primary' disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                                    ) : (
                                        'Save Promo Code'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalPopup>
            <div className='text-sm-right save-changes-button'>
                <Button
                    type='button'
                    color='primary'
                    className='btn-rounded me-1 waves-effect waves-light'
                    onClick={() => setIsPromoCodeModalOpen(true)}
                >
                    <i className='mdi mdi-plus me-1' />
                    Add New Promotion Code
                </Button>
            </div>
        </PageWrapper>
    );
};

export default withRouter(PromoCode);
