import { OrderStatus } from '@footykids/common';
import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

interface OrderTimelineProps {
    orderStatus: OrderStatus;
}

const OrderTimeline: React.FC<OrderTimelineProps> = ({ orderStatus }) => {
    return (
        <Card>
            <CardBody>
                <CardTitle className='mb-5'>
                    <h5>Order Timeline</h5>
                </CardTitle>
                <div>
                    {orderStatus === OrderStatus.CANCELED ? (
                        <ul className='verti-timeline list-unstyled'>
                            <li className='event-list'>
                                <div className='event-timeline-dot'>
                                    <i className='bx bx-error bx-fade-right' />
                                </div>
                                <div className='media'>
                                    <div className='me-3'>
                                        <i className='bx bx-no-entry h2 text-primary' />
                                    </div>
                                    <div className='media-body'>
                                        <div>
                                            <h5>Order has been canceled</h5>
                                            <p className='text-muted'>Admin has canceled the order.</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    ) : orderStatus === OrderStatus.FAILED ? (
                        <ul className='verti-timeline list-unstyled'>
                            <li className='event-list'>
                                <div className='event-timeline-dot'>
                                    <i className='bx bx-error bx-fade-right' />
                                </div>
                                <div className='media'>
                                    <div className='me-3'>
                                        <i className='bx bx-x-circle h2 text-primary' />
                                    </div>
                                    <div className='media-body'>
                                        <div>
                                            <h5>Order failed</h5>
                                            <p className='text-muted'>Looks like customer didnt complete the payment.</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    ) : (
                        <ul className='verti-timeline list-unstyled'>
                            <li className='event-list'>
                                <div className='event-timeline-dot'>
                                    <i className={`bx bx-right-arrow-circle ${orderStatus === OrderStatus.PENDING && 'bx-fade-right'}`} />
                                </div>
                                <div className='media'>
                                    <div className='me-3'>
                                        <i className='bx bx-error h2 text-primary' />
                                    </div>
                                    <div className='media-body'>
                                        <div>
                                            <h5>Order pending</h5>
                                            <p className='text-muted'>Order transaction is pending</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='event-list'>
                                <div className='event-timeline-dot'>
                                    <i
                                        className={`bx bx-right-arrow-circle ${orderStatus === OrderStatus.SUCCESSFUL && 'bx-fade-right'}`}
                                    />
                                </div>
                                <div className='media'>
                                    <div className='me-3'>
                                        <i className='bx bx-check-double h2 text-primary' />
                                    </div>
                                    <div className='media-body'>
                                        <div>
                                            <h5>Order Successful</h5>
                                            <p className='text-muted'>Customer has made payment.</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='event-list'>
                                <div className='event-timeline-dot'>
                                    <i className={`bx bx-right-arrow-circle ${orderStatus === OrderStatus.CONFIRMED && 'bx-fade-right'}`} />
                                </div>
                                <div className='media'>
                                    <div className='me-3'>
                                        <i className='bx bxs-check-circle h2 text-primary' />
                                    </div>
                                    <div className='media-body'>
                                        <div>
                                            <h5>Order Confirmed</h5>
                                            <p className='text-muted'>Admin has confirmed the order.</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='event-list'>
                                <div className='event-timeline-dot'>
                                    <i className={`bx bx-right-arrow-circle ${orderStatus === OrderStatus.SHIPPED && 'bx-fade-right'}`} />
                                </div>
                                <div className='media'>
                                    <div className='me-3'>
                                        <i className='bx bxs-package h2 text-primary' />
                                    </div>
                                    <div className='media-body'>
                                        <div>
                                            <h5>Order has been ship out</h5>
                                            <p className='text-muted'>Admin has ship out the order.</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    )}
                </div>
            </CardBody>
        </Card>
    );
};

export default OrderTimeline;
