import React from 'react';

const Preloader: React.FC<{}> = () => {
    return (
        <div id='preloader'>
            <div id='status'>
                <div className='ms-2 spinner-chase'>
                    <div className='chase-dot' />
                    <div className='chase-dot' />
                    <div className='chase-dot' />
                    <div className='chase-dot' />
                    <div className='chase-dot' />
                    <div className='chase-dot' />
                </div>
                <p className='mt-4 text-white text-align-center'>Loading</p>
            </div>
        </div>
    );
};

export default Preloader;
