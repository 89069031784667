import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container } from 'reactstrap';
import Breadcrumbs from './Breadcrumb';

interface PageWrapperProps {
    pageTitle: string;
    pageCategory: string;
    breadCrumbTitle: string;
    breadCrumbItem: string;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children, ...props }) => {
    return (
        <React.Fragment>
            <div className='page-content'>
                <MetaTags>
                    <title>{props.pageTitle} | {props.pageCategory}</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title={props.breadCrumbTitle} breadcrumbItem={props.breadCrumbItem} />
                    {children}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PageWrapper;
