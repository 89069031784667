import { isEmpty, NotificationType } from '@footykids/common';
import { NotificationDataFragment } from '../generated/graphql';

export const serveImage = (path: string) => {
    if (isEmpty(path)) return '';

    var pattern = /^((http|https|ftp):\/\/)/;

    if (!pattern.test(path)) {
        const fullPath = process.env.REACT_APP_BACKEND_URL + path;
        return fullPath;
    }

    return path;
};

export const generateNotificationLink = (notification: NotificationDataFragment) => {
    switch (notification.type) {
        case NotificationType.FKA:
            return '#';
        case NotificationType.FKL:
            return '#';
        case NotificationType.FKORIGINAL:
            return '#';
        case NotificationType.FLD:
            return `/order/${notification.refId}`;
        default:
            return '#';
    }
};
