import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { Badge, UncontrolledTooltip } from 'reactstrap';

interface UserTableProps {}

const UserTable: React.FC<UserTableProps> = ({ ...props }) => {
    return (
        <React.Fragment>
            <div className='table-responsive mb-0'>
                <Table id='tech-companies-1' className='table'>
                    <Thead className='table-light'>
                        <Tr>
                            <Th width='10%' className='text-center'>#</Th>
                            <Th width='25%'>Username</Th>
                            <Th width='25%'>Email</Th>
                            <Th width='10%'>Type</Th>
                            <Th width='10%'>Created Date</Th>
                            <Th className='text-center'>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <tr>
                            <td className='text-center'>1</td>
                            <td className='text-body fw-bold'>AdiHafiz</td>
                            <td className='text-body fw-bold'>adihafiz@test.com</td>
                            <td>
                                <Badge className={`font-size-12 badge-soft-info`}>SUPPORT</Badge>
                            </td>
                            <td>November 12, 2021</td>
                            <td className='text-center'>
                                <ul className='list-inline font-size-20 contact-links mb-0'>
                                    <li className='list-inline-item px-1'>
                                        <Link to={`/#`} id={'reset' + 1}>
                                            <i className='bx bx-reset' />
                                            <UncontrolledTooltip placement='top' target={'reset' + 1}>
                                                Reset Password
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                    <li className='list-inline-item px-1'>
                                        <Link to='#' id={'delete' + 1}>
                                            <i className='bx bxs-trash' />
                                            <UncontrolledTooltip placement='top' target={'delete' + 1}>
                                                Remove Permanently
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </Tbody>
                </Table>
            </div>
        </React.Fragment>
    );
};

export default UserTable;
