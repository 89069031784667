import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { changeLayout, changeSidebarTheme, changeSidebarType, changeTopbarTheme, changeLayoutWidth } from '../../store/layout/actions';

// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

interface layoutProps {
    changeLayoutWidth: any;
    changeSidebarTheme: any;
    changeSidebarType: any;
    children: object;
    isPreloader: any;
    layoutWidth: any;
    leftSideBarTheme: any;
    leftSideBarType: any;
    location: any;
    topbarTheme: any;
    changeTopbarTheme?: any;
}

const Layout: React.FC<layoutProps & RouteComponentProps> = ({
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    children,
    isPreloader,
    layoutWidth,
    leftSideBarTheme,
    leftSideBarType,
    location,
    topbarTheme,
    changeTopbarTheme
}) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const toggleMenuCallback = () => {
        if (leftSideBarType === 'default') {
            changeSidebarType('condensed', isMobile);
        } else if (leftSideBarType === 'condensed') {
            changeSidebarType('default', isMobile);
        }
    };

    const capitalizeFirstLetter = (letter: string) => {
        return letter.charAt(1).toUpperCase() + letter.slice(2);
    };

    useEffect(() => {
        if (isPreloader === true) {
            document.getElementById('preloader')!.style.display = 'block';
            document.getElementById('status')!.style.display = 'block';

            setTimeout(function () {
                document.getElementById('preloader')!.style.display = 'none';
                document.getElementById('status')!.style.display = 'none';
            }, 2500);
        } else {
            document.getElementById('preloader')!.style.display = 'none';
            document.getElementById('status')!.style.display = 'none';
        }

        // Scroll Top to 0
        window.scrollTo(0, 0);
        let currentage = capitalizeFirstLetter(location.pathname);

        document.title = currentage + ' | Footykids';
        if (leftSideBarTheme) {
            changeSidebarTheme(leftSideBarTheme);
        }

        if (layoutWidth) {
            changeLayoutWidth(layoutWidth);
        }

        if (leftSideBarType) {
            changeSidebarType(leftSideBarType);
        }
        if (isMobile) {
            changeTopbarTheme('dark');
        }
    }, [
        changeLayoutWidth,
        changeSidebarTheme,
        changeSidebarType,
        isPreloader,
        layoutWidth,
        leftSideBarTheme,
        leftSideBarType,
        topbarTheme,
        location.pathname,
        isMobile,
        changeTopbarTheme
    ]);

    return (
        <React.Fragment>
            <div id='preloader'>
                <div id='status'>
                    <div className='spinner-chase'>
                        <div className='chase-dot' />
                        <div className='chase-dot' />
                        <div className='chase-dot' />
                        <div className='chase-dot' />
                        <div className='chase-dot' />
                        <div className='chase-dot' />
                    </div>
                </div>
            </div>

            <div id='layout-wrapper'>
                <Header toggleMenuCallback={toggleMenuCallback} />
                <Sidebar theme={leftSideBarTheme} type={leftSideBarType} isMobile={isMobile} />
                <div className='main-content'>{children}</div>
                <Footer />
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state: any) => {
    return {
        ...state.Layout,
    };
};
export default connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutWidth,
})(withRouter(Layout));
