import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer: React.FC<{}> = () => {
    return (
        <React.Fragment>
            <footer className='footer'>
                <Container fluid={true}>
                    <Row>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <div className='text-sm-right d-none d-sm-block'>{new Date().getFullYear()} © Footykids</div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
