import React from 'react';
import { Redirect } from 'react-router-dom';
import { AdminType } from '@footykids/common';

import User from '../pages/User/User';
import Login from '../pages/Authentication/Login';
import Dashboard from '../pages/Dashboard/index';
import Notifications from '../pages/Notification/Notification';
import News from '../pages/News/News';

// FKA
import AcademyVenue from '../pages/FKAcademy/AcademyVenue/AcademyVenue';

// FLD Store
import ProductList from '../pages/FLDStore/Products/ProductList';
import AddProduct from '../pages/FLDStore/Products/AddProduct';
import EditProduct from '../pages/FLDStore/Products/EditProduct';
import ProductCategory from '../pages/FLDStore/ProductCategory/ProductCategory';
import OrderList from '../pages/FLDStore/Orders/OrderList';
import OrderFullDetails from '../pages/FLDStore/Orders/OrderFullDetails';

import HomePageBanner from '../pages/FLDStore/Banner/HomePageBanner';
import SecondaryBanner from '../pages/FLDStore/Banner/SecondaryBanner';
import PromoCode from '../pages/FLDStore/PromoCode/PromoCode';
import FLDSettings from '../pages/FLDStore/FLDSettings/FLDSettings';
import FLDFAQ from '../pages/FLDStore/FLDSettings/FLDFAQ';

const userRoutes = [
    { path: '/dashboard', component: Dashboard },

    // Admin User
    { path: '/user', component: User, roles: [AdminType.SUPER] },
    // Notifications
    { path: '/notifications', component: Notifications },
    // News
    { path: '/news', component: News },

    // FKA - Venues
    { path: '/academy-venues', component: AcademyVenue },

    // FLD Store - Orders
    { path: '/orders', component: OrderList },
    { path: '/order/:id', component: OrderFullDetails },

    // FLD Store - Banner
    { path: '/homepage-banner', component: HomePageBanner },
    { path: '/secondary-banner', component: SecondaryBanner },

    // FLD Store - Products
    { path: '/product/list', component: ProductList },
    { path: '/product/add', component: AddProduct },
    { path: '/product/edit/:id', component: EditProduct },
    { path: '/product/category', component: ProductCategory },

    // FLD Store - Promotion Code
    { path: '/promotion-code', component: PromoCode },

    // FLD Store - Settings
    { path: '/fld-settings', component: FLDSettings },
    { path: '/fld-faq', component: FLDFAQ },

    { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },
    { component: () => <Redirect to='/dashboard' /> },
];

const authRoutes = [{ path: '/login', component: Login }];

// export { userRoutes }
export { userRoutes, authRoutes };
