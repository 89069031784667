import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { CardImg, UncontrolledTooltip } from 'reactstrap';
import Preloader from '../../../../components/Layout/Preloader';
import { notification } from '../../../../components/Utils/Notification';
import { ProductCategoryDataFragment, useDeleteProductCategoryMutation, useProductCategoriesQuery } from '../../../../generated/graphql';
import { serveImage } from '../../../../util/Helper';
dayjs.extend(LocalizedFormat);

interface ProductCategoryTableProps {}

export const ProductCategoryTable: React.FC<ProductCategoryTableProps> = () => {
    const { data: productCategoryData, loading: ProductCategoryLoading } = useProductCategoriesQuery();
    const [deleteProductCategory] = useDeleteProductCategoryMutation();

    const handleDeleteProductCategory = async (id: number) => {
        try {
            const response = await deleteProductCategory({
                variables: { id },
                update: (cache: any) => {
                    cache.evict({ id: 'ProductCategory:' + id });
                },
            });

            if (response.data?.deleteProductCategory) {
                notification('success', 'Successful Delete!');
            }
        } catch (error) {
            notification('error', error.message);
        }
    };

    if (ProductCategoryLoading) {
        return <Preloader />;
    }

    return (
        <div className='table-rep-plugin'>
            <div className='table-responsive mb-0' data-pattern='priority-columns'>
                <Table id='tech-companies-1' className='table table-striped'>
                    <Thead className='table-light'>
                        <Tr>
                            <Th>#</Th>
                            <Th data-priority='3'>Image</Th>
                            <Th>Category Name</Th>
                            <Th data-priority='3'>Created At</Th>
                            <Th data-priority='6'>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {productCategoryData?.productCategories ? (
                            productCategoryData.productCategories.map((productCategory: ProductCategoryDataFragment, index: number) => (
                                <Tr key={index}>
                                    <Th>{index + 1}</Th>
                                    <Th style={{ width: '10%' }}>
                                        <CardImg
                                            src={serveImage(productCategory.imagePath || '')}
                                            alt={productCategory.name}
                                            className='avatar-sm img-fluid mx-auto rounded'
                                        />
                                    </Th>
                                    <Th>{productCategory.name}</Th>
                                    <Th>{dayjs(parseInt(productCategory.createdAt)).format('LLL')}</Th>
                                    <Th>
                                        <ul className='list-inline font-size-20 contact-links mb-0'>
                                            <li className='list-inline-item px-2'>
                                                <Link
                                                    to='#'
                                                    id={'delete' + productCategory.id}
                                                    onClick={() => handleDeleteProductCategory(productCategory.id)}
                                                >
                                                    <i className='bx bxs-trash' />
                                                    <UncontrolledTooltip placement='top' target={'delete' + productCategory.id}>
                                                        Delete
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                        </ul>
                                    </Th>
                                </Tr>
                            ))
                        ) : (
                            <p className='text-center'>No product category available.</p>
                        )}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
};
