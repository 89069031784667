import { gql } from '@apollo/client';
import { convertCentsToPrice } from '@footykids/common';
import { Link } from 'react-router-dom';
import { CardImg, UncontrolledTooltip } from 'reactstrap';
import { notification } from '../../../../components/Utils/Notification';
import { ProductDataFragment } from '../../../../generated/graphql';
import { serveImage } from '../../../../util/Helper';

const productListColumns = (toggleIsPublishProduct: any, deleteProduct: any) => [
    {
        dataField: 'image',
        text: 'Image',
        sort: false,
        formatter: (cellContent: any, product: ProductDataFragment) => (
            <>
                {product.images?.length === 0 ? (
                    <p>No image</p>
                ) : (
                    product.images?.map((img) =>
                        img.isDefault ? (
                            <CardImg
                                key={img.id}
                                src={serveImage(img.path || '')}
                                alt={product.name}
                                className='avatar-sm mx-auto rounded'
                            />
                        ) : null
                    )
                    // <img key={img.id} src={img.path} alt={product.name} className='avatar-md h-auto d-block rounded' />
                )}
            </>
        ),
    },
    {
        text: 'Name',
        dataField: 'name',
        sort: true,
        formatter: (cellContent: any, product: ProductDataFragment) => (
            <Link to='#'>
                <h5 className='font-size-14 mb-1 text-dark'>{product.name}</h5>
                <div style={{ maxWidth: '60ch', overflow: 'hidden' }}>
                    <p className='text-muted mb-0'>{product.description.substr(0, 70)}</p>
                </div>
            </Link>
        ),
    },
    {
        dataField: 'brand',
        text: 'Brand',
        sort: true,
        formatter: (cellContent: any, product: ProductDataFragment) => (
            <>
                <p>{product.brand}</p>
            </>
        ),
    },
    {
        dataField: 'category',
        text: 'Category',
        sort: true,
        formatter: (cellContent: any, product: ProductDataFragment) => (
            <>
                <p>{product.category.join(', ')}</p>
            </>
        ),
    },
    {
        dataField: 'unitsOnOrder',
        text: 'Total Purchased',
        sort: true,
        formatter: (cellContent: any, product: ProductDataFragment) => (
            <>
                <p>
                    <i className='bx bx-shopping-bag align-middle me-1'></i> {product.unitsOnOrder} purchased
                </p>
            </>
        ),
    },
    {
        dataField: 'defaultPrice',
        text: 'Min. Price',
        sort: true,
        formatter: (cellContent: any, product: ProductDataFragment) => (
            <>
                <p>RM {product.defaultPrice ? convertCentsToPrice(product.defaultPrice) : ''}</p>
            </>
        ),
    },
    {
        dataField: 'menu',
        isDummyField: true,
        text: 'Action',
        formatter: (cellContent: any, product: ProductDataFragment) => (
            <ul className='list-inline font-size-20 contact-links mb-0'>
                {product.isPublished ? null : (
                    <>
                        <li className='list-inline-item px-2'>
                            <Link to={`/product/edit/${product.id}`} id={'edit' + product.id}>
                                <i className='bx bx-edit' />
                                <UncontrolledTooltip placement='top' target={'edit' + product.id}>
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                        </li>
                        <li className='list-inline-item px-2'>
                            <div
                                id={'delete' + product.id}
                                className='is-link'
                                onClick={async () => {
                                    try {
                                        const response = await deleteProduct({
                                            variables: { id: product.id },
                                            update: (cache: any) => {
                                                cache.evict({ id: 'Product:' + product.id });
                                            },
                                        });

                                        if (response.data?.deleteProduct) {
                                            notification('success', 'Successful Delete!');
                                        }
                                    } catch (error) {
                                        notification('error', error.message);
                                    }
                                }}
                            >
                                <i className='bx bxs-trash' />
                                <UncontrolledTooltip placement='top' target={'delete' + product.id}>
                                    Delete
                                </UncontrolledTooltip>
                            </div>
                        </li>
                    </>
                )}
                <li className='list-inline-item px-2'>
                    <div
                        id={'publish' + product.id}
                        className='is-link'
                        onClick={async () => {
                            if (product.images?.length === 0 || !product.images) {
                                return notification('error', `Product don't have any images. Please upload at least one image.`);
                            } else if (product.images!.length <= 1) {
                                return notification('error', 'Product must have at least 2 image to publish.');
                            }
                            try {
                                await toggleIsPublishProduct({
                                    variables: { id: product.id },
                                    update: (cache: any) => {
                                        cache.writeFragment({
                                            id: 'Product:' + product.id,
                                            fragment: gql`
                                                fragment __ on Product {
                                                    isPublished
                                                }
                                            `,
                                            data: { isPublished: !product.isPublished },
                                        });
                                    },
                                });
                            } catch (error) {
                                notification('error', error.message);
                            }
                        }}
                    >
                        <i className={`bx bx-check-circle ${product.isPublished ? 'color-green' : ''}`} />
                        <UncontrolledTooltip placement='top' target={'publish' + product.id}>
                            Publish
                        </UncontrolledTooltip>
                    </div>
                </li>
            </ul>
        ),
    },
];

export default productListColumns;
