import { OrderStatus, PaymentStatus } from '@footykids/common';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { RouteComponentProps } from 'react-router-dom';
import { Badge, Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import PageWrapper from '../../../components/Common/PageWrapper';
import { useOrderQuery } from '../../../generated/graphql';
import BillingPaymentDetails from './Components/BillingPaymentDetails';
import OrderAction from './Components/OrderAction';
import OrderDetails from './Components/OrderDetails';
import OrderReceipt from './Components/OrderReceipt';
import ShippingDeliveryDetails from './Components/ShippingDeliveryDetails';

interface OrderFullDetailsProps {
    id: string;
}

const OrderFullDetails: React.FC<RouteComponentProps<OrderFullDetailsProps>> = ({ match }) => {
    const orderId = match.params.id;
    const [activeTab, setActiveTab] = useState('1');
    const { data: orderData, loading: orderLoading } = useOrderQuery({ variables: { id: Number(orderId) } });

    return (
        <PageWrapper pageTitle='Order' breadCrumbTitle='Order' breadCrumbItem='Full Details' pageCategory='FLD Store'>
            <Row>
                <Col lg={12}>
                    {orderLoading ? (
                        <div className='text-center'>
                            <Loader type='ThreeDots' color='primary' height={35} width={35} timeout={0} />
                        </div>
                    ) : (
                        orderData &&
                        orderData.order && (
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <h5>#FLD-{orderData.order.orderNo}</h5>
                                                <h6>Order Details</h6>
                                            </div>
                                            <div className='d-flex flex-column text-end'>
                                                <span>
                                                    Order Status:
                                                    <Badge
                                                        className={`ms-1 font-size-12 badge-soft-${
                                                            orderData.order.orderStatus === OrderStatus.FAILED ||
                                                            orderData.order.orderStatus === OrderStatus.SHIPPING_FAILED ||
                                                            orderData.order.orderStatus === OrderStatus.CANCELED
                                                                ? 'danger'
                                                                : orderData.order.orderStatus === OrderStatus.PENDING
                                                                ? 'warning'
                                                                : orderData.order.orderStatus === OrderStatus.SHIPPED
                                                                ? 'info'
                                                                : 'success'
                                                        }`}
                                                    >
                                                        {orderData.order.orderStatus}
                                                    </Badge>
                                                </span>
                                                <span>
                                                    Payment Status:
                                                    <Badge
                                                        className={`ms-1 font-size-12 badge-soft-${
                                                            orderData.order.paymentTxnStatus === PaymentStatus.PAID ||
                                                            orderData.order.paymentTxnStatus === PaymentStatus.REFUNDED
                                                                ? 'success'
                                                                : orderData.order.paymentTxnStatus === PaymentStatus.PENDINGPAYMENT ||
                                                                  orderData.order.paymentTxnStatus === PaymentStatus.TOREFUND
                                                                ? 'warning'
                                                                : 'danger'
                                                        }`}
                                                        color={'success'}
                                                    >
                                                        {orderData.order.paymentTxnStatus}
                                                    </Badge>
                                                </span>
                                            </div>
                                        </div>
                                    </CardTitle>
                                    <Nav tabs className='navtab-bg nav-pills mt-4'>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: 'pointer' }}
                                                className={`${activeTab === '1' && 'active'}`}
                                                onClick={() => setActiveTab('1')}
                                            >
                                                <span>Order Details</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: 'pointer' }}
                                                className={`${activeTab === '2' && 'active'}`}
                                                onClick={() => setActiveTab('2')}
                                            >
                                                <span>Billing & Payment Details</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: 'pointer' }}
                                                className={`${activeTab === '3' && 'active'}`}
                                                onClick={() => setActiveTab('3')}
                                            >
                                                <span>Shipping & Delivery Details</span>
                                            </NavLink>
                                        </NavItem>
                                        {orderData.order.orderStatus === OrderStatus.CONFIRMED && (
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: 'pointer' }}
                                                    className={`${activeTab === '4' && 'active'}`}
                                                    onClick={() => setActiveTab('4')}
                                                >
                                                    <span>Receipt</span>
                                                </NavLink>
                                            </NavItem>
                                        )}
                                    </Nav>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId='1' className='p-3'>
                                            <Row>
                                                <Col sm='12'>
                                                    <OrderDetails order={orderData.order} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId='2' className='p-3'>
                                            <Row>
                                                <Col sm='12'>
                                                    <BillingPaymentDetails order={orderData.order} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId='3' className='p-3'>
                                            <Row>
                                                <Col sm='12'>
                                                    <ShippingDeliveryDetails order={orderData.order} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId='4' className='p-3'>
                                            <Row>
                                                <Col sm='12'>
                                                    <OrderReceipt order={orderData.order} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                    <OrderAction orderId={Number(orderId)} order={orderData.order} />
                                </CardBody>
                            </Card>
                        )
                    )}
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default OrderFullDetails;
