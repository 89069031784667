import { gql } from '@apollo/client';
import { convertCentsToPrice } from '@footykids/common';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { CardImg, UncontrolledTooltip } from 'reactstrap';
import Preloader from '../../../../components/Layout/Preloader';
import { notification } from '../../../../components/Utils/Notification';
import {
    PromoCodeDataFragment,
    useDeletePromoCodeMutation,
    usePromoCodeListQuery,
    useTogglePromoCodeActiveMutation,
    useTogglePublishPromoCodeBannerMutation,
} from '../../../../generated/graphql';
import { serveImage } from '../../../../util/Helper';

interface PromoCodeListTableProps {}

const PromoCodeListTable: React.FC<PromoCodeListTableProps> = () => {
    const { data: promoCodeData, loading: promoCodeLoading } = usePromoCodeListQuery();
    const [deletePromoCode] = useDeletePromoCodeMutation();
    const [togglePublishPromoCodeBanner] = useTogglePublishPromoCodeBannerMutation();
    const [togglePromoCodeActive] = useTogglePromoCodeActiveMutation();

    const handleDeletePromoCode = async (id: number) => {
        try {
            const response = await deletePromoCode({
                variables: { id },
                update: (cache: any) => {
                    cache.evict({ id: 'PromoCode:' + id });
                },
            });

            if (response.data?.deletePromoCode) {
                notification('success', 'Successful Delete!');
            }
        } catch (error) {
            notification('error', error.message);
        }
    };

    const toggleActive = async (id: number, isPromoActive: boolean) => {
        try {
            await togglePromoCodeActive({
                variables: { id },
                update: (cache: any) => {
                    cache.writeFragment({
                        id: 'PromoCode:' + id,
                        fragment: gql`
                            fragment __ on PromoCode {
                                isPromoActive
                            }
                        `,
                        data: { isPromoActive: !isPromoActive },
                    });
                },
            });
        } catch (error) {
            notification('error', error.message);
        }
    };

    const togglePublishedBanner = async (id: number, isPublished: boolean) => {
        try {
            await togglePublishPromoCodeBanner({
                variables: { id },
                update: (cache: any) => {
                    cache.writeFragment({
                        id: 'PromoCode:' + id,
                        fragment: gql`
                            fragment __ on PromoCode {
                                isPublished
                            }
                        `,
                        data: { isPublished: !isPublished },
                    });
                },
            });
        } catch (error) {
            notification('error', error.message);
        }
    };

    if (promoCodeLoading) {
        return <Preloader />;
    }

    return (
        <div className='table-rep-plugin'>
            <div className='table-responsive mb-0' data-pattern='priority-columns'>
                <Table id='tech-companies-1' className='table'>
                    <Thead className='table-light'>
                        <Tr>
                            <Th>Promo Banner</Th>
                            <Th>Title & Description</Th>
                            <Th>Promo Code</Th>
                            <Th data-priority='2'>Quantity</Th>
                            <Th data-priority='2'>Discount</Th>
                            <Th data-priority='3'>Created At</Th>
                            <Th data-priority='6'>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {promoCodeData?.promoCodeList ? (
                            promoCodeData.promoCodeList.map((promotion: PromoCodeDataFragment, index: number) => (
                                <Tr key={index}>
                                    <Th style={{ width: '10%' }}>
                                        {promotion.isPromoBanner ? (
                                            <CardImg
                                                src={serveImage(promotion.imagePath || '')}
                                                alt={promotion.title}
                                                className='avatar-md img-fluid mx-auto rounded'
                                            />
                                        ) : (
                                            'No Banner'
                                        )}
                                    </Th>
                                    <Th style={{ width: '30%' }}>
                                        <Link to='#'>
                                            <h5 className='font-size-14 mb-1 text-dark'>{promotion.title}</h5>
                                            <div style={{ maxWidth: '75ch', overflow: 'hidden' }}>
                                                <p className='text-muted mb-0'>
                                                    {promotion.description ? promotion.description.substr(0, 150) : ''}
                                                </p>
                                            </div>
                                        </Link>
                                    </Th>
                                    <Th style={{ width: '15%' }}>{promotion.code}</Th>
                                    <Th style={{ width: '10%' }}>{promotion.quantity} left</Th>
                                    <Th style={{ width: '10%' }}>
                                        {promotion.isFreeShippingPromo
                                            ? 'FREE SHIPPING'
                                            : promotion.isPercentage
                                            ? promotion.discountPercentage + ' %'
                                            : 'RM ' + convertCentsToPrice(promotion.discountPrice!)}
                                    </Th>
                                    <Th style={{ width: '10%' }}>{dayjs(parseInt(promotion.createdAt)).format('LLL')}</Th>
                                    <Th style={{ width: '10%' }}>
                                        <ul className='list-inline font-size-20 contact-links mb-0'>
                                            {promotion.isPublished || promotion.isPromoActive ? null : (
                                                <li className='list-inline-item px-2'>
                                                    <Link
                                                        to='#'
                                                        id={'delete' + promotion.id}
                                                        onClick={() => handleDeletePromoCode(promotion.id)}
                                                    >
                                                        <i className='bx bxs-trash' />
                                                        <UncontrolledTooltip placement='top' target={'delete' + promotion.id}>
                                                            Delete
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                </li>
                                            )}
                                            {promotion.isPromoBanner && (
                                                <li className='list-inline-item px-2'>
                                                    <Link
                                                        to='#'
                                                        id={'publishBanner' + promotion.id}
                                                        onClick={() => togglePublishedBanner(promotion.id, promotion.isPublished)}
                                                    >
                                                        <i className={`bx bxs-image ${promotion.isPublished ? 'color-green' : ''}`} />
                                                        <UncontrolledTooltip placement='top' target={'publishBanner' + promotion.id}>
                                                            Publish Promotion Banner
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                </li>
                                            )}
                                            <li className='list-inline-item px-2'>
                                                <Link
                                                    to='#'
                                                    id={'publish' + promotion.id}
                                                    onClick={() => toggleActive(promotion.id, promotion.isPromoActive)}
                                                >
                                                    <i className={`bx bx-check-circle ${promotion.isPromoActive ? 'color-green' : ''}`} />
                                                    <UncontrolledTooltip placement='top' target={'publish' + promotion.id}>
                                                        Set Promotion as Active
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                        </ul>
                                    </Th>
                                </Tr>
                            ))
                        ) : (
                            <p className='text-center'>No promotion code available.</p>
                        )}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
};

export default PromoCodeListTable;
