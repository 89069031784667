import { convertCentsToPrice, PaymentStatus } from '@footykids/common';
import dayjs from 'dayjs';
import React from 'react';
import { Badge, Button, Card, Col, Row, Table } from 'reactstrap';
import fpxImg from '../../../../assets/images/payment/fpx.svg';
import { notification } from '../../../../components/Utils/Notification';
import { OrderDataFragment, useVerifyPendingOrderByBillCodeMutation } from '../../../../generated/graphql';

interface BillingPaymentDetailsProps {
    order: OrderDataFragment;
}

const BillingPaymentDetails: React.FC<BillingPaymentDetailsProps> = ({ order }) => {
    const [verifyPendingOrder, { loading: verifyPendingOrderLoading }] = useVerifyPendingOrderByBillCodeMutation();

    return (
        <Card>
            <Row>
                <Col xs={12} md={6}>
                    <div className='table-responsive'>
                        <Table className='table table-nowrap'>
                            <tbody>
                                <tr>
                                    <td colSpan={2} className='border-top-0'>
                                        <h5>Billing Details</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Billing Name:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.billingName}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Phone Number:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.orderBilling?.phoneNumber}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Email Address:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.orderBilling?.emailAddress}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%' style={{ verticalAlign: 'top' }}>
                                        Billing Details:
                                    </td>
                                    <td width='80%'>
                                        <p className='text-primary'>
                                            {order.orderBilling!.houseUnit ? (
                                                <>
                                                    {order.orderBilling!.houseUnit}
                                                    <br />
                                                </>
                                            ) : null}
                                            {order.orderBilling!.addressLine1} <br />
                                            {order.orderBilling!.addressLine2 ? (
                                                <>
                                                    {order.orderBilling!.addressLine2}
                                                    <br />
                                                </>
                                            ) : null}
                                            {order.orderBilling!.postalCode} <br />
                                            {order.orderBilling!.city} <br />
                                            {order.orderBilling!.state} <br />
                                            {order.orderBilling!.country} <br />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className='table-responsive'>
                        <Table className='table table-nowrap'>
                            <tbody>
                                <tr>
                                    <td colSpan={2} className='border-top-0'>
                                        <h5>Payment Details</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Payment Method:</td>
                                    <td width='80%'>
                                        {order.paymentMethod.toLowerCase() === 'fpx' && (
                                            <img src={fpxImg} alt='FPX' height='15' className='me-1' />
                                        )}
                                        {order.paymentMethod.toLowerCase() === 'creditcard' && (
                                            <>
                                                <i className={'fas fa-credit-card me-1'} />
                                                Credit Card
                                            </>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Payment Channel:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.paymentTxnChannel}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Payment BillCode:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.paymentTxnBillCode}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Payment Status:</td>
                                    <td width='80%'>
                                        <Badge
                                            className={`ms-1 font-size-12 badge-soft-${
                                                order.paymentTxnStatus === PaymentStatus.PAID ||
                                                order.paymentTxnStatus === PaymentStatus.REFUNDED
                                                    ? 'success'
                                                    : order.paymentTxnStatus === PaymentStatus.PENDINGPAYMENT ||
                                                      order.paymentTxnStatus === PaymentStatus.TOREFUND
                                                    ? 'warning'
                                                    : 'danger'
                                            }`}
                                            color={'success'}
                                        >
                                            {order.paymentTxnStatus}
                                        </Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Payment Reference:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.paymentTxnRef ? order.paymentTxnRef : '-'}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Payment Reason:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>
                                            {order.paymentTxnReason ? order.paymentTxnReason.toUpperCase() : '-'}
                                        </span>
                                    </td>
                                </tr>
                                {order.paymentTxnStatus === PaymentStatus.PENDINGPAYMENT && order.paymentTxnBillCode && (
                                    <tr>
                                        <td width='20%'>Reverify:</td>
                                        <td>
                                            <Button
                                                color='primary'
                                                size='sm'
                                                disabled={verifyPendingOrderLoading}
                                                onClick={async () => {
                                                    try {
                                                        const response = await verifyPendingOrder({
                                                            variables: { billCode: order.paymentTxnBillCode! },
                                                            update: (cache: any) => {
                                                                cache.evict({ id: 'Order:' + order.id });
                                                            },
                                                        });

                                                        if (response.data?.verifyPendingOrderByBillCode) {
                                                            notification('success', 'Request Success!');
                                                        } else {
                                                            notification('error', 'Failed to update order address!');
                                                        }
                                                    } catch (error) {
                                                        notification('error', error.message);
                                                    }
                                                }}
                                            >
                                                Reverify Order Payment
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                                {order.paymentTxnStatus === PaymentStatus.PAID && (
                                    <>
                                        <tr>
                                            <td width='20%'>Payment Received:</td>
                                            <td width='80%'>
                                                <span className='text-primary'>
                                                    RM {convertCentsToPrice(order.paymentTxnPriceReceived!)}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width='20%'>Payment Date:</td>
                                            <td width='80%'>
                                                <span className='text-primary'>{dayjs(parseInt(order.paymentTxnDate!)).format('lll')}</span>
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Card>
    );
};

export default BillingPaymentDetails;
