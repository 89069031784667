import { FLDShippingCodeAndStateType, getStateFromPostCode } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from '../../../../components/Common/InputField';
import { notification } from '../../../../components/Utils/Notification';
import { useUpdateShipCodeAndStateMutation } from '../../../../generated/graphql';

interface ShipCodeAndStateFormProps {
    cityAndStateData?: any;
}

const ShipCodeAndStateForm: React.FC<ShipCodeAndStateFormProps> = ({ cityAndStateData }) => {
    const [state, setState] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [setCodeAndState] = useUpdateShipCodeAndStateMutation();
    const [onEdit, setOnEdit] = useState<boolean>(false);

    useEffect(() => {
        if (cityAndStateData && cityAndStateData.value) {
            const data: FLDShippingCodeAndStateType = JSON.parse(cityAndStateData.value);
            if (data && data.postalCode) {
                setPostalCode(data.postalCode);
            }
            if (data && data.state) {
                setState(data.state);
            }
        }
    }, [cityAndStateData]);

    return (
        <React.Fragment>
            <Col xs={12} className='mb-4'>
                <FormGroup row>
                    <Label className='col-lg-2'>
                        <span className='fw-bold'>Set Shipping State and Postcode</span>
                        <br />
                        <small className='text-danger'>This is use to calculate the shipping price</small>
                    </Label>
                    <Col lg='10'>
                        <Formik
                            enableReinitialize
                            initialValues={{ postalCode: postalCode, state: state }}
                            // validationSchema={ShipOrderSchema}
                            onSubmit={async (values) => {
                                try {
                                    await setCodeAndState({
                                        variables: values,
                                        update: (cache: any) => {
                                            cache.evict({ fieldName: 'getFLDSettings' });
                                        },
                                    }).then(() => setOnEdit(false));
                                } catch (error) {
                                    notification('error', error.message);
                                }
                            }}
                        >
                            {({ isSubmitting, values, setFieldValue, errors, touched }) => (
                                <Form>
                                    <Row>
                                        <Col md={3} className='pr-0'>
                                            <InputField
                                                name='postalCode'
                                                label='Postal Code'
                                                placeholder='Postal Code'
                                                onChange={(e) => {
                                                    const postcode = e.target.value;
                                                    setFieldValue('postalCode', postcode);
                                                    if (postcode.length === 5) {
                                                        setFieldValue('state', getStateFromPostCode(postcode));
                                                    }
                                                }}
                                                disabled={!onEdit}
                                                required
                                            />
                                        </Col>
                                        <Col md={3} className='pr-0'>
                                            <FormGroup className='mb-3'>
                                                <Label htmlFor='state' className='form-label'>
                                                    State <span className='text-danger'>*</span>
                                                </Label>
                                                <CustomInput
                                                    name='state'
                                                    id='state'
                                                    type='select'
                                                    className='form-select'
                                                    onChange={(e) => {
                                                        setFieldValue('state', e.target.value);
                                                    }}
                                                    value={values.state}
                                                    disabled={!onEdit}
                                                >
                                                    <option value='Johor'>Johor</option>
                                                    <option value='Kedah'>Kedah</option>
                                                    <option value='Kelantan'>Kelantan</option>
                                                    <option value='Melaka'>Melaka</option>
                                                    <option value='Negeri Sembilan'>Negeri Sembilan</option>
                                                    <option value='Pahang'>Pahang</option>
                                                    <option value='Perak'>Perak</option>
                                                    <option value='Perlis'>Perlis</option>
                                                    <option value='Pulau Pinang'>Pulau Pinang</option>
                                                    <option value='Selangor'>Selangor</option>
                                                    <option value='Terengganu'>Terengganu</option>
                                                    <option value='Kuala Lumpur'>Kuala Lumpur</option>
                                                    <option value='Putra Jaya'>Putra Jaya</option>
                                                    <option value='Sarawak'>Sarawak</option>
                                                    <option value='Sabah'>Sabah</option>
                                                    <option value='Labuan'>Labuan</option>
                                                </CustomInput>
                                                <div>{errors.state && touched.state && <span className='text-danger'>{errors}</span>}</div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} className='pl-0'>
                                            <InputField name='country' label='Country' placeholder='Country' disabled value='Malaysia' />
                                        </Col>
                                        <Col md={3} className='pl-0 align-self-center'>
                                            {onEdit ? (
                                                <Button color='success' className='mt-2 mt-md-0' type='submit' disabled={isSubmitting}>
                                                    Save
                                                </Button>
                                            ) : (
                                                <Button
                                                    color='primary'
                                                    className='mt-2 mt-md-0'
                                                    type='button'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOnEdit(true);
                                                    }}
                                                >
                                                    Edit Shipping Options
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </FormGroup>
            </Col>
        </React.Fragment>
    );
};

export default ShipCodeAndStateForm;
