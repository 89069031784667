import { HomepageBannerInitialValues, HomepageBannerSchema, isEmpty } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';
import { InputField } from '../../../components/Common/InputField';
import { ModalPopup } from '../../../components/Common/ModalPopup';
import PageWrapper from '../../../components/Common/PageWrapper';
import Preloader from '../../../components/Layout/Preloader';
import { notification } from '../../../components/Utils/Notification';
import { ProductDataFragment, useCreateHomepageBannerMutation, useProductsQuery } from '../../../generated/graphql';
import { toErrorMap } from '../../../util/toErrorMap';
import { HomepageBannerListTable } from './Components/HomepageBannerListTable';

interface HomePageBannerProps {}

const HomePageBanner: React.FC<HomePageBannerProps> = () => {
    const [isHomepageBannerModalOpen, setIsHomepageBannerModalOpen] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>('No file chosen');
    const [imageToUpload, setImageToUpload] = useState<any>(undefined);
    const [imageMobileName, setImageMobileName] = useState<string>('No file chosen');
    const [imageMobileToUpload, setImageMobileToUpload] = useState<any>(undefined);
    const [productsList, setProductsList] = useState<Array<any>>([]);
    const [homepageBanner] = useState<any>(HomepageBannerInitialValues);

    const { data: productsData, loading: productsLoading } = useProductsQuery();
    const [createHomepageBanner, { loading: createHomepageBannerLoading }] = useCreateHomepageBannerMutation();

    useEffect(() => {
        if (!productsLoading && productsData && productsData.products) {
            handleProductsData(productsData.products);
        }
    }, [productsLoading, productsData]);

    const handleProductsData = (data: ProductDataFragment[]) => {
        let productsArray: Array<any> = [];
        data.map((product) => {
            return productsArray.push({ label: product.name, value: product.id });
        });
        setProductsList(productsArray);
    };

    const handleClosed = () => {
        setImageName('No file chosen');
        setImageToUpload(undefined);
        setIsHomepageBannerModalOpen(false);
    };

    const imageupload = (e: any) => {
        if (e.target.files) {
            setImageName(e.target.files[0].name);
            setImageToUpload(e.target.files[0]);
        }
    };

    const imageMobileUpload = (e: any) => {
        if (e.target.files) {
            setImageMobileName(e.target.files[0].name);
            setImageMobileToUpload(e.target.files[0]);
        }
    };

    if (productsLoading) {
        return <Preloader />;
    }

    return (
        <PageWrapper pageTitle='Homepage Banner' pageCategory='FLD Store' breadCrumbTitle='Banner' breadCrumbItem='Homepage Banner'>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>FLD Store Homepage Banner</CardTitle>
                            <CardSubtitle className='mb-2'>List of banner to use in FLD Store homepage.</CardSubtitle>
                            <HomepageBannerListTable />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ModalPopup isOpen={isHomepageBannerModalOpen} handleClosed={handleClosed} size='md' title='Add New Homepage Banner'>
                <Formik
                    enableReinitialize
                    initialValues={homepageBanner}
                    validationSchema={HomepageBannerSchema}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            if (imageToUpload === undefined) {
                                return notification('error', 'Banner image cannot be empty');
                            }
                            if (imageMobileToUpload === undefined) {
                                return notification('error', 'Banner image for mobile cannot be empty');
                            }
                            if (values.hasProductButton) {
                                if (isEmpty(values.productId)) {
                                    return notification('error', 'Product is not defined');
                                }
                            }
                            const response = await createHomepageBanner({
                                variables: {
                                    input: {
                                        title: values.title,
                                        description: values.description || null,
                                        hasProductButton: values.hasProductButton,
                                        productId: values.productId?.value || null,
                                        image: imageToUpload,
                                        imageMobile: imageMobileToUpload
                                    },
                                },
                                update: (cache) => {
                                    cache.evict({ fieldName: 'homepageBanners' });
                                },
                            });

                            if (response?.data?.createHomepageBanner.errors) {
                                const errors = response.data.createHomepageBanner.errors;
                                setErrors(toErrorMap(errors));
                                errors.forEach((err) => {
                                    notification('error', err.message);
                                });
                            } else if (response.data?.createHomepageBanner) {
                                notification('success', 'Homepage Banner created!');
                                handleClosed();
                            }
                        } catch (error) {
                            notification('error', error.message);
                        }
                    }}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <Form action='#' method='POST'>
                            <InputField name='title' label='Banner Title' placeholder='Banner Title' type='text' maxLength={30} required />
                            <InputField
                                name='description'
                                label='Banner Description'
                                placeholder='Banner Description'
                                type='text'
                                maxLength={150}
                            />
                            <InputField
                                switches
                                name='hasProductButton'
                                placeholder='Banner has product button?'
                                label='Add product button for banner?'
                                switchLabel='Toggle to enable/disable product button for this banner'
                                type='checkbox'
                            />
                            {values.hasProductButton ? (
                                <div className='mb-3'>
                                    <label className='control-label'>
                                        Product for this banner <span className='span-error'>*</span>
                                    </label>
                                    <Select
                                        value={values.productId}
                                        isMulti={false}
                                        onChange={(selectval) => {
                                            setFieldValue('productId', selectval);
                                        }}
                                        options={productsList}
                                        defaultValue={productsList[0]}
                                        placeholder='Select Product'
                                    />
                                </div>
                            ) : null}
                            <InputField
                                name='image'
                                label='Banner Image - Size (1920x700)'
                                placeholder='Banner Image'
                                type='file'
                                fileInput
                                fileInputLabel={imageName}
                                accept='image/*'
                                onChange={imageupload}
                                required
                            />
                            <InputField
                                name='imageMobile'
                                label='Banner Image for Mobile - Size (1125x1170)'
                                placeholder='Banner Image for Mobile'
                                type='file'
                                fileInput
                                fileInputLabel={imageMobileName}
                                accept='image/*'
                                onChange={imageMobileUpload}
                                required
                            />
                            <div className='modal-footer p-0'>
                                <Button type='submit' color='primary' disabled={isSubmitting || createHomepageBannerLoading}>
                                    {isSubmitting ? (
                                        <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                                    ) : (
                                        'Save Banner'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalPopup>
            <div className='text-sm-right save-changes-button'>
                <Button
                    type='button'
                    color='primary'
                    className='btn-rounded me-1 waves-effect waves-light'
                    onClick={() => setIsHomepageBannerModalOpen(true)}
                >
                    <i className='mdi mdi-plus me-1' />
                    Add New Banner
                </Button>
            </div>
        </PageWrapper>
    );
};

export default withRouter(HomePageBanner);
