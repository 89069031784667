import { OrderStatus, updateShippingAddressSchema } from '@footykids/common';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Button, Card, Col, ModalFooter, Row, Table } from 'reactstrap';
import { InputField } from '../../../../components/Common/InputField';
import { ModalPopup } from '../../../../components/Common/ModalPopup';
import { notification } from '../../../../components/Utils/Notification';
import { OrderDataFragment, useUpdateOrderDeliveryAddressMutation } from '../../../../generated/graphql';

interface ShippingDeliveryDetailsProps {
    order: OrderDataFragment;
}

const ShippingDeliveryDetails: React.FC<ShippingDeliveryDetailsProps> = ({ order }) => {
    const [updateShippingModal, setUpdateShippingModal] = useState<boolean>(false);
    const [updateShipping, { loading: updateShippingLoading }] = useUpdateOrderDeliveryAddressMutation();

    return (
        <Card>
            <Row>
                <Col xs={12} md={6}>
                    <div className='table-responsive'>
                        <Table className='table table-nowrap'>
                            <tbody>
                                <tr>
                                    <td colSpan={2} className='border-top-0'>
                                        <h5>Shipping Details</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>First Name:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.orderBilling?.firstName}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Last Name:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.orderBilling?.lastName}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Phone Number:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.orderBilling?.phoneNumber}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%' style={{ verticalAlign: 'top' }}>
                                        Shipping Details:
                                    </td>
                                    <td width='80%'>
                                        {order.orderBilling!.shipToDifferentAddress ? (
                                            <p className='text-primary'>
                                                {order.orderBilling!.shippingHouseUnit ? (
                                                    <>
                                                        {order.orderBilling!.shippingHouseUnit}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {order.orderBilling!.shippingAddressLine1} <br />
                                                {order.orderBilling!.shippingAddressLine2 ? (
                                                    <>
                                                        {order.orderBilling!.shippingAddressLine2}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {order.orderBilling!.shippingPostalCode} <br />
                                                {order.orderBilling!.shippingCity} <br />
                                                {order.orderBilling!.shippingState} <br />
                                                {order.orderBilling!.shippingCountry} <br />
                                            </p>
                                        ) : (
                                            <p className='text-primary'>
                                                {order.orderBilling!.houseUnit ? (
                                                    <>
                                                        {order.orderBilling!.houseUnit}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {order.orderBilling!.addressLine1} <br />
                                                {order.orderBilling!.addressLine2 ? (
                                                    <>
                                                        {order.orderBilling!.addressLine2}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {order.orderBilling!.postalCode} <br />
                                                {order.orderBilling!.city} <br />
                                                {order.orderBilling!.state} <br />
                                                {order.orderBilling!.country} <br />
                                            </p>
                                        )}
                                        {order.orderStatus !== OrderStatus.SHIPPED && (
                                            <Button color='primary' size='sm' onClick={() => setUpdateShippingModal(true)}>
                                                Update Shipping Address
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <ModalPopup
                        isOpen={updateShippingModal}
                        handleClosed={() => setUpdateShippingModal(false)}
                        size='md'
                        title='Update Order Shipping Address'
                    >
                        <Formik
                            enableReinitialize
                            initialValues={{
                                addressLine1:
                                    (order.orderBilling?.shipToDifferentAddress
                                        ? order.orderBilling?.shippingAddressLine1
                                        : order.orderBilling?.addressLine1) || '',
                                addressLine2:
                                    (order.orderBilling?.shipToDifferentAddress
                                        ? order.orderBilling?.shippingAddressLine2
                                        : order.orderBilling?.addressLine2) || '',
                                houseUnit:
                                    (order.orderBilling?.shipToDifferentAddress
                                        ? order.orderBilling?.shippingHouseUnit
                                        : order.orderBilling?.houseUnit) || '',
                                city:
                                    (order.orderBilling?.shipToDifferentAddress
                                        ? order.orderBilling?.shippingCity
                                        : order.orderBilling?.city) || '',
                                postalCode:
                                    (order.orderBilling?.shipToDifferentAddress
                                        ? order.orderBilling?.shippingPostalCode
                                        : order.orderBilling?.postalCode) || '',
                                state:
                                    (order.orderBilling?.shipToDifferentAddress
                                        ? order.orderBilling?.shippingState
                                        : order.orderBilling?.state) || '',
                            }}
                            validationSchema={updateShippingAddressSchema}
                            onSubmit={async (values) => {
                                try {
                                    const response = await updateShipping({
                                        variables: {
                                            input: {
                                                orderId: order.id,
                                                country: 'Malaysia',
                                                ...values,
                                            },
                                        },
                                        update: (cache: any) => {
                                            cache.writeFragment({
                                                id: 'OrderBilling:' + order.orderBilling?.id,
                                                fragment: gql`
                                                    fragment __ on OrderBilling {
                                                        shipToDifferentAddress
                                                        shippingAddressLine1
                                                        shippingAddressLine2
                                                        shippingHouseUnit
                                                        shippingCity
                                                        shippingPostalCode
                                                        shippingState
                                                    }
                                                `,
                                                data: {
                                                    shipToDifferentAddress: true,
                                                    shippingAddressLine1: values.addressLine1,
                                                    shippingAddressLine2: values.addressLine2,
                                                    shippingHouseUnit: values.houseUnit,
                                                    shippingCity: values.city,
                                                    shippingPostalCode: values.postalCode,
                                                    shippingState: values.state,
                                                },
                                            });
                                        },
                                    });

                                    if (response.data?.updateOrderDeliveryAddress) {
                                        notification('success', 'Order updated!');
                                        setUpdateShippingModal(false);
                                    } else {
                                        notification('error', 'Failed to update order address!');
                                    }
                                } catch (error) {
                                    notification('error', error.message);
                                }
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <InputField
                                        name='addressLine1'
                                        label='Address Line 1'
                                        placeholder='Address Line 1'
                                        maxLength={60}
                                        required
                                    />
                                    <InputField name='addressLine2' label='Address Line 2' placeholder='Address Line 2' maxLength={60} />
                                    <InputField name='houseUnit' label='House Unit' placeholder='House Unit' maxLength={40} />
                                    <InputField name='city' label='City' placeholder='City' required maxLength={50} />
                                    <InputField name='postalCode' label='Postal Code' placeholder='Postal Code' required maxLength={10} />
                                    <InputField name='state' label='State' placeholder='State' required maxLength={50} />

                                    <ModalFooter>
                                        <Button color='primary' disabled={isSubmitting || updateShippingLoading} type='submit'>
                                            {updateShippingLoading ? (
                                                <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                                            ) : (
                                                'Update'
                                            )}
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </ModalPopup>
                </Col>
                <Col xs={12} md={6}>
                    <div className='table-responsive'>
                        <Table className='table table-nowrap'>
                            <tbody>
                                <tr>
                                    <td colSpan={2} className='border-top-0'>
                                        <h5>Delivery Details</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Total Weight:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.totalWeight} kg</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Courier Provider:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.courierProvider ? order.courierProvider : '-'}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Tracking Number:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.trackingNumber ? order.trackingNumber : '-'}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Courier Info:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.courierInfo ? order.courierInfo : '-'}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='20%'>Notes:</td>
                                    <td width='80%'>
                                        <span className='text-primary'>{order.orderBilling?.notes ? order.orderBilling.notes : '-'}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Card>
    );
};

export default ShippingDeliveryDetails;
