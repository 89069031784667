import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { notification as popup } from '../../../components/Utils/Notification';
import {
    NotificationDataFragment,
    useMarkReadNotificationMutation,
    useNewNotificationSubscription,
    useUnreadNotificationsQuery,
} from '../../../generated/graphql';
import { generateNotificationLink } from '../../../util/Helper';
dayjs.extend(relativeTime);

interface NotificationDropdownProps {
    t: any;
}

const NotificationDropdown: React.FC<NotificationDropdownProps> = ({ t }) => {
    const [menu, setMenu] = useState(false);
    const { data: notificationData, refetch } = useUnreadNotificationsQuery();
    const { data: newNotification } = useNewNotificationSubscription();
    const [markRead] = useMarkReadNotificationMutation();

    const markAsRead = async (notification: NotificationDataFragment) => {
        try {
            await markRead({
                variables: { id: notification.id },
                update: (cache) => {
                    cache.evict({ fieldName: 'unreadNotifications' });
                    cache.writeFragment({
                        id: 'Notification:' + notification.id,
                        fragment: gql`
                            fragment __ on Notification {
                                isRead
                            }
                        `,
                        data: { isRead: true },
                    });
                },
            });
        } catch (error) {
            popup('error', error.message);
        }
    };

    useEffect(() => {
        if (newNotification?.newNotification && newNotification?.newNotification.id && !newNotification.newNotification.isRead) {
            refetch();
        }
        // eslint-disable-next-line
    }, [newNotification]);

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='dropdown d-inline-block' tag='li'>
                <DropdownToggle className='btn header-item noti-icon ' tag='button' id='page-header-notifications-dropdown'>
                    <i className='bx bx-bell bx-tada' />
                    {notificationData && notificationData.unreadNotifications.totalUnread > 0 && (
                        <span className='badge bg-danger rounded-pill'>{notificationData.unreadNotifications.totalUnread}</span>
                    )}
                </DropdownToggle>

                <DropdownMenu className='dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end'>
                    <div className='p-3'>
                        <Row className='align-items-center'>
                            <Col>
                                <h6 className='m-0'> {t('Notifications')} </h6>
                            </Col>
                            <div className='col-auto'>
                                <span onClick={() => refetch()} className='small is-link'>
                                    <i className='mdi mdi-refresh' /> Refresh
                                </span>
                            </div>
                        </Row>
                    </div>

                    {notificationData && notificationData.unreadNotifications.notifications.length > 0 ? (
                        <>
                            <SimpleBar
                                style={{
                                    height:
                                        notificationData.unreadNotifications.notifications.length === 1
                                            ? '110px'
                                            : notificationData.unreadNotifications.notifications.length === 2
                                            ? '205px'
                                            : '230px',
                                }}
                            >
                                {notificationData &&
                                    notificationData.unreadNotifications.notifications &&
                                    notificationData.unreadNotifications.notifications.map((notification, key) => (
                                        <Link
                                            to={generateNotificationLink(notification)}
                                            onClick={() => markAsRead(notification)}
                                            key={key}
                                            className='text-reset notification-item'
                                        >
                                            <div className='media'>
                                                <div className='avatar-xs me-3'>
                                                    <span className='avatar-title bg-primary rounded-circle font-size-16'>
                                                        <i className='bx bx-cart' />
                                                    </span>
                                                </div>
                                                <div className='media-body'>
                                                    <h6 className='mt-0 mb-1'>{notification.title}</h6>
                                                    <div className='font-size-12 text-muted'>
                                                        <p className='mb-1'>{notification.message}</p>
                                                        <div className='mb-0 d-flex justify-content-between'>
                                                            <span>
                                                                <i className='mdi mdi-clock-outline' />{' '}
                                                                {dayjs(parseInt(notification.createdAt)).fromNow()}
                                                            </span>
                                                            {!notification.isRead && <span className='ms-2 text-danger'>Unread</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                            </SimpleBar>
                            <div className='p-2 border-top d-grid'>
                                <Link className='btn btn-sm btn-link font-size-14 btn-block text-center' to='/notifications'>
                                    <i className='mdi mdi-arrow-right-circle me-1'></i> {t('View all')}
                                </Link>
                            </div>
                        </>
                    ) : (
                        <div className='p-2 border-top d-grid'>
                            <p className='text-center'>No message at the moment</p>
                        </div>
                    )}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default withTranslation()(NotificationDropdown);
