import { ProductFormValues } from '@footykids/common';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import CardWrapper from '../../../../components/Common/CardWrapper';
import { InputField } from '../../../../components/Common/InputField';

const ProductVariation: React.FC<{}> = () => {
    const { values } = useFormikContext<ProductFormValues>();
    return (
        <CardWrapper title='Product Variations' subtitle='Please specify the product variations'>
            <FieldArray name='variations'>
                {({ remove, push }) => (
                    <div>
                        {values.variations.length > 0 &&
                            values.variations.map((variation, index, { length: variationLength }) => (
                                <Card className='p-3' key={index}>
                                    <div>
                                        <div className='d-flex flex-row'>
                                            <h6 className='align-self-center'>Variation</h6>
                                            <span className='ms-auto'>
                                                <Button
                                                    className='btn-sm'
                                                    disabled={variationLength - 1 === 0}
                                                    onClick={() => remove(index)}
                                                >
                                                    Remove Variation
                                                </Button>
                                            </span>
                                        </div>
                                    </div>
                                    <Row>
                                        <InputField name={`variations.${index}.id`} label='id' placeholder='id' displayNone />
                                        <Col>
                                            <InputField
                                                name={`variations.${index}.name`}
                                                label='Variation Name'
                                                placeholder='Variation Name'
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <FieldArray name={`variations.${index}.variationData`}>
                                        {({ remove: optionDataRemove, push: optionDataPush }) => (
                                            <Card className='p-3'>
                                                {variation.variationData.length > 0 &&
                                                    variation.variationData.map(
                                                        (data, variationDataIndex, { length: variationDataLength }) => (
                                                            <div key={variationDataIndex}>
                                                                <div>
                                                                    <div className='d-flex flex-row'>
                                                                        <h6 className='align-self-center'>Variation Data</h6>
                                                                        <span className='ms-auto'>
                                                                            <Button
                                                                                className='btn-sm'
                                                                                disabled={variationDataLength - 1 === 0}
                                                                                onClick={() => {
                                                                                    optionDataRemove(variationDataIndex);
                                                                                }}
                                                                            >
                                                                                <i className='bx bx-minus'></i>
                                                                            </Button>
                                                                            {variationDataIndex + 1 === variationDataLength ? (
                                                                                <Button
                                                                                    className='ms-1 btn-sm'
                                                                                    color='primary'
                                                                                    onClick={() =>
                                                                                        optionDataPush({
                                                                                            value: '',
                                                                                            unitPrice: '',
                                                                                            unitWeight: '',
                                                                                            unitsInStock: '',
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <i className='bx bx-plus'></i>
                                                                                </Button>
                                                                            ) : null}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <Row key={variationDataIndex}>
                                                                    <InputField
                                                                        name={`variations.${index}.variationData.${variationDataIndex}.id`}
                                                                        label='id'
                                                                        placeholder='id'
                                                                        displayNone
                                                                    />
                                                                    <Col lg={3}>
                                                                        <InputField
                                                                            name={`variations.${index}.variationData.${variationDataIndex}.value`}
                                                                            label='Value'
                                                                            placeholder='Value'
                                                                            type='text'
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <InputField
                                                                            name={`variations.${index}.variationData.${variationDataIndex}.unitPrice`}
                                                                            label='Unit Price'
                                                                            placeholder='Unit Price'
                                                                            type='text'
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <InputField
                                                                            name={`variations.${index}.variationData.${variationDataIndex}.unitWeight`}
                                                                            label='Unit Weight (in KG)'
                                                                            placeholder='Unit Weight'
                                                                            type='text'
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <InputField
                                                                            name={`variations.${index}.variationData.${variationDataIndex}.unitsInStock`}
                                                                            label='Units in Stock'
                                                                            placeholder='Units in Stock'
                                                                            type='number'
                                                                            min='0'
                                                                            required
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    )}
                                            </Card>
                                        )}
                                    </FieldArray>
                                </Card>
                            ))}
                        <Button
                            onClick={() =>
                                push({ name: '', variationData: [{ value: '', unitPrice: '', unitWeight: '', unitsInStock: '' }] })
                            }
                            color='primary'
                            className='w-100'
                        >
                            Add More
                        </Button>
                    </div>
                )}
            </FieldArray>
        </CardWrapper>
    );
};

export default ProductVariation;
