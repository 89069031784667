// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';

interface CKEditorComponentProps {
    name: string;
    label: string;
    required?: boolean;
}

const editorConfig = {
    toolbar: [
        'heading',
        '|',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        'specialCharacters',
        '|',
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList',
        'horizontalLine',
        '|',
        'alignment',
        'outdent',
        'indent',
        '|',
        'imageInsert',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'htmlEmbed',
        '|',
        'findAndReplace',
        '|',
        'undo',
        'redo',
    ],
};

const CKEditorComponent: React.FC<CKEditorComponentProps> = ({ required, ...props }) => {
    const { values, setFieldValue, errors }: any = useFormikContext();
    return (
        <FormGroup>
            <Label htmlFor={props.name}>
                {props.label} {required ? <span className='span-error'>*</span> : null}
            </Label>
            <CKEditor
                editor={Editor}
                config={editorConfig}
                data={values[props.name]}
                onChange={(event: any, editor: any) => {
                    const data = editor.getData();
                    setFieldValue(props.name, data);
                }}
            />
            <div>{errors[props.name] ? <span className='span-error'>{errors[props.name]}</span> : null}</div>
        </FormGroup>
    );
};

export default CKEditorComponent;
