import dayjs from 'dayjs';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { Col, InputGroup, Label, Row } from 'reactstrap';

interface OrderTableFilterProps {
    searchInput: string;
    setSearchInput: any;
    onSearchKeyword: any;
    options: any;
    setOptions: any;
}

const OrderTableFilter: React.FC<OrderTableFilterProps> = ({ searchInput, setSearchInput, onSearchKeyword, options, setOptions }) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} sm={6} md={3}>
                    <div className='app-search p-0'>
                        <Label htmlFor='search-input'>Search Order:</Label>
                        <div id='search-input' className='position-relative'>
                            <input
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                type='text'
                                className='form-control'
                                placeholder='Search...'
                                onKeyDown={onSearchKeyword}
                            />
                            <span className='bx bx-search-alt' />
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={3}></Col>
                <Col xs={6} sm={6} md={2}>
                    <div className='form-group row mx-1 mt-3 mt-md-0'>
                        <Label htmlFor='search-status' className='p-0'>
                            Order Status
                        </Label>
                        <select
                            id='search-status'
                            onChange={(e) => {
                                setOptions({ ...options, orderStatus: e.target.value, pageNumber: 0 });
                            }}
                            defaultValue={options.orderStatus}
                            className='form-select'
                        >
                            <option value='ALL'>All</option>
                            <option value='PENDING'>Pending</option>
                            <option value='SUCCESSFUL'>Successful</option>
                            <option value='CONFIRMED'>Confirmed</option>
                            <option value='SHIPPED'>Shipped</option>
                            <option value='FAILED'>Failed</option>
                            <option value='CANCELED'>Canceled</option>
                            <option value='SHIPPING FAILED'>Shipping Failed</option>
                        </select>
                    </div>
                </Col>
                <Col xs={6} sm={6} md={2}>
                    <div className='form-group row mx-1 mt-3 mt-md-0'>
                        <Label htmlFor='search-status' className='p-0'>
                            Payment Status
                        </Label>
                        <select
                            id='search-status'
                            onChange={(e) => {
                                setOptions({ ...options, paymentStatus: e.target.value, pageNumber: 0 });
                            }}
                            defaultValue={options.paymentStatus}
                            className='form-select'
                        >
                            <option value='ALL'>All</option>
                            <option value='ERROR'>Error</option>
                            <option value='UNSUCCESSFUL TRANSACTION'>Failed</option>
                            <option value='PENDING PAYMENT'>Pending</option>
                            <option value='PAID'>Paid</option>
                            <option value='UNPAID'>Unpaid</option>
                            <option value='TO REFUND'>To Refund</option>
                            <option value='REFUNDED'>Refunded</option>
                        </select>
                    </div>
                </Col>
                <Col xs={6} sm={6} md={2}>
                    <div className='docs-datepicker mt-3 mt-md-0'>
                        <Label htmlFor='search-date'>Order Date</Label>
                        <InputGroup>
                            <Flatpickr
                                id='search-date'
                                className='form-control d-block'
                                placeholder='Sort by Order Date'
                                options={{
                                    mode: 'range',
                                    altInput: true,
                                    dateFormat: 'd-m-y',
                                }}
                                onChange={(date) => {
                                    const pickDate: string[] = [];
                                    date.map((date) => {
                                        return pickDate.push(dayjs(date).format('YYYY-MM-DD'));
                                    });
                                    setOptions({ ...options, dateFilter: pickDate });
                                }}
                            />
                            <div className='input-group-append'>
                                <button type='button' className='btn btn-outline-secondary docs-datepicker-trigger' disabled>
                                    <i className='fa fa-calendar' aria-hidden='true' />
                                </button>
                            </div>
                        </InputGroup>
                        <div className='docs-datepicker-container' />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OrderTableFilter;
