import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import footykidsLogo from '../../assets/images/footykids.svg';
//i18n
import { withTranslation, WithTranslationProps } from 'react-i18next';
import SidebarContent from './SidebarContent';

interface SidebarProps {
    type: string;
    theme?: any;
    isMobile?: boolean;
}

const Sidebar: React.FC<SidebarProps & RouteComponentProps & WithTranslationProps> = ({ type }) => {
    return (
        <React.Fragment>
            <div className='vertical-menu'>
                <div className='navbar-brand-box'>
                    <Link to='/' className='logo logo-dark'>
                        <span className='logo-sm'>
                            <img src={logo} alt='' height='22' />
                        </span>
                        <span className='logo-lg'>
                            <img src={footykidsLogo} alt='' height='17' />
                        </span>
                    </Link>

                    <Link to='/' className='logo logo-light'>
                        <span className='logo-sm'>
                            <img src={logo} alt='' height='22' />
                        </span>
                        <span className='logo-lg'>
                            <img src={footykidsLogo} alt='' height='35' className='mt-3' />
                        </span>
                    </Link>
                </div>
                <div data-simplebar className='h-100'>
                    {type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
                </div>
                <div className='sidebar-background'></div>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state: any) => {
    return {
        layout: state.Layout,
    };
};
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)));
