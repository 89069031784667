import React from 'react';
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';
import PageWrapper from '../../../components/Common/PageWrapper';
import { useGetFldSettingsQuery } from '../../../generated/graphql';
import FLDIsMaintenanceForm from './Components/FLDIsMaintenanceForm';
import PrivacyPolicyForm from './Components/PrivacyPolicyForm';
import ShipCodeAndStateForm from './Components/ShipCodeAndStateForm';

interface FLDSettingsProps {}

const FLDSettings: React.FC<FLDSettingsProps> = () => {
    const { data: fldSettingsData } = useGetFldSettingsQuery();

    return (
        <PageWrapper pageTitle='All Settings' pageCategory='FLD Store' breadCrumbTitle='FLD' breadCrumbItem='Settings'>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>FLD Store Settings</CardTitle>
                            <CardSubtitle className='mb-2'>Include all variable/setting that you need</CardSubtitle>
                            <Row className='pt-3'>
                                <FLDIsMaintenanceForm isMaintenance={fldSettingsData?.getFLDSettings.isMaintenance} />
                                <ShipCodeAndStateForm cityAndStateData={fldSettingsData?.getFLDSettings.shipCityAndState} />
                                <PrivacyPolicyForm privacyPolicyData={fldSettingsData?.getFLDSettings.privacyPolicy} />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default FLDSettings;
