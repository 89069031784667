import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

export function notification(variant: string, message: string) {
    toastr.options = {
        positionClass: 'toast-top-right',
        timeOut: 3000,
        extendedTimeOut: 2000,
        closeButton: true,
        debug: false,
        progressBar: true,
        preventDuplicates: true,
        newestOnTop: false,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 300,
    };

    if (variant === 'info') {
        return toastr.info(message, '');
    } else if (variant === 'warning') {
        return toastr.warning(message, '');
    } else if (variant === 'error') {
        return toastr.error(message, '');
    } else {
        return toastr.success(message, '');
    }
};
