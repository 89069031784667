import { convertCentsToPrice } from '@footykids/common';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FldData } from '../../generated/graphql';

interface FLDEarningProps {
    FLDData: FldData;
}

const FLDEarning: React.FC<FLDEarningProps> = ({ FLDData }) => {
    const series = [
        {
            name: 'Total Sales',
            data: FLDData.earnings.earningPerDay,
        },
    ];

    const options = {
        chart: {
            toolbar: 'false',
            dropShadow: {
                enabled: !0,
                color: '#000',
                top: 18,
                left: 7,
                blur: 8,
                opacity: 0.2,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        colors: ['#556ee6'],
        stroke: {
            curve: 'smooth',
            width: 3,
        },
    };

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className='clearfix'>
                        {/* <div className='float-end'>
                            <div className='input-group input-group'>
                                <select className='form-select form-select-sm'>
                                    <option defaultValue='JA'>Jan</option>
                                    <option value='DE'>Dec</option>
                                    <option value='NO'>Nov</option>
                                    <option value='OC'>Oct</option>
                                </select>
                                <label className='input-group-text'>Month</label>
                            </div>
                        </div> */}
                        <h4 className='card-title mb-4'>FLD Store Earning</h4>
                    </div>

                    <Row>
                        <Col lg='4'>
                            <div className='text-muted'>
                                <div className='mb-4'>
                                    <p>This month</p>
                                    <h4>RM {convertCentsToPrice(FLDData.earnings.currentMonthEarnings)}</h4>
                                    <div>
                                        Total Earning For
                                        <span className='badge badge-soft-primary font-size-12 ms-2'>
                                            {monthNames[new Date().getMonth()]}
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <Link to='/orders' className='btn btn-primary  btn-sm'>
                                        View Details <i className='mdi mdi-chevron-right ms-1'></i>
                                    </Link>
                                </div>

                                <div className='mt-4'>
                                    <p className='mb-3'>Total Earning</p>
                                    <h4>RM {convertCentsToPrice(FLDData.earnings.currentEarnings)}</h4>
                                </div>
                            </div>
                        </Col>

                        <Col lg='8'>
                            <div id='line-chart' dir='ltr'>
                                <ReactApexChart series={series} options={options as any} type='line' height={320} className='apex-charts' />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default FLDEarning;
