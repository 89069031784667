import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Thead, Tr, Th, Tbody } from 'react-super-responsive-table';
import { UncontrolledTooltip } from 'reactstrap';

interface AcademyVenueTableProps {}

const AcademyVenueTable: React.FC<AcademyVenueTableProps> = ({ ...props }) => {
    return (
        <React.Fragment>
            <div className='table-responsive mb-0'>
                <Table id='tech-companies-1' className='table'>
                    <Thead className='table-light'>
                        <Tr>
                            <Th width='20%'>Venue</Th>
                            <Th width='15%'>Location</Th>
                            <Th width='10%'>State</Th>
                            <Th width='10%'>Training Day</Th>
                            <Th width='10%'>Monthly Fee</Th>
                            <Th width='10%'>Registration Fee</Th>
                            <Th width='8%' className='text-center'>
                                Trial?
                            </Th>
                            <Th width='8%' className='text-center'>
                                Max Trial
                            </Th>
                            <Th width='10%' className='text-center'>
                                Action
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <tr>
                            <td className='text-body fw-bold'>Quantum Futsal</td>
                            <td>Lot 3049, Jalan Datuk Sulaiman, Kampung Sungai Penchala, 60000 Kuala Lumpur</td>
                            <td>W.P KUALA LUMPUR</td>
                            <td>SUNDAY</td>
                            <td>RM 70.00</td>
                            <td>RM 80.00</td>
                            <td className='text-center'>Yes</td>
                            <td className='text-center'>3</td>
                            <td className='text-center'>
                                <ul className='list-inline font-size-20 contact-links mb-0'>
                                    <li className='list-inline-item px-1'>
                                        <Link to={`/#`} id={'edit' + 1}>
                                            <i className='bx bx-edit' />
                                            <UncontrolledTooltip placement='top' target={'edit' + 1}>
                                                Edit
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                    <li className='list-inline-item px-1'>
                                        <Link to='#' id={'delete' + 1}>
                                            <i className='bx bxs-trash' />
                                            <UncontrolledTooltip placement='top' target={'delete' + 1}>
                                                Delete
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                    <li className='list-inline-item px-1'>
                                        <Link to='#' id={'publish' + 1}>
                                            <i className={`bx bx-check-circle`} />
                                            <UncontrolledTooltip placement='top' target={'publish' + 1}>
                                                Publish
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </Tbody>
                </Table>
            </div>
        </React.Fragment>
    );
};

export default AcademyVenueTable;
