import { useFormikContext } from 'formik';
import React from 'react';
import Loader from 'react-loader-spinner';
import { Button } from 'reactstrap';

interface BottomRightSaveButtonProps {
    title: string;
    isLoading?: boolean;
}

export const BottomRightSaveButton: React.FC<BottomRightSaveButtonProps> = ({ ...props }) => {
    const { isSubmitting, isValid } = useFormikContext();
    return (
        <div className='text-sm-right save-changes-button'>
            <Button disabled={!isValid || isSubmitting} type='submit' color='primary' className='me-1 waves-effect waves-light'>
                {props.isLoading || isSubmitting ? (
                    <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                ) : (
                    <>
                        <i className='far fa-save me-3' />
                        {props.title}
                    </>
                )}
            </Button>
        </div>
    );
};
