import { AcademyVenueInitialValues, AcademyVenueSchema } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Button, Col, Row } from 'reactstrap';
import { InputField } from '../../../../components/Common/InputField';
import { ModalPopup } from '../../../../components/Common/ModalPopup';
import { notification } from '../../../../components/Utils/Notification';

interface AddVenueModalProps {
    isOpen: boolean;
    handleClosed: any;
}

const AddVenueModal: React.FC<AddVenueModalProps> = ({ isOpen, handleClosed }) => {
    const [imageName, setImageName] = useState<string>('No file chosen');
    const [imageToUpload, setImageToUpload] = useState<any>(undefined);

    const imageupload = (e: any) => {
        if (e.target.files) {
            setImageName(e.target.files[0].name);
            setImageToUpload(e.target.files[0]);
        }
    };

    return (
        <React.Fragment>
            <ModalPopup isOpen={isOpen} handleClosed={handleClosed} size='lg' title='Add New Academy Venue'>
                <Formik
                    enableReinitialize
                    initialValues={AcademyVenueInitialValues}
                    validationSchema={AcademyVenueSchema}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            console.log(values);
                            console.log(imageToUpload);
                        } catch (error) {
                            notification('error', error.message);
                        }
                    }}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <Row>
                                <Col xs={12} md={6}>
                                    <InputField name='venue' label='Venue Name' placeholder='Venue Name' type='text' required />
                                    <InputField
                                        name='description'
                                        label='Venue Description'
                                        placeholder='Venue Description'
                                        type='text'
                                        textarea
                                        rows={7}
                                        required
                                    />
                                    <InputField name='ageLimit' placeholder='Enter Age Limit' label='Age Limit' type='number' />
                                    <div className='mb-3'>
                                        <label className='control-label'>
                                            Training Day <span className='span-error'>*</span>
                                        </label>
                                        <select
                                            className='form-select'
                                            name='trainingDay'
                                            aria-label='Training Day'
                                            defaultValue={values.trainingDay}
                                            onChange={(e) => setFieldValue('trainingDay', e.target.value)}
                                        >
                                            <option value='MONDAY'>MONDAY</option>
                                            <option value='TUESDAY'>TUESDAY</option>
                                            <option value='WEDNESDAY'>WEDNESDAY</option>
                                            <option value='THURSDAY'>THURSDAY</option>
                                            <option value='FRIDAY'>FRIDAY</option>
                                            <option value='SATURDAY'>SATURDAY</option>
                                            <option value='SUNDAY'>SUNDAY</option>
                                        </select>
                                    </div>
                                    <InputField name='fee' placeholder='Monthly Fee' label='Monthly Fee' type='text' required />
                                    <InputField
                                        name='registrationFee'
                                        placeholder='Registration Fee'
                                        label='Registration Fee'
                                        type='text'
                                        required
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputField
                                        name='location'
                                        label='Venue Location'
                                        placeholder='Venue Location/Address'
                                        type='text'
                                        textarea
                                        rows={3}
                                        required
                                    />
                                    <div className='mb-3'>
                                        <label className='control-label'>
                                            Location State <span className='span-error'>*</span>
                                        </label>
                                        <select
                                            className='form-select'
                                            name='locationState'
                                            aria-label='Location state'
                                            defaultValue={values.locationState}
                                            onChange={(e) => setFieldValue('locationState', e.target.value)}
                                        >
                                            <option value='KUALALUMPUR'>KUALA LUMPUR</option>
                                            <option value='LABUAN'>LABUAN</option>
                                            <option value='PUTRAJAYA'>PUTRAJAYA</option>
                                            <option value='JOHOR'>JOHOR</option>
                                            <option value='KEDAH'>KEDAH</option>
                                            <option value='KELANTAN'>KELANTAN</option>
                                            <option value='NEGERISEMBILAN'>NEGERI SEMBILAN</option>
                                            <option value='JOHOR'>JOHOR</option>
                                            <option value='JOHOR'>JOHOR</option>
                                            <option value='JOHOR'>JOHOR</option>
                                            <option value='JOHOR'>JOHOR</option>
                                            <option value='JOHOR'>JOHOR</option>
                                        </select>
                                    </div>
                                    <InputField
                                        name='startDate'
                                        label='Start Date'
                                        placeholder='Start Date'
                                        type='date'
                                        min={new Date().toISOString().split('T')[0]}
                                        required
                                    />
                                    <InputField
                                        name='image'
                                        label='Venue Image'
                                        placeholder='Venue Image'
                                        type='file'
                                        fileInput
                                        fileInputLabel={imageName}
                                        accept='image/*'
                                        onChange={imageupload}
                                        required
                                    />
                                    <InputField
                                        switches
                                        name='isTrialAvailable'
                                        placeholder='Trial Available?'
                                        label='Trial Available?'
                                        type='checkbox'
                                    />
                                    <InputField
                                        name='trialFee'
                                        placeholder='Trial Fee'
                                        label='Trial Fee'
                                        type='text'
                                        disabled={!values.isTrialAvailable}
                                        required={values.isTrialAvailable}
                                    />
                                    <InputField
                                        name='maxTrial'
                                        placeholder='Enter Max Trial'
                                        label='Max Trial'
                                        type='number'
                                        disabled={!values.isTrialAvailable}
                                        required={values.isTrialAvailable}
                                    />
                                </Col>
                            </Row>

                            <div className='modal-footer p-0'>
                                <Button type='submit' color='primary' disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                                    ) : (
                                        'Save Venue'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalPopup>
        </React.Fragment>
    );
};

export default AddVenueModal;
