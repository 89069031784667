import { gql } from '@apollo/client';
import { OrderStatus, ShipOrderSchema } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { InputField } from '../../../../components/Common/InputField';
import { ModalPopup } from '../../../../components/Common/ModalPopup';
import { notification } from '../../../../components/Utils/Notification';
import { OrderDataFragment, useCancelOrderMutation, useConfirmOrderMutation, useShipOrderMutation } from '../../../../generated/graphql';

interface OrderActionProps {
    orderId: number;
    order: OrderDataFragment;
}

const OrderAction: React.FC<OrderActionProps> = ({ orderId, order }) => {
    const [confirmOrderModal, setConfirmOrderModal] = useState<boolean>(false);
    const [cancelOrderModal, setCancelOrderModal] = useState<boolean>(false);
    const [shipOrderModal, setShipOrderModal] = useState<boolean>(false);
    const [confirmOrder, { loading: confirmOrderLoading }] = useConfirmOrderMutation();
    const [cancelOrder, { loading: cancelOrderLoading }] = useCancelOrderMutation();
    const [shipOrder, { loading: shipOrderLoading }] = useShipOrderMutation();

    const onConfirmOrder = async () => {
        try {
            const response = await confirmOrder({
                variables: {
                    id: Number(orderId),
                },
                update: (cache: any) => {
                    cache.writeFragment({
                        id: 'Order:' + orderId,
                        fragment: gql`
                            fragment __ on Order {
                                orderStatus
                            }
                        `,
                        data: { orderStatus: OrderStatus.CONFIRMED },
                    });
                },
            });

            if (response.data?.confirmOrder) {
                notification('success', 'Order updated!');
                setConfirmOrderModal(false);
            }
        } catch (error) {
            notification('error', error.message);
        }
    };

    const onCancelOrder = async () => {
        try {
            const response = await cancelOrder({
                variables: {
                    id: Number(orderId),
                },
                update: (cache: any) => {
                    cache.writeFragment({
                        id: 'Order:' + orderId,
                        fragment: gql`
                            fragment __ on Order {
                                orderStatus
                            }
                        `,
                        data: { orderStatus: OrderStatus.CANCELED },
                    });
                },
            });

            if (response.data?.cancelOrder) {
                notification('success', 'Order updated!');
                setCancelOrderModal(false);
            }
        } catch (error) {
            notification('error', error.message);
        }
    };
    return (
        <React.Fragment>
            <div className='d-flex flex-column align-items-end'>
                <div>
                    {order.orderStatus === OrderStatus.SUCCESSFUL && (
                        <Button color='primary' outline className='me-2' onClick={() => setConfirmOrderModal(true)}>
                            Confirm Order
                        </Button>
                    )}
                    {order.orderStatus === OrderStatus.CONFIRMED && (
                        <Button color='primary' className='me-2' outline onClick={() => setShipOrderModal(true)}>
                            Ship Order
                        </Button>
                    )}
                    {(order.orderStatus === OrderStatus.SUCCESSFUL || order.orderStatus === OrderStatus.CONFIRMED) && (
                        <Button color='danger' outline onClick={() => setCancelOrderModal(true)}>
                            Cancel Order
                        </Button>
                    )}
                </div>
                {order.orderStatus === OrderStatus.SUCCESSFUL && (
                    <span className='text-danger mt-2 text-end align-bottom'>
                        Please confirm this order. If order is canceled, refund need to pay to customer manually.
                    </span>
                )}
                {order.orderStatus === OrderStatus.CONFIRMED && (
                    <span className='text-danger mt-2 text-end align-bottom'>Please ship this order.</span>
                )}

                <ModalPopup
                    isOpen={cancelOrderModal}
                    handleClosed={() => setCancelOrderModal(false)}
                    size='sm'
                    title='Are you sure to cancel order?'
                >
                    <ModalBody>You won't be able to revert this!</ModalBody>
                    <ModalFooter>
                        {!cancelOrderLoading && (
                            <Button color='secondary' onClick={() => setCancelOrderModal(false)}>
                                No
                            </Button>
                        )}
                        <Button color='primary' disabled={cancelOrderLoading} onClick={onCancelOrder}>
                            {cancelOrderLoading ? <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} /> : 'Yes'}
                        </Button>
                    </ModalFooter>
                </ModalPopup>
                <ModalPopup
                    isOpen={confirmOrderModal}
                    handleClosed={() => setConfirmOrderModal(false)}
                    size='sm'
                    title='Confirm this order?'
                >
                    <ModalBody>You won't be able to revert this!</ModalBody>
                    <ModalFooter>
                        {!confirmOrderLoading && (
                            <Button color='secondary' onClick={() => setConfirmOrderModal(false)}>
                                No
                            </Button>
                        )}
                        <Button color='primary' disabled={confirmOrderLoading} onClick={onConfirmOrder}>
                            {confirmOrderLoading ? <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} /> : 'Yes'}
                        </Button>
                    </ModalFooter>
                </ModalPopup>
                <ModalPopup isOpen={shipOrderModal} handleClosed={() => setShipOrderModal(false)} size='md' title='Shipping Info'>
                    <Formik
                        enableReinitialize
                        initialValues={{ courierProvider: '', trackingNumber: '', courierInfo: '' }}
                        validationSchema={ShipOrderSchema}
                        onSubmit={async (values) => {
                            try {
                                const response = await shipOrder({
                                    variables: {
                                        input: {
                                            id: Number(orderId),
                                            courierProvider: values.courierProvider,
                                            trackingNumber: values.trackingNumber,
                                            courierInfo: values.courierInfo,
                                        },
                                    },
                                    update: (cache: any) => {
                                        cache.writeFragment({
                                            id: 'Order:' + orderId,
                                            fragment: gql`
                                                fragment __ on Order {
                                                    orderStatus
                                                    courierProvider
                                                    trackingNumber
                                                    courierInfo
                                                }
                                            `,
                                            data: {
                                                orderStatus: OrderStatus.SHIPPED,
                                                courierProvider: values.courierProvider,
                                                trackingNumber: values.trackingNumber,
                                                courierInfo: values.courierInfo,
                                            },
                                        });
                                    },
                                });

                                if (response.data?.shipOrder) {
                                    notification('success', 'Order updated!');
                                    setShipOrderModal(false);
                                } else {
                                    notification('error', 'Failed to ship order!');
                                }
                            } catch (error) {
                                notification('error', error.message);
                            }
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <InputField name='courierProvider' label='Courier Provider' placeholder='Enter courier provider' required />
                                <InputField
                                    name='trackingNumber'
                                    label='Tracking Number'
                                    placeholder='Enter tracking number or orderNo if self-delivery'
                                    required
                                />
                                <InputField
                                    name='courierInfo'
                                    label='Courier Additional Info (optional)'
                                    placeholder='Enter courier additional info'
                                />
                                <ModalFooter>
                                    <Button color='primary' type='submit' disabled={isSubmitting || shipOrderLoading}>
                                        {isSubmitting ? (
                                            <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                                        ) : (
                                            'Ship Order'
                                        )}
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalPopup>
            </div>
        </React.Fragment>
    );
};

export default OrderAction;
