import { convertCentsToPrice } from '@footykids/common';
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { FklData } from '../../generated/graphql';

interface FKLDataProps {
    FKLData: FklData;
}

const FKLData: React.FC<FKLDataProps> = ({ FKLData }) => {
    return (
        <React.Fragment>
            <Col sm='6'>
                <Card>
                    <CardBody>
                        <div className='d-flex align-items-center mb-3'>
                            <div className='avatar-xs me-3'>
                                <span className='avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18'>
                                    <i className='fab fa-shirtsinbulk' />
                                </span>
                            </div>
                            <h5 className='font-size-14 mb-0'>FKL Total Payment</h5>
                        </div>
                        <div className='text-muted mt-4'>
                            <h4>RM {convertCentsToPrice(FKLData.payments.currentTotalPayment)}</h4>
                            <div className='d-flex'>
                                <span className='text-truncate'>Total For Current Month:</span>
                                <span className='ms-2'>RM {convertCentsToPrice(FKLData.payments.currentMonthTotalPayment)}</span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col sm='6'>
                <Card>
                    <CardBody>
                        <div className='d-flex align-items-center mb-3'>
                            <div className='avatar-xs me-3'>
                                <span className='avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18'>
                                    <i className='fas fa-futbol' />
                                </span>
                            </div>
                            <h5 className='font-size-14 mb-0'>FKL Team Registered</h5>
                        </div>
                        <div className='text-muted mt-4'>
                            <h4>{FKLData.totalTeams} Team</h4>
                            <div className='d-flex'>
                                <span className='text-truncate'>Current Team Registered in FKL</span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default FKLData;
