import React from 'react';
import { Badge } from 'reactstrap';
import { useOrderStatusCountQuery } from '../../../../generated/graphql';

interface OrderCountProps {}

const OrderCount: React.FC<OrderCountProps> = () => {
    const { data } = useOrderStatusCountQuery();

    return (
        <React.Fragment>
            {data && data.orderStatusCount && (
                <div className='mb-4 d-none d-md-block'>
                    <Badge className='me-2 bg-primary font-size-12 fw-normal'>Total Order: {data.orderStatusCount.totalOrders}</Badge>
                    <Badge className='me-2 bg-success font-size-12 fw-normal'>Successful Order: {data.orderStatusCount.successfulOrders}</Badge>
                    <Badge className='me-2 bg-warning font-size-12 fw-normal'>Pending Order: {data.orderStatusCount.pendingOrders}</Badge>
                    <Badge className='me-2 bg-danger font-size-12 fw-normal'>Failed Order: {data.orderStatusCount.failedOrders}</Badge>
                    <Badge className='me-2 bg-success font-size-12 fw-normal'>Confirmed Order: {data.orderStatusCount.confirmedOrders}</Badge>
                    <Badge className='me-2 bg-danger font-size-12 fw-normal'>Canceled Order: {data.orderStatusCount.canceledOrders}</Badge>
                    <Badge className='me-2 bg-info font-size-12 fw-normal'>Order Shipped: {data.orderStatusCount.shippedOrders}</Badge>
                </div>
            )}
        </React.Fragment>
    );
};

export default OrderCount;
