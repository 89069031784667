import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-super-responsive-table';
import { Badge, Card, CardBody, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { notification as popup } from '../../components/Utils/Notification';
import { NotificationDataFragment, useMarkReadNotificationMutation } from '../../generated/graphql';
import { generateNotificationLink } from '../../util/Helper';
dayjs.extend(relativeTime);

interface NotificationCardProps {
    notifications: NotificationDataFragment[];
}

const NotificationCard: React.FC<NotificationCardProps> = ({ notifications }) => {
    const [markRead] = useMarkReadNotificationMutation();
    const markAsRead = async (notification: NotificationDataFragment) => {
        try {
            await markRead({
                variables: { id: notification.id },
                update: (cache) => {
                    cache.evict({ fieldName: 'unreadNotifications' });
                    cache.writeFragment({
                        id: 'Notification:' + notification.id,
                        fragment: gql`
                            fragment __ on Notification {
                                isRead
                            }
                        `,
                        data: { isRead: true },
                    });
                },
            });
        } catch (error) {
            popup('error', error.message);
        }
    };
    return (
        <React.Fragment>
            <Col sm='12'>
                <Card>
                    <CardBody>
                        <h4 className='card-title mb-4'>Notifications</h4>
                        <div className='mt-4'>
                            <SimpleBar>
                                <div className='table-responsive'>
                                    <Table className='table table-nowrap align-middle table-hover mb-0'>
                                        <tbody>
                                            {notifications.map((notification, key) => (
                                                <tr key={key}>
                                                    <td>
                                                        <h5 className='text-truncate font-size-14 mb-1'>{notification.title}</h5>
                                                        <p className='text-muted mb-0'>
                                                            {notification.message.substring(0, 50)}
                                                            {notification.message.length > 50 && '...'}
                                                        </p>
                                                    </td>
                                                    <td style={{ width: '90px' }} className='text-center'>
                                                        <Badge className='me-2 bg-primary font-size-11'>{notification.type}</Badge>
                                                    </td>
                                                    <td style={{ width: '90px' }} className='text-end'>
                                                        <div>
                                                            <span>
                                                                <i className='mdi mdi-clock-outline me-1' />{' '}
                                                                {dayjs(parseInt(notification.createdAt)).fromNow()}
                                                            </span>
                                                            {!notification.isRead && <span className='ms-2 text-danger'>Unread</span>}
                                                        </div>
                                                    </td>
                                                    <td style={{ width: '90px' }}>
                                                        <Link
                                                            to={generateNotificationLink(notification)}
                                                            onClick={() => markAsRead(notification)}
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            View Details
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </SimpleBar>
                        </div>
                        <div className='text-center mt-3'>
                            <Link to='/notifications' className='btn btn-primary waves-effect waves-light btn-sm'>
                                View More <i className='mdi mdi-arrow-right ms-1' />
                            </Link>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default NotificationCard;
