import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import PageWrapper from '../../components/Common/PageWrapper';
import { notification as popup } from '../../components/Utils/Notification';
import {
    NotificationDataFragment,
    useMarkAllAsReadNotificationMutation,
    useMarkReadNotificationMutation,
    useNotificationsQuery,
} from '../../generated/graphql';
import { generateNotificationLink } from '../../util/Helper';
dayjs.extend(relativeTime);

interface NotificationProps {}

const Notification: React.FC<NotificationProps> = () => {
    const [sizePerPage] = useState<number>(15);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const [notificationType, setNotificationType] = useState<string>('ALL');

    const [markRead] = useMarkReadNotificationMutation();
    const [markAllRead] = useMarkAllAsReadNotificationMutation();
    const { data } = useNotificationsQuery({
        variables: {
            option: { pageNumber: pageNumber, sizePerPage: sizePerPage, type: notificationType },
        },
    });

    useEffect(() => {
        if (data && data.notifications.pageCount) {
            setPageCount(data.notifications.pageCount);
        }
    }, [data]);

    const handlePageClick = (page: any) => {
        setPageNumber(page.selected);
    };

    const markAsRead = async (notification: NotificationDataFragment) => {
        try {
            await markRead({
                variables: { id: notification.id },
                update: (cache) => {
                    cache.evict({ fieldName: 'unreadNotifications' });
                    cache.writeFragment({
                        id: 'Notification:' + notification.id,
                        fragment: gql`
                            fragment __ on Notification {
                                isRead
                            }
                        `,
                        data: { isRead: true },
                    });
                },
            });
        } catch (error) {
            popup('error', error.message);
        }
    };

    const markAllAsRead = async () => {
        try {
            await markAllRead({
                update: (cache) => {
                    cache.evict({ fieldName: 'notifications' });
                },
            });
        } catch (error) {
            popup('error', error.message);
        }
    };

    return (
        <PageWrapper pageTitle='Notifications' pageCategory='Footykids' breadCrumbTitle='Footykids' breadCrumbItem='Notifications'>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs={12} md={10} style={{ alignSelf: 'center' }}>
                                    <h4 className='card-title mb-2'>Notification Messages</h4>
                                    {data && data.notifications.notifications.length > 0 && (
                                        <Button size='sm' color='primary' onClick={markAllAsRead}>
                                            Mark All as Read
                                        </Button>
                                    )}
                                </Col>
                                <Col xs={12} md={2}>
                                    <div className='form-group row mx-1 mt-3 mt-md-0'>
                                        <label className='ps-0'>Category: </label>
                                        <select
                                            onChange={(e) => setNotificationType(e.target.value)}
                                            defaultValue={notificationType}
                                            className='form-select'
                                        >
                                            <option value='ALL'>All</option>
                                            <option value='FKL'>FKL</option>
                                            <option value='FKA'>FKA</option>
                                            <option value='FLD'>FLD</option>
                                            <option value='FKORIGINAL'>FKORIGINAL</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>

                            <div className='mt-4'>
                                {data && data.notifications.notifications.length > 0 ? (
                                    <SimpleBar>
                                        <div className='table-responsive'>
                                            <Table className='table table-nowrap align-middle table-hover mb-0'>
                                                <tbody>
                                                    {data.notifications.notifications.map((notification, key) => (
                                                        <tr key={key}>
                                                            <td>
                                                                <h5 className='text-truncate font-size-14 mb-1'>
                                                                    <Link
                                                                        to={generateNotificationLink(notification)}
                                                                        onClick={() => markAsRead(notification)}
                                                                        className='text-dark'
                                                                    >
                                                                        {notification.title}
                                                                    </Link>
                                                                </h5>
                                                                <p className='text-muted mb-0'>{notification.message}</p>
                                                            </td>
                                                            <td style={{ width: '90px' }} className='text-center'>
                                                                <Badge className='me-2 bg-primary font-size-11'>{notification.type}</Badge>
                                                            </td>
                                                            <td style={{ width: '90px' }} className='text-end'>
                                                                <div>
                                                                    <span>
                                                                        <i className='mdi mdi-clock-outline' />{' '}
                                                                        {dayjs(parseInt(notification.createdAt)).fromNow()}
                                                                    </span>
                                                                    {!notification.isRead && (
                                                                        <span className='ms-2 text-danger'>Unread</span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td style={{ width: '90px' }}>
                                                                <Link
                                                                    to={generateNotificationLink(notification)}
                                                                    onClick={() => markAsRead(notification)}
                                                                    className='btn btn-primary btn-sm'
                                                                >
                                                                    View Details
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </SimpleBar>
                                ) : (
                                    <p className='text-center'>No new message at the moment.</p>
                                )}
                            </div>
                        </CardBody>

                        <div className='card-footer bg-transparent border-top'>
                            <div className='text-center'>
                                <ReactPaginate
                                    initialPage={pageNumber}
                                    forcePage={pageNumber}
                                    previousLabel={'Prev'}
                                    previousClassName='page-item'
                                    previousLinkClassName='page-link'
                                    nextLabel={'Next'}
                                    nextClassName='page-item'
                                    nextLinkClassName='page-link'
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination react-bootstrap-table-page-btns-ul'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName='page-link'
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default withRouter(Notification);
