import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { Badge, UncontrolledTooltip } from 'reactstrap';

interface NewsTableProps {}

const NewsTable: React.FC<NewsTableProps> = ({ ...props }) => {
    return (
        <React.Fragment>
            <div className='table-responsive mb-0'>
                <Table id='tech-companies-1' className='table'>
                    <Thead className='table-light'>
                        <Tr>
                            <Th width='5%'>Cover</Th>
                            <Th width='27%'>Title</Th>
                            <Th width='27%'>Slug</Th>
                            <Th width='10%'>Category</Th>
                            <Th width='10%'>Type</Th>
                            <Th width='10%'>Created Date</Th>
                            <Th width='10%' className='text-center'>
                                Action
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <tr>
                            <td>
                                <img
                                    src={
                                        'https://cdn.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_1200/https://worldsoccertalk.com/wp-content/uploads/2021/05/euro-2020-univision-coverage.jpeg'
                                    }
                                    alt={'test'}
                                    className='img-fluid'
                                />
                            </td>
                            <td className='text-body fw-bold'>UEFA Euro 2020</td>
                            <td>W.P KUALA LUMPUR</td>
                            <td>
                                <Badge className={`font-size-12 badge-soft-fkl`}>FKL</Badge>
                            </td>
                            <td>Slider</td>
                            <td>November 12, 2021</td>
                            <td className='text-center'>
                                <ul className='list-inline font-size-20 contact-links mb-0'>
                                    <li className='list-inline-item px-1'>
                                        <Link to={`/#`} id={'edit' + 1}>
                                            <i className='bx bx-edit' />
                                            <UncontrolledTooltip placement='top' target={'edit' + 1}>
                                                Edit
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                    <li className='list-inline-item px-1'>
                                        <Link to='#' id={'delete' + 1}>
                                            <i className='bx bxs-trash' />
                                            <UncontrolledTooltip placement='top' target={'delete' + 1}>
                                                Delete
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                    <li className='list-inline-item px-1'>
                                        <Link to='#' id={'publish' + 1}>
                                            <i className={`bx bx-check-circle`} />
                                            <UncontrolledTooltip placement='top' target={'publish' + 1}>
                                                Publish
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </Tbody>
                </Table>
            </div>
        </React.Fragment>
    );
};

export default NewsTable;
