import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, CardBody, Collapse } from 'reactstrap';
import { notification } from '../../../../components/Utils/Notification';
import { Fldfaq, useDeleteFldfaqMutation } from '../../../../generated/graphql';

interface FAQAccordionProps {
    FAQList: Fldfaq[];
}

const FAQAccordion: React.FC<FAQAccordionProps> = ({ FAQList }) => {
    const [FLDFAQList, setFLDFAQList] = useState<any>(undefined);
    const [deleteFLDFAQ] = useDeleteFldfaqMutation();

    useEffect(() => {
        if (FAQList) {
            let newFAQ: any = [];
            FAQList.forEach((faq, idx) => {
                newFAQ.push({
                    id: faq.id,
                    category: faq.category,
                    question: faq.question,
                    answer: faq.answer,
                    isOpen: idx === 0 ? true : false,
                });
            });
            setFLDFAQList(newFAQ);
        }
    }, [FAQList]);

    const setAccordionOpen = (id: number) => {
        let newFAQ: any = [];
        FLDFAQList.forEach((faq: any) => {
            if (faq.id === id) {
                faq.isOpen = true;
            } else {
                faq.isOpen = false;
            }
            newFAQ.push(faq);
        });
        setFLDFAQList(newFAQ);
    };

    const deleteFAQById = async (id: number) => {
        try {
            const response = await deleteFLDFAQ({
                variables: { id },
                update: (cache: any) => {
                    cache.evict({ id: 'FLDFAQ:' + id });
                },
            });

            if (response.data?.deleteFLDFAQ) {
                notification('success', 'Successful Delete!');
            }
        } catch (error) {
            notification('error', error.message);
        }
    };

    return (
        <React.Fragment>
            <div>
                <div id='gen-ques-accordion' className='accordion custom-accordion'>
                    {FLDFAQList &&
                        FLDFAQList.map((faq: any, idx: number) => (
                            <div className={idx === FAQList.length ? '' : 'mb-3'} key={idx}>
                                <Link
                                    to='#'
                                    className='accordion-list'
                                    onClick={() => setAccordionOpen(faq.id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div>{faq.question}</div>
                                    <i className={faq.isOpen ? 'mdi mdi-minus accor-plus-icon' : 'mdi mdi-plus accor-plus-icon'} />
                                </Link>

                                <Collapse isOpen={faq.isOpen}>
                                    <CardBody>
                                        <div className='mb-5'>
                                            <p className='mb-0'>{faq.answer}</p>
                                            <Button color='danger' className='float-end m-3' onClick={() => deleteFAQById(faq.id)}>
                                                Delete
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Collapse>
                            </div>
                        ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default FAQAccordion;
