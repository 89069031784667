import { convertCentsToPrice } from '@footykids/common';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-super-responsive-table';
import { Card, CardBody, Col, Row } from 'reactstrap';
import logo from '../../../../assets/images/footykids.svg';
import { OrderDataFragment } from '../../../../generated/graphql';
dayjs.extend(LocalizedFormat);

interface OrderReceiptProps {
    order: OrderDataFragment;
}

export const OrderReceipt: React.FC<OrderReceiptProps> = ({ order }) => {
    const printReceipt = () => {
        var printContents = (document.getElementById('order-receipt') as any).innerHTML;
        // var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();
        // document.body.innerHTML = originalContents;
    };

    return (
        <React.Fragment>
            <Card id='order-receipt'>
                <CardBody>
                    <div className='invoice-title'>
                        <h4 className='float-end font-size-16'>Order #FLD-{order.orderNo}</h4>
                        <div className='mb-4'>
                            <img src={logo} alt='logo' height='30' />
                            <address className='mt-4'>
                                FootyField Sdn. Bhd
                                <br />
                                admin@footykids.com.my
                                <br />
                                Level 30 Menara Prestige,
                                <br />
                                Jalan Pinang, 50450 Kuala Lumpur
                            </address>
                        </div>
                    </div>
                    <hr />
                    <Row>
                        <Col xs='6'>
                            <address>
                                <strong>Billed To:</strong>
                                <br />
                                <span>{order.billingName}</span> <br />
                                {order.orderBilling!.houseUnit && (
                                    <>
                                        <span>{order.orderBilling!.houseUnit}</span> <br />
                                    </>
                                )}
                                <span>{order.orderBilling?.addressLine1}</span> <br />
                                {order.orderBilling!.addressLine2 && (
                                    <>
                                        <span>{order.orderBilling!.addressLine2}</span> <br />
                                    </>
                                )}
                                <span>{order.orderBilling!.postalCode}</span> <br />
                                <span>{order.orderBilling!.city}</span> <br />
                                <span>{order.orderBilling!.state}</span> <br />
                                <span>{order.orderBilling!.country}</span> <br />
                            </address>
                        </Col>
                        <Col xs='6' className='text-end'>
                            <address>
                                <strong>Shipped To:</strong>
                                <br />
                                <span>{order.billingName}</span> <br />
                                {order.orderBilling?.shipToDifferentAddress ? (
                                    <>
                                        {order.orderBilling!.shippingHouseUnit && (
                                            <>
                                                <span>{order.orderBilling!.shippingHouseUnit}</span> <br />
                                            </>
                                        )}
                                        <span>{order.orderBilling?.shippingAddressLine1}</span> <br />
                                        {order.orderBilling!.shippingAddressLine2 && (
                                            <>
                                                <span>{order.orderBilling!.shippingAddressLine2}</span> <br />
                                            </>
                                        )}
                                        <span>{order.orderBilling!.shippingPostalCode}</span> <br />
                                        <span>{order.orderBilling!.shippingCity}</span> <br />
                                        <span>{order.orderBilling!.shippingState}</span> <br />
                                        <span>{order.orderBilling!.shippingCountry}</span> <br />
                                    </>
                                ) : (
                                    <>
                                        {order.orderBilling!.houseUnit && (
                                            <>
                                                <span>{order.orderBilling!.houseUnit}</span> <br />
                                            </>
                                        )}
                                        <span>{order.orderBilling?.addressLine1}</span> <br />
                                        {order.orderBilling!.addressLine2 && (
                                            <>
                                                <span>{order.orderBilling!.addressLine2}</span> <br />
                                            </>
                                        )}
                                        <span>{order.orderBilling!.postalCode}</span> <br />
                                        <span>{order.orderBilling!.city}</span> <br />
                                        <span>{order.orderBilling!.state}</span> <br />
                                        <span>{order.orderBilling!.country}</span> <br />
                                    </>
                                )}
                            </address>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='6' className='mt-3'>
                            <address>
                                <strong>Payment Method:</strong>
                                <br />
                                {order.paymentMethod.toUpperCase()}
                                <br />
                                {order.orderBilling?.emailAddress}
                            </address>
                        </Col>
                        <Col xs='6' className='mt-3 text-end'>
                            <address>
                                <strong>Order Date:</strong>
                                <br />
                                {dayjs(parseInt(order.createdAt)).format('lll')}
                                <br />
                                <br />
                            </address>
                        </Col>
                    </Row>
                    <div className='py-2 mt-3'>
                        <h3 className='font-size-15 fw-bold'>Order summary</h3>
                    </div>
                    <div className='table-responsive'>
                        <Table className='table table-nowrap w-100'>
                            <thead>
                                <tr>
                                    <th style={{ width: '70px' }}>No.</th>
                                    <th>Item</th>
                                    <th className='text-end'>Quantity</th>
                                    <th className='text-end'>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {order.orderProduct!.map((product, idx) => (
                                    <tr key={idx}>
                                        <td>{idx + 1}</td>
                                        <td>
                                            <h6 className='text-truncate font-size-14 mb-0'>{product.name}</h6>
                                            <p className='text-muted mb-0'>
                                                {product.orderProductVariation.map((variation, idx, arr) => {
                                                    return `${variation.name}: ${variation.value}${idx === arr.length - 1 ? '' : ', '}`;
                                                })}
                                            </p>
                                        </td>
                                        <td className='text-end'>{product.quantity}</td>
                                        <td className='text-end'>
                                            RM{' '}
                                            {product.isDiscount
                                                ? convertCentsToPrice(product.unitPrice - product.discount)
                                                : convertCentsToPrice(product.unitPrice)}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={3} className='text-end'>
                                        Sub Total
                                    </td>
                                    <td className='text-end'>
                                        RM{' '}
                                        {convertCentsToPrice(
                                            order.orderProduct!.reduce((acc, product) => product.unitPrice * product.quantity + acc, 0)
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3} className='border-0 text-end'>
                                        <strong>Shipping</strong>
                                    </td>
                                    <td className='border-0 text-end'>RM {convertCentsToPrice(order.shippingPrice)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3} className='border-0 text-end'>
                                        <strong>Discount</strong>
                                    </td>
                                    <td className='border-0 text-end'>RM {convertCentsToPrice(order.totalDiscount)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3} className='border-0 text-end'>
                                        <strong>Total</strong>
                                    </td>
                                    <td className='border-0 text-end'>
                                        <h5 className='m-0'>RM {convertCentsToPrice(order.totalPrice)}</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className='d-print-none mt-4'>
                        <div className='float-end'>
                            <Link to='#' onClick={printReceipt} className='btn btn-primary waves-effect waves-light me-2'>
                                <i className='fa fa-print' /> Print
                            </Link>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default OrderReceipt;
