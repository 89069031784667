import React from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/footykids.svg';

interface AuthCardWrapperProps {
    t: any;
}

const AuthCardWrapper: React.FC<AuthCardWrapperProps & WithTranslationProps> = ({ children, t }) => {
    return (
        <div className='auth-full-page-content p-md-5 p-4'>
            <div className='w-100'>
                <div className='d-flex flex-column h-100'>
                    <div className='my-auto'>
                        <div className='mb-4 mb-md-5'>
                            <Link to='dashboard' className='d-block auth-logo'>
                                <img src={logo} alt='Footykids' className='auth-logo-dark mx-auto d-block' height="50" />
                            </Link>
                        </div>
                        <div className='mt-5 mt-md-5'>
                            <h5 className='text-primary'>{t('WelcomeText')}</h5>
                            <p className='text-muted'>{t('SignInText')}</p>
                        </div>

                        <div className='mt-4 mb-5'>{children}</div>
                    </div>

                    <div className='mt-auto text-center'>
                        <p className='mb-0'>© {new Date().getFullYear()} Footykids.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(AuthCardWrapper);
