import { AddProductCategorySchema, ProductCategoryInitialValues } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';
import { InputField } from '../../../components/Common/InputField';
import { ModalPopup } from '../../../components/Common/ModalPopup';
import PageWrapper from '../../../components/Common/PageWrapper';
import { notification } from '../../../components/Utils/Notification';
import { useCreateProductCategoryMutation } from '../../../generated/graphql';
import { toErrorMap } from '../../../util/toErrorMap';
import { ProductCategoryTable } from './Components/ProductCategoryTable';

const ProductCategory: React.FC<{}> = () => {
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>('No file chosen');
    const [imageToUpload, setImageToUpload] = useState<any>(undefined);

    const [createProductCategory] = useCreateProductCategoryMutation();

    const handleClosed = () => {
        setImageName('No file chosen');
        setImageToUpload(undefined);
        setIsCategoryModalOpen(false);
    };

    const imageupload = (e: any) => {
        if (e.target.files) {
            setImageName(e.target.files[0].name);
            setImageToUpload(e.target.files[0]);
        }
    };

    return (
        <PageWrapper pageTitle='Product Category' pageCategory='FLD Store' breadCrumbTitle='Products' breadCrumbItem='Category'>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>Product Category</CardTitle>
                            <CardSubtitle className='mb-2'>List for a possible product category.</CardSubtitle>
                            <ProductCategoryTable />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ModalPopup isOpen={isCategoryModalOpen} handleClosed={handleClosed} size='md' title='Add New Category'>
                <Formik
                    enableReinitialize
                    initialValues={ProductCategoryInitialValues}
                    validationSchema={AddProductCategorySchema}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            if (imageToUpload === undefined) {
                                return notification('error', 'Category image cannot be empty');
                            }
                            const response = await createProductCategory({
                                variables: {
                                    input: {
                                        name: values.name,
                                        image: imageToUpload,
                                    },
                                },
                                update: (cache) => {
                                    cache.evict({ fieldName: 'productCategories' });
                                },
                            });

                            if (response?.data?.createProductCategory.errors) {
                                const errors = response.data.createProductCategory.errors;
                                setErrors(toErrorMap(errors));
                                errors.forEach((err) => {
                                    notification('error', err.message);
                                });
                            } else if (response.data?.createProductCategory.productCategory) {
                                notification('success', 'Product Category created!');
                                handleClosed();
                            }
                        } catch (error) {
                            notification('error', error.message);
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <InputField name='name' label='Category Name' placeholder='Category Name' type='text' />
                            <InputField
                                name='image'
                                label='Category Image'
                                placeholder='Category Image'
                                type='file'
                                fileInput
                                fileInputLabel={imageName}
                                accept='image/*'
                                onChange={imageupload}
                                required
                            />
                            <div className='modal-footer p-0'>
                                <Button type='submit' color='primary' disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                                    ) : (
                                        'Save Category'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalPopup>
            <div className='text-sm-right save-changes-button'>
                <Button
                    type='button'
                    color='primary'
                    className='btn-rounded me-1 waves-effect waves-light'
                    onClick={() => setIsCategoryModalOpen(true)}
                >
                    <i className='mdi mdi-plus me-1' />
                    Add New Category
                </Button>
            </div>
        </PageWrapper>
    );
};

export default withRouter(ProductCategory);
