import React, { InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import { FormGroup, Label } from 'reactstrap';

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    placeholder: string;
    name: string;
    type?: string;
    textarea?: boolean;
    rows?: number;
    disabled?: boolean;
    switches?: boolean;
    switchLabel?: string;
    maxLength?: number;
    required?: boolean;
    displayNone?: boolean;
    fileInput?: boolean;
    fileInputLabel?: string;
};

export const InputField: React.FC<InputFieldProps> = ({
    textarea,
    switches,
    switchLabel,
    maxLength,
    required,
    displayNone,
    fileInput,
    fileInputLabel,
    ...props
}) => {
    const [field, { error, touched }] = useField(props);
    return (
        <FormGroup className={`${displayNone ? 'd-none' : ''} mb-3`}>
            <Label htmlFor={props.name} className='form-label'>
                {props.label} {required ? <span className='span-error'>*</span> : null}
            </Label>
            {textarea ? (
                <textarea
                    id={field.name}
                    {...field}
                    rows={props.rows}
                    className={`form-control ${error && touched ? 'input-error' : null}`}
                    maxLength={maxLength}
                ></textarea>
            ) : switches ? (
                <div className='form-check form-switch form-switch-md mb-3' dir='ltr' style={{ zIndex: 0 }}>
                    <input {...field} {...props} id={props.name} className='form-check-input' />
                    <label className='form-check-label' htmlFor={props.name}>
                        {switchLabel}
                    </label>
                </div>
            ) : fileInput ? (
                <div className='mb-3' style={{ zIndex: 0 }}>
                    <input id='customFile' {...field} {...props} className={`form-control ${error && touched ? 'input-error' : null}`} />
                </div>
            ) : (
                <input
                    {...field}
                    {...props}
                    id={props.name}
                    className={`form-control ${error && touched ? 'input-error' : null}`}
                    maxLength={maxLength ? maxLength : undefined}
                />
            )}
            <div>{error && touched && <span className='span-error'>{error}</span>}</div>
        </FormGroup>
    );
};
