import React, { useEffect } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"

interface NonAuthLayoutProps {
  children: any;
  location: any;
}

const NonAuthLayout: React.FC<NonAuthLayoutProps & RouteComponentProps> = ({ children, location }) => {
  const capitalizeFirstLetter = (letter: string) => {
    return letter.charAt(1).toUpperCase() + letter.slice(2)
  }

  useEffect(() => {
    const currentage = capitalizeFirstLetter(location.pathname);

    document.title = currentage + " | Footykids";
  }, [location.pathname])

    return (
      <React.Fragment>{children}</React.Fragment>
    );
}

export default withRouter(NonAuthLayout)
