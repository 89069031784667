import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import PageWrapper from '../../components/Common/PageWrapper';
import AddUserModal from './Components/AddUserModal';
import UserTable from './Components/UserTable';

interface UserProps {}

const User: React.FC<UserProps> = () => {
    const [addUserModal, setAddUserModal] = useState<boolean>(false);

    const handleClosed = () => {
        setAddUserModal(false);
    };

    return (
        <PageWrapper pageTitle='User' pageCategory='Footykids' breadCrumbTitle='Footykids' breadCrumbItem='User'>
            <Row>
                <Col lg='12'>
                    <Card>
                        <CardBody>
                            <Row>
                                <UserTable />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <AddUserModal isOpen={addUserModal} handleClosed={handleClosed} />
            <div className='text-sm-right save-changes-button'>
                <Button
                    type='button'
                    color='primary'
                    className='btn-rounded me-1 waves-effect waves-light'
                    onClick={() => setAddUserModal(true)}
                >
                    <i className='mdi mdi-plus me-1' />
                    Add New Support Admin
                </Button>
            </div>
        </PageWrapper>
    );
};

export default withRouter(User);
