import { convertCentsToPrice, OrderStatus, PaymentStatus } from '@footykids/common';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { Badge, Button } from 'reactstrap';
import fpxImg from '../../../../assets/images/payment/fpx.svg';
import { OrdersQuery } from '../../../../generated/graphql';
dayjs.extend(LocalizedFormat);

interface OrderListTableProps {
    orderList: OrdersQuery;
    orderListLoading: boolean;
    toggleDetailsModal: any;
    setModalOrderId: any;
}

const OrderListTable: React.FC<OrderListTableProps> = ({ orderList, orderListLoading, toggleDetailsModal, setModalOrderId }) => {
    return (
        <React.Fragment>
            <div className='table-responsive mb-0'>
                <Table id='tech-companies-1' className='table'>
                    <Thead className='table-light'>
                        <Tr>
                            <Th width='10%'>Order ID</Th>
                            <Th width='8%'>Order Status</Th>
                            <Th width='25%'>Billing Name</Th>
                            <Th width='10%'>Date</Th>
                            <Th width='10%'>Total</Th>
                            <Th width='8%'>Payment Status</Th>
                            <Th width='9%'>Payment Method</Th>
                            <Th width='10%' className='text-center'>
                                Order Summary
                            </Th>
                            <Th width='10%' className='text-center'>
                                Full Details
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {orderListLoading ? (
                            <tr>
                                <td colSpan={9} className='text-center'>
                                    <Loader type='ThreeDots' color='primary' height={35} width={35} timeout={0} />
                                </td>
                            </tr>
                        ) : orderList && orderList.orders.orders && orderList.orders.orders.length > 0 ? (
                            orderList.orders.orders.map((order, idx) => (
                                <tr key={idx}>
                                    <td className='text-body fw-bold'>#FLD-{order.orderNo}</td>
                                    <td>
                                        <Badge
                                            className={`font-size-12 badge-soft-${
                                                order.orderStatus === OrderStatus.FAILED ||
                                                order.orderStatus === OrderStatus.SHIPPING_FAILED ||
                                                order.orderStatus === OrderStatus.CANCELED
                                                    ? 'danger'
                                                    : order.orderStatus === OrderStatus.PENDING
                                                    ? 'warning'
                                                    : order.orderStatus === OrderStatus.SHIPPED
                                                    ? 'info'
                                                    : 'success'
                                            }`}
                                        >
                                            {order.orderStatus}
                                        </Badge>
                                    </td>
                                    <td>{order.billingName}</td>
                                    <td>{dayjs(parseInt(order.createdAt)).format('lll')}</td>
                                    <td>RM {convertCentsToPrice(order.totalPrice)}</td>
                                    <td>
                                        <Badge
                                            className={`font-size-12 badge-soft-${
                                                order.paymentTxnStatus === PaymentStatus.PAID
                                                    ? 'success'
                                                    : order.paymentTxnStatus === PaymentStatus.PENDINGPAYMENT
                                                    ? 'warning'
                                                    : 'danger'
                                            }`}
                                        >
                                            {order.paymentTxnStatus}
                                        </Badge>
                                    </td>
                                    <td>
                                        {order.paymentMethod.toLowerCase() === 'fpx' && (
                                            <img src={fpxImg} alt='FPX' height='15' className='me-1' />
                                        )}
                                        {order.paymentMethod.toLowerCase() === 'creditcard' && (
                                            <>
                                                <i className={'fas fa-credit-card me-1'} />
                                                Credit Card
                                            </>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {order.paymentTxnStatus === PaymentStatus.PAID && (
                                            <Button
                                                type='button'
                                                color='secondary'
                                                className='btn-sm'
                                                onClick={() => {
                                                    setModalOrderId(order.id);
                                                    toggleDetailsModal();
                                                }}
                                            >
                                                View Details
                                            </Button>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        <Link to={`/order/${order.id}`}>
                                            <Button type='button' color='primary' className='btn-sm'>
                                                Full Details
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={9} className='text-center'>
                                    <p>No order at the moment.</p>
                                </td>
                            </tr>
                        )}
                    </Tbody>
                </Table>
            </div>
        </React.Fragment>
    );
};

export default OrderListTable;
