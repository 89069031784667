import { convertCentsToPrice, NotificationType, PaymentStatus } from '@footykids/common';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardTitle } from 'reactstrap';
import fpxImg from '../../assets/images/payment/fpx.svg';
import { LatestTransactionData } from '../../generated/graphql';
dayjs.extend(LocalizedFormat);

interface LatestTransactionProps {
    transactions: LatestTransactionData[];
}

const LatestTransaction: React.FC<LatestTransactionProps> = ({ transactions }) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className='mb-4'>Latest Transaction</CardTitle>
                    <div className='table-responsive'>
                        <table className='table table-centered table-nowrap mb-0'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Category</th>
                                    <th>Billing Name</th>
                                    <th>Date</th>
                                    <th>Total</th>
                                    <th>Payment Status</th>
                                    <th>Payment Method</th>
                                    <th>View Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map((transaction, key) => (
                                    <tr key={'_tr_' + key}>
                                        <td>
                                            <Badge className='me-2 bg-primary font-size-11'>{transaction.category}</Badge>
                                        </td>
                                        <td>{transaction.paymentName}</td>
                                        <td>{dayjs(transaction.date).format('lll')}</td>
                                        <td>RM {convertCentsToPrice(transaction.totalPayment)}</td>
                                        <td>
                                            <Badge
                                                className={`font-size-12 badge-soft-${
                                                    transaction.paymentStatus === PaymentStatus.PAID
                                                        ? 'success'
                                                        : transaction.paymentStatus === PaymentStatus.PENDINGPAYMENT
                                                        ? 'warning'
                                                        : 'danger'
                                                }`}
                                            >
                                                {transaction.paymentStatus}
                                            </Badge>
                                        </td>
                                        <td>
                                            {transaction.paymentMethod.toLowerCase() === 'fpx' && (
                                                <img src={fpxImg} alt='FPX' height='15' className='me-1' />
                                            )}
                                            {transaction.paymentMethod.toLowerCase() === 'creditcard' && (
                                                <>
                                                    <i className={'fas fa-credit-card me-1'} />
                                                    Credit Card
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            {transaction.category === NotificationType.FLD && (
                                                <Link to={`order/${transaction.id}`}>
                                                    <Button
                                                        type='button'
                                                        color='primary'
                                                        size='sm'
                                                        className='btn-rounded waves-effect waves-light'
                                                    >
                                                        View Details
                                                    </Button>
                                                </Link>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default LatestTransaction;
