import { ApolloProvider } from '@apollo/client/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n';
import store from './store/store';
import client from './util/ApolloClient';
import interceptor from './util/services/Axios.interceptor';

// Axios Interceptor
interceptor(store);

const app = (
    <ApolloProvider client={client}>
        <ReduxProvider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ReduxProvider>
    </ApolloProvider>
);

ReactDOM.render(app, document.getElementById('root'));
