import classnames from 'classnames';
import React, { useState } from 'react';
import { Card, CardBody, Col, Nav, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Fldfaq } from '../../../../generated/graphql';
import FAQAccordion from './FAQAccordion';

interface FAQComponentProps {
    FAQList: Fldfaq[];
}

const FAQComponent: React.FC<FAQComponentProps> = ({ FAQList }) => {
    const [activeTab, setactiveTab] = useState('0');

    return (
        <Col lg='12'>
            <div className='vertical-nav'>
                <Row>
                    <Col lg='2' sm='4'>
                        <Nav pills className='flex-column'>
                            {Object.entries(FAQList).map(([key, value], idx) => (
                                <NavLink
                                    key={idx}
                                    className={classnames({ active: activeTab === idx.toString() })}
                                    onClick={() => {
                                        setactiveTab(idx.toString());
                                    }}
                                >
                                    <i className='bx bx-help-circle nav-icon d-block mb-2' />
                                    <p className='font-weight-bold mb-0'>{key}</p>
                                </NavLink>
                            ))}
                        </Nav>
                    </Col>
                    <Col lg='10' sm='8'>
                        <Card>
                            <CardBody>
                                <TabContent activeTab={activeTab}>
                                    {Object.entries(FAQList).map(([key, value], idx) => (
                                        <TabPane key={idx} tabId={idx.toString()} id={key}>
                                            <h4 className='card-title mb-4'>{key}</h4>
                                            <FAQAccordion FAQList={value as any} />
                                        </TabPane>
                                    ))}
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export default FAQComponent;
