import { changeSidebarType as changeSidebarTypeAction, changeTopbarTheme as changeTopbarThemeAction } from './actions';

function changeBodyAttribute(attribute, value) {
    if (document.body) document.body.setAttribute(attribute, value);
    return true;
}

function manageBodyClass(cssClass, action = 'toggle') {
    switch (action) {
        case 'add':
            if (document.body) document.body.classList.add(cssClass);
            break;
        case 'remove':
            if (document.body) document.body.classList.remove(cssClass);
            break;
        default:
            if (document.body) document.body.classList.toggle(cssClass);
            break;
    }

    return true;
}

function changeLayout(layout) {
    try {
        if (layout === 'horizontal') {
            changeTopbarThemeAction('dark');
            document.body.removeAttribute('data-sidebar');
            document.body.removeAttribute('data-sidebar-size');
        } else {
            changeTopbarThemeAction('light');
        }
        changeBodyAttribute('data-layout', layout);
    } catch (error) {}
}

function changeLayoutWidth(width) {
    try {
        if (width === 'boxed') {
            changeSidebarTypeAction('icon');
            changeBodyAttribute('data-layout-size', width);
            changeBodyAttribute('data-layout-scrollable', false);
        } else if (width === 'scrollable') {
            changeSidebarTypeAction('default');
            changeBodyAttribute('data-layout-scrollable', true);
        } else {
            changeSidebarTypeAction('default');
            changeBodyAttribute('data-layout-size', width);
            changeBodyAttribute('data-layout-scrollable', false);
        }
    } catch (error) {}
}

function changeLeftSidebarTheme(theme) {
    try {
        changeBodyAttribute('data-sidebar', theme);
    } catch (error) {}
}

function changeTopbarTheme(theme) {
    try {
        changeBodyAttribute('data-topbar', theme);
    } catch (error) {}
}

function changeLeftSidebarType(payload) {
    const sidebarType = payload.sidebarType;
    const isMobile = payload.isMobile;
    try {
        switch (sidebarType) {
            case "compact":
              changeBodyAttribute("data-sidebar-size", "small")
              manageBodyClass("sidebar-enable", "remove")
              manageBodyClass("vertical-collpsed", "remove")
              break
            case "icon":
              changeBodyAttribute("data-keep-enlarged", "true")
              manageBodyClass("vertical-collpsed", "add")
              break
            case "condensed":
              // manageBodyClass("sidebar-enable", "add")
              // if (!isMobile) manageBodyClass("vertical-collpsed", "add")
              manageBodyClass("sidebar-enable", "add")
              if (window.screen.width >= 992) {
                manageBodyClass("vertical-collpsed", "remove")
                manageBodyClass("sidebar-enable", "remove")
                manageBodyClass("vertical-collpsed", "add")
                manageBodyClass("sidebar-enable", "add")
              } else {
                manageBodyClass("sidebar-enable", "add")
                manageBodyClass("vertical-collpsed", "add")
              }
              break
            default:
              changeBodyAttribute("data-sidebar-size", "")
              manageBodyClass("sidebar-enable", "remove")
              if (!isMobile)
                manageBodyClass("vertical-collpsed", "remove")
              break
          }
    } catch (error) {}
}

function toggleRightSidebar() {
    try {
        manageBodyClass('right-bar-enabled', "add");
    } catch (error) {}
}

function showRightSidebar() {
    try {
        manageBodyClass('right-bar-enabled', 'add');
    } catch (error) {}
}

function hideRightSidebar() {
    try {
        manageBodyClass('right-bar-enabled', 'remove');
    } catch (error) {}
}

export {
    changeLayout,
    changeLayoutWidth,
    changeLeftSidebarTheme,
    changeTopbarTheme,
    changeLeftSidebarType,
    toggleRightSidebar,
    showRightSidebar,
    hideRightSidebar,
};
