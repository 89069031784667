import React from 'react';
import { Modal } from 'reactstrap';

interface ModalPopupProps {
    isOpen: boolean;
    handleClosed: any;
    size: string;
    notCentered?: boolean;
    noHeader?: boolean;
    noBody?: boolean;
    title?: string;
}

export const ModalPopup: React.FC<ModalPopupProps> = ({ isOpen, handleClosed, size, children, ...props }) => {
    return (
        <Modal isOpen={isOpen} centered={props.notCentered ? false : true} size={size}>
            {props.noHeader ? null : (
                <div className='modal-header'>
                    <h5 className='modal-title mt-0'>{props.title}</h5>
                    <button type='button' onClick={handleClosed} className='close' data-dismiss='modal' aria-label='Close'>
                        <span aria-hidden='true'>&times;</span>
                    </button>
                </div>
            )}
            {props.noBody ? { children } : <div className='modal-body'>{children}</div>}
        </Modal>
    );
};
