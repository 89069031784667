import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcademyPayment = {
  __typename?: 'AcademyPayment';
  id: Scalars['Float'];
  paymentNo: Scalars['Float'];
  billingName: Scalars['String'];
  totalPrice: Scalars['Float'];
  paymentMethod: Scalars['String'];
  paymentTxnChannel: Scalars['String'];
  paymentTxnRef?: Maybe<Scalars['String']>;
  paymentTxnBillCode?: Maybe<Scalars['String']>;
  paymentTxnStatus?: Maybe<Scalars['String']>;
  paymentTxnReason?: Maybe<Scalars['String']>;
  paymentTxnPriceReceived?: Maybe<Scalars['Float']>;
  paymentTxnDate?: Maybe<Scalars['String']>;
  receiptPath?: Maybe<Scalars['String']>;
  academyPlayerId: Scalars['Float'];
};

export type AcademyPlayer = {
  __typename?: 'AcademyPlayer';
  id: Scalars['Float'];
  dob: Scalars['String'];
  identificationNumber: Scalars['String'];
  preferedPosition: Scalars['String'];
  dominantFoot: Scalars['String'];
  medicalCondition: Scalars['String'];
  medication: Scalars['String'];
  parentName: Scalars['String'];
  parentPhoneNumber: Scalars['String'];
  parentEmailAddress: Scalars['String'];
  isActive: Scalars['Boolean'];
  certificatePath?: Maybe<Scalars['String']>;
  profileImagePath?: Maybe<Scalars['String']>;
  academyVenueId: Scalars['Float'];
  academyVenue: AcademyVenue;
};

export type AcademyVenue = {
  __typename?: 'AcademyVenue';
  id: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  ageLimit: Scalars['Float'];
  trainingDay: Scalars['String'];
  fee: Scalars['Float'];
  registrationFee: Scalars['Float'];
  isTrialAvailable: Scalars['Boolean'];
  maxTrial: Scalars['Float'];
  isActive: Scalars['Boolean'];
  location: Scalars['String'];
  locationState: Scalars['String'];
  isPublished: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type AcademyVenueSession = {
  __typename?: 'AcademyVenueSession';
  id: Scalars['Float'];
  sessionDate: Scalars['String'];
  academyVenueId: Scalars['Float'];
  createdAt: Scalars['String'];
};

export type AcademyVenueSessionPlayer = {
  __typename?: 'AcademyVenueSessionPlayer';
  id: Scalars['Float'];
  isAttend: Scalars['String'];
  skipReason: Scalars['String'];
  academyVenueSessionId: Scalars['Float'];
  academyPlayerId: Scalars['Float'];
  createdAt: Scalars['String'];
};

export type BillingDetails = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullNameOrCompanyName: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  houseUnit: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
  phoneNumber: Scalars['String'];
  emailAddress: Scalars['String'];
  shipToDifferentAddress: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  paymentMethod: Scalars['String'];
  shippingAddressLine1?: Maybe<Scalars['String']>;
  shippingAddressLine2?: Maybe<Scalars['String']>;
  shippingHouseUnit?: Maybe<Scalars['String']>;
  shippingCity?: Maybe<Scalars['String']>;
  shippingPostalCode?: Maybe<Scalars['String']>;
  shippingState?: Maybe<Scalars['String']>;
  shippingCountry?: Maybe<Scalars['String']>;
};

export type CartProduct = {
  id: Scalars['Float'];
  options: Array<Scalars['JSONObject']>;
  quantity: Scalars['Int'];
  availableQuantity?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type CartProductOption = {
  id: Scalars['Int'];
  variationId: Scalars['Int'];
  value: Scalars['String'];
  unitsInStock?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type DashboardResponse = {
  __typename?: 'DashboardResponse';
  FLDData: FldData;
  FKLData: FklData;
  FKAData: FkaData;
  totalTransactions: TotalTransactionData;
  notifications: Array<Notification>;
  salesAnalytics: SalesAnalyticData;
  latestTransactions: Array<LatestTransactionData>;
};


export type EarningsData = {
  __typename?: 'EarningsData';
  currentEarnings: Scalars['Float'];
  currentMonthEarnings: Scalars['Float'];
  earningPerDay: Array<Scalars['Float']>;
};

export type EmailPasswordInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type FkaData = {
  __typename?: 'FKAData';
  payments: PaymentsData;
  totalStudents: Scalars['Float'];
};

export type FklData = {
  __typename?: 'FKLData';
  payments: PaymentsData;
  totalTeams: Scalars['Float'];
};

export type FldData = {
  __typename?: 'FLDData';
  orders: OrdersData;
  payments: PaymentsData;
  averagePricePerOrder: Scalars['Float'];
  earnings: EarningsData;
};

export type Fldfaq = {
  __typename?: 'FLDFAQ';
  id: Scalars['Float'];
  category: Scalars['String'];
  question: Scalars['String'];
  answer: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type FldfaqInput = {
  category: Scalars['String'];
  question: Scalars['String'];
  answer: Scalars['String'];
};

export type FldfaqResponse = {
  __typename?: 'FLDFAQResponse';
  errors?: Maybe<Array<FieldError>>;
  FLDFAQCreated?: Maybe<Scalars['Boolean']>;
};

export type FldSettings = {
  __typename?: 'FLDSettings';
  id: Scalars['Float'];
  key: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type FldSettingsResponse = {
  __typename?: 'FLDSettingsResponse';
  errors?: Maybe<Array<FieldError>>;
  isMaintenance?: Maybe<FldSettings>;
  shipCityAndState?: Maybe<FldSettings>;
  privacyPolicy?: Maybe<FldSettings>;
};

export type FpxBanks = {
  __typename?: 'FPXBanks';
  id: Scalars['Float'];
  bank_code: Scalars['String'];
  bank_name: Scalars['String'];
  active: Scalars['Boolean'];
  image: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type HomepageBanner = {
  __typename?: 'HomepageBanner';
  id: Scalars['Float'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  imageName: Scalars['String'];
  imagePath?: Maybe<Scalars['String']>;
  imageMobilePath?: Maybe<Scalars['String']>;
  hasProductButton: Scalars['Boolean'];
  productId?: Maybe<Scalars['Float']>;
  isPublished: Scalars['Boolean'];
  publishedAt: Scalars['String'];
  createdAt: Scalars['String'];
  product?: Maybe<Product>;
};

export type HomepageBannerInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hasProductButton?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['Int']>;
  image: Scalars['Upload'];
  imageMobile: Scalars['Upload'];
};

export type HomepageBannerResponse = {
  __typename?: 'HomepageBannerResponse';
  errors?: Maybe<Array<FieldError>>;
  homepageBannerCreated?: Maybe<Scalars['Boolean']>;
};


export type LatestTransactionData = {
  __typename?: 'LatestTransactionData';
  id: Scalars['Float'];
  category: Scalars['String'];
  paymentName: Scalars['String'];
  date: Scalars['DateTime'];
  totalPayment: Scalars['Float'];
  paymentStatus: Scalars['String'];
  paymentMethod: Scalars['String'];
};

export type League = {
  __typename?: 'League';
  id: Scalars['Float'];
};

export type LeaguePayment = {
  __typename?: 'LeaguePayment';
  id: Scalars['Float'];
  refId: Scalars['String'];
  price: Scalars['Float'];
  details: Scalars['String'];
  paymentMethod: Scalars['String'];
  paymentTxnChannel: Scalars['String'];
  paymentTxnRef?: Maybe<Scalars['String']>;
  paymentTxnBillCode?: Maybe<Scalars['String']>;
  paymentTxnStatus?: Maybe<Scalars['String']>;
  paymentTxnReason?: Maybe<Scalars['String']>;
  paymentTxnPriceReceived?: Maybe<Scalars['Float']>;
  paymentTxnDate?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createFLDFAQ: FldfaqResponse;
  deleteFLDFAQ: Scalars['Boolean'];
  updateShipCodeAndState: Scalars['Boolean'];
  updatePrivacyPolicy: Scalars['Boolean'];
  updateFLDIsMaintenance: Scalars['Boolean'];
  createHomepageBanner: HomepageBannerResponse;
  deleteHomepageBanner: Scalars['Boolean'];
  toggleIsPublishHomepageBanner: Scalars['Boolean'];
  submitOrder: OrderResponse;
  verifyPendingOrderByBillCode: Order;
  confirmOrder: Scalars['Boolean'];
  cancelOrder: Scalars['Boolean'];
  shipOrder: Scalars['Boolean'];
  resendReceipt: Scalars['Boolean'];
  getOrderShippingPrice: Scalars['Float'];
  updateOrderDeliveryAddress: Scalars['Boolean'];
  createProduct: ProductResponse;
  updateProduct: ProductResponse;
  deleteProduct: Scalars['Boolean'];
  toggleIsPublishProduct: Scalars['Boolean'];
  createProductCategory: ProductCategoryResponse;
  deleteProductCategory: Scalars['Boolean'];
  createProductImages: Scalars['Boolean'];
  setDefaultImage: Scalars['Boolean'];
  deleteProductImage: Scalars['Boolean'];
  applyPromoCode: PromoCode;
  createPromoCode: PromoCodeResponse;
  deletePromoCode: Scalars['Boolean'];
  togglePublishPromoCodeBanner: Scalars['Boolean'];
  togglePromoCodeActive: Scalars['Boolean'];
  createSecondaryBanner: SecondaryBannerResponse;
  deleteSecondaryBanner: Scalars['Boolean'];
  toggleIsPublishSecondaryBanner: Scalars['Boolean'];
  markReadNotification: Scalars['Boolean'];
  markAllAsReadNotification: Scalars['Boolean'];
  testPushNotification: Scalars['Boolean'];
  register: UserResponse;
  registerSupportAdmin: UserResponse;
  adminLogin: UserResponse;
  logout: Scalars['Boolean'];
  deleteAdminUser: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
};


export type MutationCreateFldfaqArgs = {
  input: FldfaqInput;
};


export type MutationDeleteFldfaqArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateShipCodeAndStateArgs = {
  state: Scalars['String'];
  postalCode: Scalars['String'];
};


export type MutationUpdatePrivacyPolicyArgs = {
  text: Scalars['String'];
};


export type MutationUpdateFldIsMaintenanceArgs = {
  isMaintenance: Scalars['Boolean'];
};


export type MutationCreateHomepageBannerArgs = {
  input: HomepageBannerInput;
};


export type MutationDeleteHomepageBannerArgs = {
  id: Scalars['Int'];
};


export type MutationToggleIsPublishHomepageBannerArgs = {
  id: Scalars['Int'];
};


export type MutationSubmitOrderArgs = {
  order: OrderInput;
};


export type MutationVerifyPendingOrderByBillCodeArgs = {
  billCode: Scalars['String'];
};


export type MutationConfirmOrderArgs = {
  id: Scalars['Int'];
};


export type MutationCancelOrderArgs = {
  id: Scalars['Int'];
};


export type MutationShipOrderArgs = {
  input: ShipOrderInput;
};


export type MutationResendReceiptArgs = {
  id: Scalars['Int'];
};


export type MutationGetOrderShippingPriceArgs = {
  input: OrderShippingPriceInput;
};


export type MutationUpdateOrderDeliveryAddressArgs = {
  input: OrderDeliveryAddressInput;
};


export type MutationCreateProductArgs = {
  input: ProductInput;
};


export type MutationUpdateProductArgs = {
  input: ProductInput;
  id: Scalars['Int'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['Int'];
};


export type MutationToggleIsPublishProductArgs = {
  id: Scalars['Int'];
};


export type MutationCreateProductCategoryArgs = {
  input: ProductCategoryInput;
};


export type MutationDeleteProductCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationCreateProductImagesArgs = {
  input: ProductImageInput;
};


export type MutationSetDefaultImageArgs = {
  imageId: Scalars['Int'];
  productId: Scalars['Int'];
};


export type MutationDeleteProductImageArgs = {
  imageId: Scalars['Int'];
  productId: Scalars['Int'];
};


export type MutationApplyPromoCodeArgs = {
  token: Scalars['String'];
  code: Scalars['String'];
};


export type MutationCreatePromoCodeArgs = {
  input: PromoCodeInput;
};


export type MutationDeletePromoCodeArgs = {
  id: Scalars['Int'];
};


export type MutationTogglePublishPromoCodeBannerArgs = {
  id: Scalars['Int'];
};


export type MutationTogglePromoCodeActiveArgs = {
  id: Scalars['Int'];
};


export type MutationCreateSecondaryBannerArgs = {
  input: SecondaryBannerInput;
};


export type MutationDeleteSecondaryBannerArgs = {
  id: Scalars['Int'];
};


export type MutationToggleIsPublishSecondaryBannerArgs = {
  id: Scalars['Int'];
};


export type MutationMarkReadNotificationArgs = {
  id: Scalars['Int'];
};


export type MutationTestPushNotificationArgs = {
  word: Scalars['String'];
};


export type MutationRegisterArgs = {
  data: EmailPasswordInput;
};


export type MutationRegisterSupportAdminArgs = {
  data: RegisterSupportAdminInput;
};


export type MutationAdminLoginArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationDeleteAdminUserArgs = {
  id: Scalars['Int'];
};


export type MutationResetPasswordArgs = {
  id: Scalars['Int'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['Float'];
  type: Scalars['String'];
  refId: Scalars['Float'];
  title: Scalars['String'];
  message: Scalars['String'];
  isRead: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type NotificationListInput = {
  pageNumber?: Maybe<Scalars['Float']>;
  sizePerPage?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  notifications: Array<Notification>;
  pageCount: Scalars['Float'];
  totalNotifications: Scalars['Float'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['Float'];
  orderNo: Scalars['Float'];
  uniqueOrderId: Scalars['String'];
  orderStatus: Scalars['String'];
  billingName: Scalars['String'];
  ipAddress: Scalars['String'];
  totalQuantity: Scalars['Float'];
  price: Scalars['Float'];
  shippingPrice: Scalars['Float'];
  totalDiscount: Scalars['Float'];
  totalPrice: Scalars['Float'];
  totalWeight: Scalars['Float'];
  promoCode?: Maybe<Scalars['String']>;
  paymentMethod: Scalars['String'];
  paymentTxnChannel: Scalars['String'];
  paymentTxnRef?: Maybe<Scalars['String']>;
  paymentTxnBillCode?: Maybe<Scalars['String']>;
  paymentTxnStatus?: Maybe<Scalars['String']>;
  paymentTxnReason?: Maybe<Scalars['String']>;
  paymentTxnPriceReceived?: Maybe<Scalars['Float']>;
  paymentTxnDate?: Maybe<Scalars['String']>;
  receiptPath?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  courierProvider?: Maybe<Scalars['String']>;
  courierInfo?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  orderBilling?: Maybe<OrderBilling>;
  orderProduct?: Maybe<Array<OrderProduct>>;
};

export type OrderBilling = {
  __typename?: 'OrderBilling';
  id: Scalars['Float'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullNameOrCompanyName: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  houseUnit: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
  phoneNumber: Scalars['String'];
  emailAddress: Scalars['String'];
  notes: Scalars['String'];
  shipToDifferentAddress: Scalars['Boolean'];
  shippingAddressLine1?: Maybe<Scalars['String']>;
  shippingAddressLine2?: Maybe<Scalars['String']>;
  shippingHouseUnit?: Maybe<Scalars['String']>;
  shippingCity?: Maybe<Scalars['String']>;
  shippingPostalCode?: Maybe<Scalars['String']>;
  shippingState?: Maybe<Scalars['String']>;
  shippingCountry?: Maybe<Scalars['String']>;
  orderId: Scalars['Float'];
  createdAt: Scalars['String'];
};

export type OrderDeliveryAddressInput = {
  orderId: Scalars['Float'];
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  houseUnit: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
};

export type OrderInput = {
  token: Scalars['String'];
  products: Array<CartProduct>;
  promoCode?: Maybe<Scalars['String']>;
  paymentBankCode?: Maybe<Scalars['String']>;
  billing: BillingDetails;
};

export type OrderListInput = {
  pageNumber?: Maybe<Scalars['Float']>;
  sizePerPage?: Maybe<Scalars['Float']>;
  searchKeyword?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  dateFilter?: Maybe<Array<Scalars['String']>>;
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  id: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  category: Array<Scalars['String']>;
  brand: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  isDiscount: Scalars['Boolean'];
  discount: Scalars['Float'];
  image: Scalars['String'];
  unitPrice: Scalars['Float'];
  unitWeight: Scalars['Float'];
  quantity: Scalars['Float'];
  orderId: Scalars['Float'];
  orderProductVariation: Array<OrderProductVariation>;
  createdAt: Scalars['String'];
};

export type OrderProductVariation = {
  __typename?: 'OrderProductVariation';
  id: Scalars['Float'];
  name: Scalars['String'];
  value: Scalars['String'];
  orderProductId: Scalars['Float'];
  createdAt: Scalars['String'];
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  errors?: Maybe<Array<FieldError>>;
  orderCreated?: Maybe<Scalars['Boolean']>;
  billUrl?: Maybe<Scalars['String']>;
};

export type OrderShippingPriceInput = {
  products: Array<CartProduct>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type OrderStatusCountResponse = {
  __typename?: 'OrderStatusCountResponse';
  totalOrders?: Maybe<Scalars['Float']>;
  successfulOrders?: Maybe<Scalars['Float']>;
  pendingOrders?: Maybe<Scalars['Float']>;
  failedOrders?: Maybe<Scalars['Float']>;
  confirmedOrders?: Maybe<Scalars['Float']>;
  canceledOrders?: Maybe<Scalars['Float']>;
  shippedOrders?: Maybe<Scalars['Float']>;
};

export type OrdersData = {
  __typename?: 'OrdersData';
  currentTotalOrder: Scalars['Float'];
  currentMonthTotalOrder: Scalars['Float'];
};

export type PaginatedOrdersResponse = {
  __typename?: 'PaginatedOrdersResponse';
  orders: Array<Order>;
  pageCount: Scalars['Float'];
  totalOrders: Scalars['Float'];
};

export type PaginatedProductsResponse = {
  __typename?: 'PaginatedProductsResponse';
  products: Array<Product>;
  hasMore: Scalars['Boolean'];
  totalProducts: Scalars['Float'];
};

export type PaymentsData = {
  __typename?: 'PaymentsData';
  currentTotalPayment: Scalars['Float'];
  currentMonthTotalPayment: Scalars['Float'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  longDescription: Scalars['String'];
  category: Array<Scalars['String']>;
  brand: Scalars['String'];
  defaultPrice?: Maybe<Scalars['Float']>;
  unitsOnOrder: Scalars['Float'];
  isDiscount: Scalars['Boolean'];
  discount: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
  metaTitle: Scalars['String'];
  metaKeywords: Scalars['String'];
  metaDescription: Scalars['String'];
  isPublished: Scalars['Boolean'];
  publishedAt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  variations?: Maybe<Array<ProductVariation>>;
  images?: Maybe<Array<ProductImage>>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['Float'];
  name: Scalars['String'];
  imagePath?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ProductCategoryInput = {
  name: Scalars['String'];
  image?: Maybe<Scalars['Upload']>;
};

export type ProductCategoryResponse = {
  __typename?: 'ProductCategoryResponse';
  errors?: Maybe<Array<FieldError>>;
  productCategory?: Maybe<ProductCategory>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  id: Scalars['Float'];
  name: Scalars['String'];
  generatedName: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  productId: Scalars['Float'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ProductImageInput = {
  productId: Scalars['Int'];
  productName: Scalars['String'];
  type: Scalars['String'];
  images: Array<Scalars['Upload']>;
};

export type ProductInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  longDescription: Scalars['String'];
  category: Array<Scalars['String']>;
  brand: Scalars['String'];
  isDiscount: Scalars['Boolean'];
  discount: Scalars['Float'];
  sku?: Maybe<Scalars['String']>;
  variations: Array<ProductVariationInput>;
  metaTitle: Scalars['String'];
  metaKeywords: Scalars['String'];
  metaDescription: Scalars['String'];
};

export type ProductListInput = {
  limit: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  cursor2?: Maybe<Scalars['Float']>;
  searchKeyword?: Maybe<Scalars['String']>;
  searchCategory?: Maybe<Scalars['String']>;
  sortKeyword?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  refetch?: Maybe<Scalars['Boolean']>;
};

export type ProductResponse = {
  __typename?: 'ProductResponse';
  errors?: Maybe<Array<FieldError>>;
  product?: Maybe<Product>;
};

export type ProductVariation = {
  __typename?: 'ProductVariation';
  id: Scalars['Float'];
  name: Scalars['String'];
  productId: Scalars['Float'];
  variationData: Array<ProductVariationData>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ProductVariationData = {
  __typename?: 'ProductVariationData';
  id: Scalars['Float'];
  value: Scalars['String'];
  unitPrice: Scalars['Float'];
  unitWeight: Scalars['Float'];
  unitsInStock: Scalars['Float'];
  unitsOnOrder: Scalars['Float'];
  image?: Maybe<Scalars['String']>;
  productVariationId: Scalars['Float'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ProductVariationDataInput = {
  id?: Maybe<Scalars['Float']>;
  value: Scalars['String'];
  unitPrice: Scalars['Float'];
  unitWeight: Scalars['Float'];
  unitsInStock: Scalars['Float'];
  image?: Maybe<Scalars['String']>;
};

export type ProductVariationInput = {
  id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  variationData?: Maybe<Array<ProductVariationDataInput>>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  id: Scalars['Float'];
  title: Scalars['String'];
  description: Scalars['String'];
  code: Scalars['String'];
  quantity: Scalars['Float'];
  isPercentage: Scalars['Boolean'];
  discountPercentage: Scalars['Float'];
  discountPrice?: Maybe<Scalars['Float']>;
  isFreeShippingPromo: Scalars['Boolean'];
  isPromoActive: Scalars['Boolean'];
  isPromoBanner: Scalars['Boolean'];
  imagePath?: Maybe<Scalars['String']>;
  isPublished: Scalars['Boolean'];
  publishedAt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type PromoCodeInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  code: Scalars['String'];
  quantity: Scalars['Float'];
  isPercentage: Scalars['Boolean'];
  isFreeShippingPromo?: Maybe<Scalars['Boolean']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  discountPrice?: Maybe<Scalars['Float']>;
  isPromoBanner: Scalars['Boolean'];
  image?: Maybe<Scalars['Upload']>;
};

export type PromoCodeResponse = {
  __typename?: 'PromoCodeResponse';
  errors?: Maybe<Array<FieldError>>;
  promoCodeCreated?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  getFLDFAQList: Array<Fldfaq>;
  getFLDSettings: FldSettingsResponse;
  getFLDPrivacyPolicy: Scalars['String'];
  getFLDIsMaintenance: Scalars['Boolean'];
  homepageBanners: Array<HomepageBanner>;
  publishedHomepageBanners: Array<HomepageBanner>;
  orders: PaginatedOrdersResponse;
  order?: Maybe<Order>;
  orderStatusCount: OrderStatusCountResponse;
  getOrderByBillCode: Order;
  products: Array<Product>;
  publishedProducts: PaginatedProductsResponse;
  publishedProductsByIds?: Maybe<Array<Product>>;
  product?: Maybe<Product>;
  publishedProduct?: Maybe<Product>;
  recentProducts: Array<Product>;
  popularProducts: Array<Product>;
  fldProducts: Array<Product>;
  featuredProducts: Array<Product>;
  productCategories: Array<ProductCategory>;
  promoCodeList: Array<PromoCode>;
  activePromoCodeList: Array<PromoCode>;
  publishedPromoBannerList: Array<PromoCode>;
  secondaryBanners: Array<SecondaryBanner>;
  publishedSecondaryBanners: Array<SecondaryBanner>;
  dashboardData: DashboardResponse;
  notifications: NotificationResponse;
  unreadNotifications: UnreadNotificationResponse;
  getFPXBanks: Array<FpxBanks>;
  me?: Maybe<User>;
  admin?: Maybe<User>;
};


export type QueryOrdersArgs = {
  option: OrderListInput;
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryGetOrderByBillCodeArgs = {
  option: VerifyOrderInput;
};


export type QueryPublishedProductsArgs = {
  option: ProductListInput;
};


export type QueryPublishedProductsByIdsArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QueryPublishedProductArgs = {
  id: Scalars['Int'];
};


export type QueryNotificationsArgs = {
  option: NotificationListInput;
};

export type RegisterSupportAdminInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  profileImage?: Maybe<Scalars['Upload']>;
};

export type SalesAnalyticData = {
  __typename?: 'SalesAnalyticData';
  fkaTotalPayment: Scalars['Float'];
  fklTotalPayment: Scalars['Float'];
  fldTotalPayment: Scalars['Float'];
};

export type SecondaryBanner = {
  __typename?: 'SecondaryBanner';
  id: Scalars['Float'];
  title: Scalars['String'];
  imagePath?: Maybe<Scalars['String']>;
  isPublished: Scalars['Boolean'];
  publishedAt: Scalars['String'];
  createdAt: Scalars['String'];
};

export type SecondaryBannerInput = {
  title: Scalars['String'];
  image: Scalars['Upload'];
};

export type SecondaryBannerResponse = {
  __typename?: 'SecondaryBannerResponse';
  errors?: Maybe<Array<FieldError>>;
  secondaryBannerCreated?: Maybe<Scalars['Boolean']>;
};

export type ShipOrderInput = {
  id: Scalars['Float'];
  courierProvider: Scalars['String'];
  courierInfo?: Maybe<Scalars['String']>;
  trackingNumber: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  orderPaymentCallback?: Maybe<Order>;
  newNotification?: Maybe<Notification>;
  testNotificationSubscriptionsWithFilter: Scalars['JSONObject'];
};


export type SubscriptionOrderPaymentCallbackArgs = {
  billCode: Scalars['String'];
};


export type SubscriptionTestNotificationSubscriptionsWithFilterArgs = {
  notificationId: Scalars['Int'];
};

export type TotalTransactionData = {
  __typename?: 'TotalTransactionData';
  currentTotalPayments: Scalars['Float'];
  currentMonthPayments: Scalars['Float'];
};

export type UnreadNotificationResponse = {
  __typename?: 'UnreadNotificationResponse';
  notifications: Array<Notification>;
  totalUnread: Scalars['Float'];
};


export type User = {
  __typename?: 'User';
  id: Scalars['Float'];
  username: Scalars['String'];
  email: Scalars['String'];
  profileImage?: Maybe<Scalars['String']>;
  isAdmin: Scalars['Boolean'];
  adminType: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
};

export type UsernameEmailInput = {
  username: Scalars['String'];
  email: Scalars['String'];
};

export type VerifyOrderInput = {
  billCode: Scalars['String'];
  sorted: Scalars['String'];
  x_code: Scalars['String'];
  reverify?: Maybe<Scalars['Boolean']>;
};

export type AdminDataFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'email' | 'profileImage' | 'isAdmin' | 'adminType' | 'createdAt'>
);

export type AdminResponseDataFragment = (
  { __typename?: 'UserResponse' }
  & { errors?: Maybe<Array<(
    { __typename?: 'FieldError' }
    & ErrorDataFragment
  )>>, user?: Maybe<(
    { __typename?: 'User' }
    & AdminDataFragment
  )> }
);

export type ErrorDataFragment = (
  { __typename?: 'FieldError' }
  & Pick<FieldError, 'field' | 'message'>
);

export type HomepageBannerDataFragment = (
  { __typename?: 'HomepageBanner' }
  & Pick<HomepageBanner, 'id' | 'title' | 'description' | 'imageName' | 'imagePath' | 'imageMobilePath' | 'hasProductButton' | 'isPublished' | 'createdAt'>
  & { product?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name'>
  )> }
);

export type OrderBillingDataFragment = (
  { __typename?: 'OrderBilling' }
  & Pick<OrderBilling, 'id' | 'firstName' | 'lastName' | 'fullNameOrCompanyName' | 'addressLine1' | 'addressLine2' | 'houseUnit' | 'city' | 'postalCode' | 'state' | 'country' | 'phoneNumber' | 'emailAddress' | 'notes' | 'shipToDifferentAddress' | 'shippingAddressLine1' | 'shippingAddressLine2' | 'shippingHouseUnit' | 'shippingCity' | 'shippingPostalCode' | 'shippingState' | 'shippingCountry'>
);

export type OrderDataFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'orderNo' | 'orderStatus' | 'billingName' | 'totalQuantity' | 'price' | 'shippingPrice' | 'totalDiscount' | 'totalPrice' | 'totalWeight' | 'promoCode' | 'paymentMethod' | 'paymentTxnChannel' | 'paymentTxnRef' | 'paymentTxnBillCode' | 'paymentTxnStatus' | 'paymentTxnReason' | 'paymentTxnPriceReceived' | 'paymentTxnDate' | 'trackingNumber' | 'courierProvider' | 'courierInfo' | 'createdAt'>
  & { orderProduct?: Maybe<Array<(
    { __typename?: 'OrderProduct' }
    & OrderProductDataFragment
  )>>, orderBilling?: Maybe<(
    { __typename?: 'OrderBilling' }
    & OrderBillingDataFragment
  )> }
);

export type OrderListDataFragment = (
  { __typename?: 'PaginatedOrdersResponse' }
  & Pick<PaginatedOrdersResponse, 'totalOrders' | 'pageCount'>
  & { orders: Array<(
    { __typename?: 'Order' }
    & OrderDataFragment
  )> }
);

export type OrderProductDataFragment = (
  { __typename?: 'OrderProduct' }
  & Pick<OrderProduct, 'id' | 'name' | 'description' | 'category' | 'brand' | 'sku' | 'isDiscount' | 'discount' | 'image' | 'unitPrice' | 'unitWeight' | 'quantity'>
  & { orderProductVariation: Array<(
    { __typename?: 'OrderProductVariation' }
    & OrderProductVariationDataFragment
  )> }
);

export type OrderProductVariationDataFragment = (
  { __typename?: 'OrderProductVariation' }
  & Pick<OrderProductVariation, 'id' | 'name' | 'value'>
);

export type ProductCategoryDataFragment = (
  { __typename?: 'ProductCategory' }
  & Pick<ProductCategory, 'id' | 'name' | 'imagePath' | 'createdAt'>
);

export type ProductDataFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'name' | 'description' | 'longDescription' | 'category' | 'brand' | 'defaultPrice' | 'unitsOnOrder' | 'isDiscount' | 'discount' | 'sku' | 'metaTitle' | 'metaKeywords' | 'metaDescription' | 'isPublished'>
  & { variations?: Maybe<Array<(
    { __typename?: 'ProductVariation' }
    & Pick<ProductVariation, 'id' | 'name'>
    & { variationData: Array<(
      { __typename?: 'ProductVariationData' }
      & Pick<ProductVariationData, 'id' | 'value' | 'unitPrice' | 'unitWeight' | 'unitsInStock' | 'image'>
    )> }
  )>>, images?: Maybe<Array<(
    { __typename?: 'ProductImage' }
    & Pick<ProductImage, 'id' | 'name' | 'path' | 'isDefault'>
  )>> }
);

export type PromoCodeDataFragment = (
  { __typename?: 'PromoCode' }
  & Pick<PromoCode, 'id' | 'title' | 'description' | 'code' | 'quantity' | 'isFreeShippingPromo' | 'isPercentage' | 'discountPercentage' | 'discountPrice' | 'isPromoActive' | 'isPromoBanner' | 'imagePath' | 'isPublished' | 'createdAt'>
);

export type SecondaryBannerDataFragment = (
  { __typename?: 'SecondaryBanner' }
  & Pick<SecondaryBanner, 'id' | 'title' | 'imagePath' | 'isPublished' | 'createdAt'>
);

export type NotificationDataFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'type' | 'refId' | 'title' | 'message' | 'isRead' | 'createdAt'>
);

export type NotificationListDataFragment = (
  { __typename?: 'NotificationResponse' }
  & Pick<NotificationResponse, 'totalNotifications' | 'pageCount'>
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & NotificationDataFragment
  )> }
);

export type AdminLoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AdminLoginMutation = (
  { __typename?: 'Mutation' }
  & { adminLogin: (
    { __typename?: 'UserResponse' }
    & AdminResponseDataFragment
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type CancelOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CancelOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelOrder'>
);

export type ConfirmOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ConfirmOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmOrder'>
);

export type CreateFldfaqMutationVariables = Exact<{
  input: FldfaqInput;
}>;


export type CreateFldfaqMutation = (
  { __typename?: 'Mutation' }
  & { createFLDFAQ: (
    { __typename?: 'FLDFAQResponse' }
    & Pick<FldfaqResponse, 'FLDFAQCreated'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & ErrorDataFragment
    )>> }
  ) }
);

export type CreateHomepageBannerMutationVariables = Exact<{
  input: HomepageBannerInput;
}>;


export type CreateHomepageBannerMutation = (
  { __typename?: 'Mutation' }
  & { createHomepageBanner: (
    { __typename?: 'HomepageBannerResponse' }
    & Pick<HomepageBannerResponse, 'homepageBannerCreated'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & ErrorDataFragment
    )>> }
  ) }
);

export type CreateProductMutationVariables = Exact<{
  input: ProductInput;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct: (
    { __typename?: 'ProductResponse' }
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & ProductDataFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & ErrorDataFragment
    )>> }
  ) }
);

export type CreateProductCategoryMutationVariables = Exact<{
  input: ProductCategoryInput;
}>;


export type CreateProductCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createProductCategory: (
    { __typename?: 'ProductCategoryResponse' }
    & { productCategory?: Maybe<(
      { __typename?: 'ProductCategory' }
      & ProductCategoryDataFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & ErrorDataFragment
    )>> }
  ) }
);

export type CreateProductImagesMutationVariables = Exact<{
  input: ProductImageInput;
}>;


export type CreateProductImagesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProductImages'>
);

export type CreatePromoCodeMutationVariables = Exact<{
  input: PromoCodeInput;
}>;


export type CreatePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { createPromoCode: (
    { __typename?: 'PromoCodeResponse' }
    & Pick<PromoCodeResponse, 'promoCodeCreated'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & ErrorDataFragment
    )>> }
  ) }
);

export type CreateSecondaryBannerMutationVariables = Exact<{
  input: SecondaryBannerInput;
}>;


export type CreateSecondaryBannerMutation = (
  { __typename?: 'Mutation' }
  & { createSecondaryBanner: (
    { __typename?: 'SecondaryBannerResponse' }
    & Pick<SecondaryBannerResponse, 'secondaryBannerCreated'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & ErrorDataFragment
    )>> }
  ) }
);

export type DeleteFldfaqMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFldfaqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFLDFAQ'>
);

export type DeleteHomepageBannerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteHomepageBannerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteHomepageBanner'>
);

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProduct'>
);

export type DeleteProductCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProductCategory'>
);

export type DeleteProductImageMutationVariables = Exact<{
  productId: Scalars['Int'];
  imageId: Scalars['Int'];
}>;


export type DeleteProductImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProductImage'>
);

export type DeletePromoCodeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePromoCode'>
);

export type DeleteSecondaryBannerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSecondaryBannerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSecondaryBanner'>
);

export type SetDefaultImageMutationVariables = Exact<{
  productId: Scalars['Int'];
  imageId: Scalars['Int'];
}>;


export type SetDefaultImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDefaultImage'>
);

export type ShipOrderMutationVariables = Exact<{
  input: ShipOrderInput;
}>;


export type ShipOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'shipOrder'>
);

export type TogglePromoCodeActiveMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TogglePromoCodeActiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'togglePromoCodeActive'>
);

export type ToggleIsPublishHomepageBannerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ToggleIsPublishHomepageBannerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleIsPublishHomepageBanner'>
);

export type ToggleIsPublishProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ToggleIsPublishProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleIsPublishProduct'>
);

export type TogglePublishPromoCodeBannerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TogglePublishPromoCodeBannerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'togglePublishPromoCodeBanner'>
);

export type ToggleIsPublishSecondaryBannerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ToggleIsPublishSecondaryBannerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleIsPublishSecondaryBanner'>
);

export type UpdateFldIsMaintenanceMutationVariables = Exact<{
  isMaintenance: Scalars['Boolean'];
}>;


export type UpdateFldIsMaintenanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFLDIsMaintenance'>
);

export type UpdateOrderDeliveryAddressMutationVariables = Exact<{
  input: OrderDeliveryAddressInput;
}>;


export type UpdateOrderDeliveryAddressMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrderDeliveryAddress'>
);

export type UpdatePrivacyPolicyMutationVariables = Exact<{
  text: Scalars['String'];
}>;


export type UpdatePrivacyPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePrivacyPolicy'>
);

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['Int'];
  input: ProductInput;
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'ProductResponse' }
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & ProductDataFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & ErrorDataFragment
    )>> }
  ) }
);

export type UpdateShipCodeAndStateMutationVariables = Exact<{
  postalCode: Scalars['String'];
  state: Scalars['String'];
}>;


export type UpdateShipCodeAndStateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateShipCodeAndState'>
);

export type VerifyPendingOrderByBillCodeMutationVariables = Exact<{
  billCode: Scalars['String'];
}>;


export type VerifyPendingOrderByBillCodeMutation = (
  { __typename?: 'Mutation' }
  & { verifyPendingOrderByBillCode: (
    { __typename?: 'Order' }
    & OrderDataFragment
  ) }
);

export type MarkAllAsReadNotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkAllAsReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markAllAsReadNotification'>
);

export type MarkReadNotificationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MarkReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markReadNotification'>
);

export type AdminQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminQuery = (
  { __typename?: 'Query' }
  & { admin?: Maybe<(
    { __typename?: 'User' }
    & AdminDataFragment
  )> }
);

export type GetFldfaqListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFldfaqListQuery = (
  { __typename?: 'Query' }
  & { getFLDFAQList: Array<(
    { __typename?: 'FLDFAQ' }
    & Pick<Fldfaq, 'id' | 'category' | 'question' | 'answer'>
  )> }
);

export type GetFldSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFldSettingsQuery = (
  { __typename?: 'Query' }
  & { getFLDSettings: (
    { __typename?: 'FLDSettingsResponse' }
    & { shipCityAndState?: Maybe<(
      { __typename?: 'FLDSettings' }
      & Pick<FldSettings, 'key' | 'value'>
    )>, privacyPolicy?: Maybe<(
      { __typename?: 'FLDSettings' }
      & Pick<FldSettings, 'key' | 'value'>
    )>, isMaintenance?: Maybe<(
      { __typename?: 'FLDSettings' }
      & Pick<FldSettings, 'key' | 'value'>
    )> }
  ) }
);

export type HomepageBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type HomepageBannersQuery = (
  { __typename?: 'Query' }
  & { homepageBanners: Array<(
    { __typename?: 'HomepageBanner' }
    & HomepageBannerDataFragment
  )> }
);

export type OrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & OrderDataFragment
  )> }
);

export type OrderStatusCountQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderStatusCountQuery = (
  { __typename?: 'Query' }
  & { orderStatusCount: (
    { __typename?: 'OrderStatusCountResponse' }
    & Pick<OrderStatusCountResponse, 'totalOrders' | 'successfulOrders' | 'pendingOrders' | 'failedOrders' | 'confirmedOrders' | 'canceledOrders' | 'shippedOrders'>
  ) }
);

export type OrdersQueryVariables = Exact<{
  option: OrderListInput;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders: (
    { __typename?: 'PaginatedOrdersResponse' }
    & OrderListDataFragment
  ) }
);

export type ProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductQuery = (
  { __typename?: 'Query' }
  & { product?: Maybe<(
    { __typename?: 'Product' }
    & ProductDataFragment
  )> }
);

export type ProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductCategoriesQuery = (
  { __typename?: 'Query' }
  & { productCategories: Array<(
    { __typename?: 'ProductCategory' }
    & ProductCategoryDataFragment
  )> }
);

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & ProductDataFragment
  )> }
);

export type PromoCodeListQueryVariables = Exact<{ [key: string]: never; }>;


export type PromoCodeListQuery = (
  { __typename?: 'Query' }
  & { promoCodeList: Array<(
    { __typename?: 'PromoCode' }
    & PromoCodeDataFragment
  )> }
);

export type SecondaryBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type SecondaryBannersQuery = (
  { __typename?: 'Query' }
  & { secondaryBanners: Array<(
    { __typename?: 'SecondaryBanner' }
    & SecondaryBannerDataFragment
  )> }
);

export type DashboardDataQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardDataQuery = (
  { __typename?: 'Query' }
  & { dashboardData: (
    { __typename?: 'DashboardResponse' }
    & { FLDData: (
      { __typename?: 'FLDData' }
      & Pick<FldData, 'averagePricePerOrder'>
      & { orders: (
        { __typename?: 'OrdersData' }
        & Pick<OrdersData, 'currentTotalOrder' | 'currentMonthTotalOrder'>
      ), payments: (
        { __typename?: 'PaymentsData' }
        & Pick<PaymentsData, 'currentTotalPayment' | 'currentMonthTotalPayment'>
      ), earnings: (
        { __typename?: 'EarningsData' }
        & Pick<EarningsData, 'currentEarnings' | 'currentMonthEarnings' | 'earningPerDay'>
      ) }
    ), FKLData: (
      { __typename?: 'FKLData' }
      & Pick<FklData, 'totalTeams'>
      & { payments: (
        { __typename?: 'PaymentsData' }
        & Pick<PaymentsData, 'currentTotalPayment' | 'currentMonthTotalPayment'>
      ) }
    ), FKAData: (
      { __typename?: 'FKAData' }
      & Pick<FkaData, 'totalStudents'>
      & { payments: (
        { __typename?: 'PaymentsData' }
        & Pick<PaymentsData, 'currentTotalPayment' | 'currentMonthTotalPayment'>
      ) }
    ), totalTransactions: (
      { __typename?: 'TotalTransactionData' }
      & Pick<TotalTransactionData, 'currentTotalPayments' | 'currentMonthPayments'>
    ), notifications: Array<(
      { __typename?: 'Notification' }
      & NotificationDataFragment
    )>, salesAnalytics: (
      { __typename?: 'SalesAnalyticData' }
      & Pick<SalesAnalyticData, 'fkaTotalPayment' | 'fklTotalPayment' | 'fldTotalPayment'>
    ), latestTransactions: Array<(
      { __typename?: 'LatestTransactionData' }
      & Pick<LatestTransactionData, 'id' | 'category' | 'paymentName' | 'date' | 'totalPayment' | 'paymentStatus' | 'paymentMethod'>
    )> }
  ) }
);

export type NotificationsQueryVariables = Exact<{
  option: NotificationListInput;
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationResponse' }
    & NotificationListDataFragment
  ) }
);

export type UnreadNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnreadNotificationsQuery = (
  { __typename?: 'Query' }
  & { unreadNotifications: (
    { __typename?: 'UnreadNotificationResponse' }
    & Pick<UnreadNotificationResponse, 'totalUnread'>
    & { notifications: Array<(
      { __typename?: 'Notification' }
      & NotificationDataFragment
    )> }
  ) }
);

export type NewNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { newNotification?: Maybe<(
    { __typename?: 'Notification' }
    & NotificationDataFragment
  )> }
);

export const ErrorDataFragmentDoc = gql`
    fragment ErrorData on FieldError {
  field
  message
}
    `;
export const AdminDataFragmentDoc = gql`
    fragment AdminData on User {
  id
  username
  email
  profileImage
  isAdmin
  adminType
  createdAt
}
    `;
export const AdminResponseDataFragmentDoc = gql`
    fragment AdminResponseData on UserResponse {
  errors {
    ...ErrorData
  }
  user {
    ...AdminData
  }
}
    ${ErrorDataFragmentDoc}
${AdminDataFragmentDoc}`;
export const HomepageBannerDataFragmentDoc = gql`
    fragment HomepageBannerData on HomepageBanner {
  id
  title
  description
  imageName
  imagePath
  imageMobilePath
  hasProductButton
  product {
    id
    name
  }
  isPublished
  createdAt
}
    `;
export const OrderProductVariationDataFragmentDoc = gql`
    fragment OrderProductVariationData on OrderProductVariation {
  id
  name
  value
}
    `;
export const OrderProductDataFragmentDoc = gql`
    fragment OrderProductData on OrderProduct {
  id
  name
  description
  category
  brand
  sku
  isDiscount
  discount
  image
  unitPrice
  unitWeight
  quantity
  orderProductVariation {
    ...OrderProductVariationData
  }
}
    ${OrderProductVariationDataFragmentDoc}`;
export const OrderBillingDataFragmentDoc = gql`
    fragment OrderBillingData on OrderBilling {
  id
  firstName
  lastName
  fullNameOrCompanyName
  addressLine1
  addressLine2
  houseUnit
  city
  postalCode
  state
  country
  phoneNumber
  emailAddress
  notes
  shipToDifferentAddress
  shippingAddressLine1
  shippingAddressLine2
  shippingHouseUnit
  shippingCity
  shippingPostalCode
  shippingState
  shippingCountry
}
    `;
export const OrderDataFragmentDoc = gql`
    fragment OrderData on Order {
  id
  orderNo
  orderStatus
  billingName
  totalQuantity
  price
  shippingPrice
  totalDiscount
  totalPrice
  totalWeight
  promoCode
  paymentMethod
  paymentTxnChannel
  paymentTxnRef
  paymentTxnBillCode
  paymentTxnStatus
  paymentTxnReason
  paymentTxnPriceReceived
  paymentTxnDate
  trackingNumber
  courierProvider
  courierInfo
  orderProduct {
    ...OrderProductData
  }
  orderBilling {
    ...OrderBillingData
  }
  createdAt
}
    ${OrderProductDataFragmentDoc}
${OrderBillingDataFragmentDoc}`;
export const OrderListDataFragmentDoc = gql`
    fragment OrderListData on PaginatedOrdersResponse {
  orders {
    ...OrderData
  }
  totalOrders
  pageCount
}
    ${OrderDataFragmentDoc}`;
export const ProductCategoryDataFragmentDoc = gql`
    fragment ProductCategoryData on ProductCategory {
  id
  name
  imagePath
  createdAt
}
    `;
export const ProductDataFragmentDoc = gql`
    fragment ProductData on Product {
  id
  name
  description
  longDescription
  category
  brand
  defaultPrice
  unitsOnOrder
  isDiscount
  discount
  sku
  metaTitle
  metaKeywords
  metaDescription
  isPublished
  variations {
    id
    name
    variationData {
      id
      value
      unitPrice
      unitWeight
      unitsInStock
      image
    }
  }
  images {
    id
    name
    path
    isDefault
  }
}
    `;
export const PromoCodeDataFragmentDoc = gql`
    fragment PromoCodeData on PromoCode {
  id
  title
  description
  code
  quantity
  isFreeShippingPromo
  isPercentage
  discountPercentage
  discountPrice
  isPromoActive
  isPromoBanner
  imagePath
  isPublished
  createdAt
}
    `;
export const SecondaryBannerDataFragmentDoc = gql`
    fragment SecondaryBannerData on SecondaryBanner {
  id
  title
  imagePath
  isPublished
  createdAt
}
    `;
export const NotificationDataFragmentDoc = gql`
    fragment NotificationData on Notification {
  id
  type
  refId
  title
  message
  isRead
  createdAt
}
    `;
export const NotificationListDataFragmentDoc = gql`
    fragment NotificationListData on NotificationResponse {
  notifications {
    ...NotificationData
  }
  totalNotifications
  pageCount
}
    ${NotificationDataFragmentDoc}`;
export const AdminLoginDocument = gql`
    mutation AdminLogin($email: String!, $password: String!) {
  adminLogin(email: $email, password: $password) {
    ...AdminResponseData
  }
}
    ${AdminResponseDataFragmentDoc}`;
export type AdminLoginMutationFn = Apollo.MutationFunction<AdminLoginMutation, AdminLoginMutationVariables>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminLoginMutation(baseOptions?: Apollo.MutationHookOptions<AdminLoginMutation, AdminLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(AdminLoginDocument, options);
      }
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<AdminLoginMutation, AdminLoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CancelOrderDocument = gql`
    mutation cancelOrder($id: Int!) {
  cancelOrder(id: $id)
}
    `;
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const ConfirmOrderDocument = gql`
    mutation confirmOrder($id: Int!) {
  confirmOrder(id: $id)
}
    `;
export type ConfirmOrderMutationFn = Apollo.MutationFunction<ConfirmOrderMutation, ConfirmOrderMutationVariables>;

/**
 * __useConfirmOrderMutation__
 *
 * To run a mutation, you first call `useConfirmOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderMutation, { data, loading, error }] = useConfirmOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmOrderMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmOrderMutation, ConfirmOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmOrderMutation, ConfirmOrderMutationVariables>(ConfirmOrderDocument, options);
      }
export type ConfirmOrderMutationHookResult = ReturnType<typeof useConfirmOrderMutation>;
export type ConfirmOrderMutationResult = Apollo.MutationResult<ConfirmOrderMutation>;
export type ConfirmOrderMutationOptions = Apollo.BaseMutationOptions<ConfirmOrderMutation, ConfirmOrderMutationVariables>;
export const CreateFldfaqDocument = gql`
    mutation CreateFLDFAQ($input: FLDFAQInput!) {
  createFLDFAQ(input: $input) {
    FLDFAQCreated
    errors {
      ...ErrorData
    }
  }
}
    ${ErrorDataFragmentDoc}`;
export type CreateFldfaqMutationFn = Apollo.MutationFunction<CreateFldfaqMutation, CreateFldfaqMutationVariables>;

/**
 * __useCreateFldfaqMutation__
 *
 * To run a mutation, you first call `useCreateFldfaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFldfaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFldfaqMutation, { data, loading, error }] = useCreateFldfaqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFldfaqMutation(baseOptions?: Apollo.MutationHookOptions<CreateFldfaqMutation, CreateFldfaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFldfaqMutation, CreateFldfaqMutationVariables>(CreateFldfaqDocument, options);
      }
export type CreateFldfaqMutationHookResult = ReturnType<typeof useCreateFldfaqMutation>;
export type CreateFldfaqMutationResult = Apollo.MutationResult<CreateFldfaqMutation>;
export type CreateFldfaqMutationOptions = Apollo.BaseMutationOptions<CreateFldfaqMutation, CreateFldfaqMutationVariables>;
export const CreateHomepageBannerDocument = gql`
    mutation CreateHomepageBanner($input: HomepageBannerInput!) {
  createHomepageBanner(input: $input) {
    homepageBannerCreated
    errors {
      ...ErrorData
    }
  }
}
    ${ErrorDataFragmentDoc}`;
export type CreateHomepageBannerMutationFn = Apollo.MutationFunction<CreateHomepageBannerMutation, CreateHomepageBannerMutationVariables>;

/**
 * __useCreateHomepageBannerMutation__
 *
 * To run a mutation, you first call `useCreateHomepageBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHomepageBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHomepageBannerMutation, { data, loading, error }] = useCreateHomepageBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHomepageBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateHomepageBannerMutation, CreateHomepageBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHomepageBannerMutation, CreateHomepageBannerMutationVariables>(CreateHomepageBannerDocument, options);
      }
export type CreateHomepageBannerMutationHookResult = ReturnType<typeof useCreateHomepageBannerMutation>;
export type CreateHomepageBannerMutationResult = Apollo.MutationResult<CreateHomepageBannerMutation>;
export type CreateHomepageBannerMutationOptions = Apollo.BaseMutationOptions<CreateHomepageBannerMutation, CreateHomepageBannerMutationVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($input: ProductInput!) {
  createProduct(input: $input) {
    product {
      ...ProductData
    }
    errors {
      ...ErrorData
    }
  }
}
    ${ProductDataFragmentDoc}
${ErrorDataFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const CreateProductCategoryDocument = gql`
    mutation CreateProductCategory($input: ProductCategoryInput!) {
  createProductCategory(input: $input) {
    productCategory {
      ...ProductCategoryData
    }
    errors {
      ...ErrorData
    }
  }
}
    ${ProductCategoryDataFragmentDoc}
${ErrorDataFragmentDoc}`;
export type CreateProductCategoryMutationFn = Apollo.MutationFunction<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;

/**
 * __useCreateProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryMutation, { data, loading, error }] = useCreateProductCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>(CreateProductCategoryDocument, options);
      }
export type CreateProductCategoryMutationHookResult = ReturnType<typeof useCreateProductCategoryMutation>;
export type CreateProductCategoryMutationResult = Apollo.MutationResult<CreateProductCategoryMutation>;
export type CreateProductCategoryMutationOptions = Apollo.BaseMutationOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;
export const CreateProductImagesDocument = gql`
    mutation createProductImages($input: ProductImageInput!) {
  createProductImages(input: $input)
}
    `;
export type CreateProductImagesMutationFn = Apollo.MutationFunction<CreateProductImagesMutation, CreateProductImagesMutationVariables>;

/**
 * __useCreateProductImagesMutation__
 *
 * To run a mutation, you first call `useCreateProductImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductImagesMutation, { data, loading, error }] = useCreateProductImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductImagesMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductImagesMutation, CreateProductImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductImagesMutation, CreateProductImagesMutationVariables>(CreateProductImagesDocument, options);
      }
export type CreateProductImagesMutationHookResult = ReturnType<typeof useCreateProductImagesMutation>;
export type CreateProductImagesMutationResult = Apollo.MutationResult<CreateProductImagesMutation>;
export type CreateProductImagesMutationOptions = Apollo.BaseMutationOptions<CreateProductImagesMutation, CreateProductImagesMutationVariables>;
export const CreatePromoCodeDocument = gql`
    mutation createPromoCode($input: PromoCodeInput!) {
  createPromoCode(input: $input) {
    promoCodeCreated
    errors {
      ...ErrorData
    }
  }
}
    ${ErrorDataFragmentDoc}`;
export type CreatePromoCodeMutationFn = Apollo.MutationFunction<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;

/**
 * __useCreatePromoCodeMutation__
 *
 * To run a mutation, you first call `useCreatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoCodeMutation, { data, loading, error }] = useCreatePromoCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>(CreatePromoCodeDocument, options);
      }
export type CreatePromoCodeMutationHookResult = ReturnType<typeof useCreatePromoCodeMutation>;
export type CreatePromoCodeMutationResult = Apollo.MutationResult<CreatePromoCodeMutation>;
export type CreatePromoCodeMutationOptions = Apollo.BaseMutationOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;
export const CreateSecondaryBannerDocument = gql`
    mutation CreateSecondaryBanner($input: SecondaryBannerInput!) {
  createSecondaryBanner(input: $input) {
    secondaryBannerCreated
    errors {
      ...ErrorData
    }
  }
}
    ${ErrorDataFragmentDoc}`;
export type CreateSecondaryBannerMutationFn = Apollo.MutationFunction<CreateSecondaryBannerMutation, CreateSecondaryBannerMutationVariables>;

/**
 * __useCreateSecondaryBannerMutation__
 *
 * To run a mutation, you first call `useCreateSecondaryBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSecondaryBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSecondaryBannerMutation, { data, loading, error }] = useCreateSecondaryBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSecondaryBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateSecondaryBannerMutation, CreateSecondaryBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSecondaryBannerMutation, CreateSecondaryBannerMutationVariables>(CreateSecondaryBannerDocument, options);
      }
export type CreateSecondaryBannerMutationHookResult = ReturnType<typeof useCreateSecondaryBannerMutation>;
export type CreateSecondaryBannerMutationResult = Apollo.MutationResult<CreateSecondaryBannerMutation>;
export type CreateSecondaryBannerMutationOptions = Apollo.BaseMutationOptions<CreateSecondaryBannerMutation, CreateSecondaryBannerMutationVariables>;
export const DeleteFldfaqDocument = gql`
    mutation deleteFLDFAQ($id: Int!) {
  deleteFLDFAQ(id: $id)
}
    `;
export type DeleteFldfaqMutationFn = Apollo.MutationFunction<DeleteFldfaqMutation, DeleteFldfaqMutationVariables>;

/**
 * __useDeleteFldfaqMutation__
 *
 * To run a mutation, you first call `useDeleteFldfaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFldfaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFldfaqMutation, { data, loading, error }] = useDeleteFldfaqMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFldfaqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFldfaqMutation, DeleteFldfaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFldfaqMutation, DeleteFldfaqMutationVariables>(DeleteFldfaqDocument, options);
      }
export type DeleteFldfaqMutationHookResult = ReturnType<typeof useDeleteFldfaqMutation>;
export type DeleteFldfaqMutationResult = Apollo.MutationResult<DeleteFldfaqMutation>;
export type DeleteFldfaqMutationOptions = Apollo.BaseMutationOptions<DeleteFldfaqMutation, DeleteFldfaqMutationVariables>;
export const DeleteHomepageBannerDocument = gql`
    mutation deleteHomepageBanner($id: Int!) {
  deleteHomepageBanner(id: $id)
}
    `;
export type DeleteHomepageBannerMutationFn = Apollo.MutationFunction<DeleteHomepageBannerMutation, DeleteHomepageBannerMutationVariables>;

/**
 * __useDeleteHomepageBannerMutation__
 *
 * To run a mutation, you first call `useDeleteHomepageBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHomepageBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHomepageBannerMutation, { data, loading, error }] = useDeleteHomepageBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHomepageBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHomepageBannerMutation, DeleteHomepageBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHomepageBannerMutation, DeleteHomepageBannerMutationVariables>(DeleteHomepageBannerDocument, options);
      }
export type DeleteHomepageBannerMutationHookResult = ReturnType<typeof useDeleteHomepageBannerMutation>;
export type DeleteHomepageBannerMutationResult = Apollo.MutationResult<DeleteHomepageBannerMutation>;
export type DeleteHomepageBannerMutationOptions = Apollo.BaseMutationOptions<DeleteHomepageBannerMutation, DeleteHomepageBannerMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: Int!) {
  deleteProduct(id: $id)
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const DeleteProductCategoryDocument = gql`
    mutation deleteProductCategory($id: Int!) {
  deleteProductCategory(id: $id)
}
    `;
export type DeleteProductCategoryMutationFn = Apollo.MutationFunction<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>;

/**
 * __useDeleteProductCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductCategoryMutation, { data, loading, error }] = useDeleteProductCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>(DeleteProductCategoryDocument, options);
      }
export type DeleteProductCategoryMutationHookResult = ReturnType<typeof useDeleteProductCategoryMutation>;
export type DeleteProductCategoryMutationResult = Apollo.MutationResult<DeleteProductCategoryMutation>;
export type DeleteProductCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>;
export const DeleteProductImageDocument = gql`
    mutation deleteProductImage($productId: Int!, $imageId: Int!) {
  deleteProductImage(productId: $productId, imageId: $imageId)
}
    `;
export type DeleteProductImageMutationFn = Apollo.MutationFunction<DeleteProductImageMutation, DeleteProductImageMutationVariables>;

/**
 * __useDeleteProductImageMutation__
 *
 * To run a mutation, you first call `useDeleteProductImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductImageMutation, { data, loading, error }] = useDeleteProductImageMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useDeleteProductImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductImageMutation, DeleteProductImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductImageMutation, DeleteProductImageMutationVariables>(DeleteProductImageDocument, options);
      }
export type DeleteProductImageMutationHookResult = ReturnType<typeof useDeleteProductImageMutation>;
export type DeleteProductImageMutationResult = Apollo.MutationResult<DeleteProductImageMutation>;
export type DeleteProductImageMutationOptions = Apollo.BaseMutationOptions<DeleteProductImageMutation, DeleteProductImageMutationVariables>;
export const DeletePromoCodeDocument = gql`
    mutation deletePromoCode($id: Int!) {
  deletePromoCode(id: $id)
}
    `;
export type DeletePromoCodeMutationFn = Apollo.MutationFunction<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>;

/**
 * __useDeletePromoCodeMutation__
 *
 * To run a mutation, you first call `useDeletePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromoCodeMutation, { data, loading, error }] = useDeletePromoCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>(DeletePromoCodeDocument, options);
      }
export type DeletePromoCodeMutationHookResult = ReturnType<typeof useDeletePromoCodeMutation>;
export type DeletePromoCodeMutationResult = Apollo.MutationResult<DeletePromoCodeMutation>;
export type DeletePromoCodeMutationOptions = Apollo.BaseMutationOptions<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>;
export const DeleteSecondaryBannerDocument = gql`
    mutation deleteSecondaryBanner($id: Int!) {
  deleteSecondaryBanner(id: $id)
}
    `;
export type DeleteSecondaryBannerMutationFn = Apollo.MutationFunction<DeleteSecondaryBannerMutation, DeleteSecondaryBannerMutationVariables>;

/**
 * __useDeleteSecondaryBannerMutation__
 *
 * To run a mutation, you first call `useDeleteSecondaryBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSecondaryBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSecondaryBannerMutation, { data, loading, error }] = useDeleteSecondaryBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSecondaryBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSecondaryBannerMutation, DeleteSecondaryBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSecondaryBannerMutation, DeleteSecondaryBannerMutationVariables>(DeleteSecondaryBannerDocument, options);
      }
export type DeleteSecondaryBannerMutationHookResult = ReturnType<typeof useDeleteSecondaryBannerMutation>;
export type DeleteSecondaryBannerMutationResult = Apollo.MutationResult<DeleteSecondaryBannerMutation>;
export type DeleteSecondaryBannerMutationOptions = Apollo.BaseMutationOptions<DeleteSecondaryBannerMutation, DeleteSecondaryBannerMutationVariables>;
export const SetDefaultImageDocument = gql`
    mutation setDefaultImage($productId: Int!, $imageId: Int!) {
  setDefaultImage(productId: $productId, imageId: $imageId)
}
    `;
export type SetDefaultImageMutationFn = Apollo.MutationFunction<SetDefaultImageMutation, SetDefaultImageMutationVariables>;

/**
 * __useSetDefaultImageMutation__
 *
 * To run a mutation, you first call `useSetDefaultImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultImageMutation, { data, loading, error }] = useSetDefaultImageMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useSetDefaultImageMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultImageMutation, SetDefaultImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultImageMutation, SetDefaultImageMutationVariables>(SetDefaultImageDocument, options);
      }
export type SetDefaultImageMutationHookResult = ReturnType<typeof useSetDefaultImageMutation>;
export type SetDefaultImageMutationResult = Apollo.MutationResult<SetDefaultImageMutation>;
export type SetDefaultImageMutationOptions = Apollo.BaseMutationOptions<SetDefaultImageMutation, SetDefaultImageMutationVariables>;
export const ShipOrderDocument = gql`
    mutation shipOrder($input: ShipOrderInput!) {
  shipOrder(input: $input)
}
    `;
export type ShipOrderMutationFn = Apollo.MutationFunction<ShipOrderMutation, ShipOrderMutationVariables>;

/**
 * __useShipOrderMutation__
 *
 * To run a mutation, you first call `useShipOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipOrderMutation, { data, loading, error }] = useShipOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShipOrderMutation(baseOptions?: Apollo.MutationHookOptions<ShipOrderMutation, ShipOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShipOrderMutation, ShipOrderMutationVariables>(ShipOrderDocument, options);
      }
export type ShipOrderMutationHookResult = ReturnType<typeof useShipOrderMutation>;
export type ShipOrderMutationResult = Apollo.MutationResult<ShipOrderMutation>;
export type ShipOrderMutationOptions = Apollo.BaseMutationOptions<ShipOrderMutation, ShipOrderMutationVariables>;
export const TogglePromoCodeActiveDocument = gql`
    mutation togglePromoCodeActive($id: Int!) {
  togglePromoCodeActive(id: $id)
}
    `;
export type TogglePromoCodeActiveMutationFn = Apollo.MutationFunction<TogglePromoCodeActiveMutation, TogglePromoCodeActiveMutationVariables>;

/**
 * __useTogglePromoCodeActiveMutation__
 *
 * To run a mutation, you first call `useTogglePromoCodeActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePromoCodeActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePromoCodeActiveMutation, { data, loading, error }] = useTogglePromoCodeActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTogglePromoCodeActiveMutation(baseOptions?: Apollo.MutationHookOptions<TogglePromoCodeActiveMutation, TogglePromoCodeActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TogglePromoCodeActiveMutation, TogglePromoCodeActiveMutationVariables>(TogglePromoCodeActiveDocument, options);
      }
export type TogglePromoCodeActiveMutationHookResult = ReturnType<typeof useTogglePromoCodeActiveMutation>;
export type TogglePromoCodeActiveMutationResult = Apollo.MutationResult<TogglePromoCodeActiveMutation>;
export type TogglePromoCodeActiveMutationOptions = Apollo.BaseMutationOptions<TogglePromoCodeActiveMutation, TogglePromoCodeActiveMutationVariables>;
export const ToggleIsPublishHomepageBannerDocument = gql`
    mutation toggleIsPublishHomepageBanner($id: Int!) {
  toggleIsPublishHomepageBanner(id: $id)
}
    `;
export type ToggleIsPublishHomepageBannerMutationFn = Apollo.MutationFunction<ToggleIsPublishHomepageBannerMutation, ToggleIsPublishHomepageBannerMutationVariables>;

/**
 * __useToggleIsPublishHomepageBannerMutation__
 *
 * To run a mutation, you first call `useToggleIsPublishHomepageBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIsPublishHomepageBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIsPublishHomepageBannerMutation, { data, loading, error }] = useToggleIsPublishHomepageBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleIsPublishHomepageBannerMutation(baseOptions?: Apollo.MutationHookOptions<ToggleIsPublishHomepageBannerMutation, ToggleIsPublishHomepageBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleIsPublishHomepageBannerMutation, ToggleIsPublishHomepageBannerMutationVariables>(ToggleIsPublishHomepageBannerDocument, options);
      }
export type ToggleIsPublishHomepageBannerMutationHookResult = ReturnType<typeof useToggleIsPublishHomepageBannerMutation>;
export type ToggleIsPublishHomepageBannerMutationResult = Apollo.MutationResult<ToggleIsPublishHomepageBannerMutation>;
export type ToggleIsPublishHomepageBannerMutationOptions = Apollo.BaseMutationOptions<ToggleIsPublishHomepageBannerMutation, ToggleIsPublishHomepageBannerMutationVariables>;
export const ToggleIsPublishProductDocument = gql`
    mutation toggleIsPublishProduct($id: Int!) {
  toggleIsPublishProduct(id: $id)
}
    `;
export type ToggleIsPublishProductMutationFn = Apollo.MutationFunction<ToggleIsPublishProductMutation, ToggleIsPublishProductMutationVariables>;

/**
 * __useToggleIsPublishProductMutation__
 *
 * To run a mutation, you first call `useToggleIsPublishProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIsPublishProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIsPublishProductMutation, { data, loading, error }] = useToggleIsPublishProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleIsPublishProductMutation(baseOptions?: Apollo.MutationHookOptions<ToggleIsPublishProductMutation, ToggleIsPublishProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleIsPublishProductMutation, ToggleIsPublishProductMutationVariables>(ToggleIsPublishProductDocument, options);
      }
export type ToggleIsPublishProductMutationHookResult = ReturnType<typeof useToggleIsPublishProductMutation>;
export type ToggleIsPublishProductMutationResult = Apollo.MutationResult<ToggleIsPublishProductMutation>;
export type ToggleIsPublishProductMutationOptions = Apollo.BaseMutationOptions<ToggleIsPublishProductMutation, ToggleIsPublishProductMutationVariables>;
export const TogglePublishPromoCodeBannerDocument = gql`
    mutation togglePublishPromoCodeBanner($id: Int!) {
  togglePublishPromoCodeBanner(id: $id)
}
    `;
export type TogglePublishPromoCodeBannerMutationFn = Apollo.MutationFunction<TogglePublishPromoCodeBannerMutation, TogglePublishPromoCodeBannerMutationVariables>;

/**
 * __useTogglePublishPromoCodeBannerMutation__
 *
 * To run a mutation, you first call `useTogglePublishPromoCodeBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePublishPromoCodeBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePublishPromoCodeBannerMutation, { data, loading, error }] = useTogglePublishPromoCodeBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTogglePublishPromoCodeBannerMutation(baseOptions?: Apollo.MutationHookOptions<TogglePublishPromoCodeBannerMutation, TogglePublishPromoCodeBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TogglePublishPromoCodeBannerMutation, TogglePublishPromoCodeBannerMutationVariables>(TogglePublishPromoCodeBannerDocument, options);
      }
export type TogglePublishPromoCodeBannerMutationHookResult = ReturnType<typeof useTogglePublishPromoCodeBannerMutation>;
export type TogglePublishPromoCodeBannerMutationResult = Apollo.MutationResult<TogglePublishPromoCodeBannerMutation>;
export type TogglePublishPromoCodeBannerMutationOptions = Apollo.BaseMutationOptions<TogglePublishPromoCodeBannerMutation, TogglePublishPromoCodeBannerMutationVariables>;
export const ToggleIsPublishSecondaryBannerDocument = gql`
    mutation toggleIsPublishSecondaryBanner($id: Int!) {
  toggleIsPublishSecondaryBanner(id: $id)
}
    `;
export type ToggleIsPublishSecondaryBannerMutationFn = Apollo.MutationFunction<ToggleIsPublishSecondaryBannerMutation, ToggleIsPublishSecondaryBannerMutationVariables>;

/**
 * __useToggleIsPublishSecondaryBannerMutation__
 *
 * To run a mutation, you first call `useToggleIsPublishSecondaryBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIsPublishSecondaryBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIsPublishSecondaryBannerMutation, { data, loading, error }] = useToggleIsPublishSecondaryBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleIsPublishSecondaryBannerMutation(baseOptions?: Apollo.MutationHookOptions<ToggleIsPublishSecondaryBannerMutation, ToggleIsPublishSecondaryBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleIsPublishSecondaryBannerMutation, ToggleIsPublishSecondaryBannerMutationVariables>(ToggleIsPublishSecondaryBannerDocument, options);
      }
export type ToggleIsPublishSecondaryBannerMutationHookResult = ReturnType<typeof useToggleIsPublishSecondaryBannerMutation>;
export type ToggleIsPublishSecondaryBannerMutationResult = Apollo.MutationResult<ToggleIsPublishSecondaryBannerMutation>;
export type ToggleIsPublishSecondaryBannerMutationOptions = Apollo.BaseMutationOptions<ToggleIsPublishSecondaryBannerMutation, ToggleIsPublishSecondaryBannerMutationVariables>;
export const UpdateFldIsMaintenanceDocument = gql`
    mutation UpdateFLDIsMaintenance($isMaintenance: Boolean!) {
  updateFLDIsMaintenance(isMaintenance: $isMaintenance)
}
    `;
export type UpdateFldIsMaintenanceMutationFn = Apollo.MutationFunction<UpdateFldIsMaintenanceMutation, UpdateFldIsMaintenanceMutationVariables>;

/**
 * __useUpdateFldIsMaintenanceMutation__
 *
 * To run a mutation, you first call `useUpdateFldIsMaintenanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFldIsMaintenanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFldIsMaintenanceMutation, { data, loading, error }] = useUpdateFldIsMaintenanceMutation({
 *   variables: {
 *      isMaintenance: // value for 'isMaintenance'
 *   },
 * });
 */
export function useUpdateFldIsMaintenanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFldIsMaintenanceMutation, UpdateFldIsMaintenanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFldIsMaintenanceMutation, UpdateFldIsMaintenanceMutationVariables>(UpdateFldIsMaintenanceDocument, options);
      }
export type UpdateFldIsMaintenanceMutationHookResult = ReturnType<typeof useUpdateFldIsMaintenanceMutation>;
export type UpdateFldIsMaintenanceMutationResult = Apollo.MutationResult<UpdateFldIsMaintenanceMutation>;
export type UpdateFldIsMaintenanceMutationOptions = Apollo.BaseMutationOptions<UpdateFldIsMaintenanceMutation, UpdateFldIsMaintenanceMutationVariables>;
export const UpdateOrderDeliveryAddressDocument = gql`
    mutation UpdateOrderDeliveryAddress($input: OrderDeliveryAddressInput!) {
  updateOrderDeliveryAddress(input: $input)
}
    `;
export type UpdateOrderDeliveryAddressMutationFn = Apollo.MutationFunction<UpdateOrderDeliveryAddressMutation, UpdateOrderDeliveryAddressMutationVariables>;

/**
 * __useUpdateOrderDeliveryAddressMutation__
 *
 * To run a mutation, you first call `useUpdateOrderDeliveryAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderDeliveryAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderDeliveryAddressMutation, { data, loading, error }] = useUpdateOrderDeliveryAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderDeliveryAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderDeliveryAddressMutation, UpdateOrderDeliveryAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderDeliveryAddressMutation, UpdateOrderDeliveryAddressMutationVariables>(UpdateOrderDeliveryAddressDocument, options);
      }
export type UpdateOrderDeliveryAddressMutationHookResult = ReturnType<typeof useUpdateOrderDeliveryAddressMutation>;
export type UpdateOrderDeliveryAddressMutationResult = Apollo.MutationResult<UpdateOrderDeliveryAddressMutation>;
export type UpdateOrderDeliveryAddressMutationOptions = Apollo.BaseMutationOptions<UpdateOrderDeliveryAddressMutation, UpdateOrderDeliveryAddressMutationVariables>;
export const UpdatePrivacyPolicyDocument = gql`
    mutation UpdatePrivacyPolicy($text: String!) {
  updatePrivacyPolicy(text: $text)
}
    `;
export type UpdatePrivacyPolicyMutationFn = Apollo.MutationFunction<UpdatePrivacyPolicyMutation, UpdatePrivacyPolicyMutationVariables>;

/**
 * __useUpdatePrivacyPolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePrivacyPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivacyPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivacyPolicyMutation, { data, loading, error }] = useUpdatePrivacyPolicyMutation({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdatePrivacyPolicyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrivacyPolicyMutation, UpdatePrivacyPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrivacyPolicyMutation, UpdatePrivacyPolicyMutationVariables>(UpdatePrivacyPolicyDocument, options);
      }
export type UpdatePrivacyPolicyMutationHookResult = ReturnType<typeof useUpdatePrivacyPolicyMutation>;
export type UpdatePrivacyPolicyMutationResult = Apollo.MutationResult<UpdatePrivacyPolicyMutation>;
export type UpdatePrivacyPolicyMutationOptions = Apollo.BaseMutationOptions<UpdatePrivacyPolicyMutation, UpdatePrivacyPolicyMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($id: Int!, $input: ProductInput!) {
  updateProduct(id: $id, input: $input) {
    product {
      ...ProductData
    }
    errors {
      ...ErrorData
    }
  }
}
    ${ProductDataFragmentDoc}
${ErrorDataFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateShipCodeAndStateDocument = gql`
    mutation UpdateShipCodeAndState($postalCode: String!, $state: String!) {
  updateShipCodeAndState(postalCode: $postalCode, state: $state)
}
    `;
export type UpdateShipCodeAndStateMutationFn = Apollo.MutationFunction<UpdateShipCodeAndStateMutation, UpdateShipCodeAndStateMutationVariables>;

/**
 * __useUpdateShipCodeAndStateMutation__
 *
 * To run a mutation, you first call `useUpdateShipCodeAndStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipCodeAndStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipCodeAndStateMutation, { data, loading, error }] = useUpdateShipCodeAndStateMutation({
 *   variables: {
 *      postalCode: // value for 'postalCode'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateShipCodeAndStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShipCodeAndStateMutation, UpdateShipCodeAndStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShipCodeAndStateMutation, UpdateShipCodeAndStateMutationVariables>(UpdateShipCodeAndStateDocument, options);
      }
export type UpdateShipCodeAndStateMutationHookResult = ReturnType<typeof useUpdateShipCodeAndStateMutation>;
export type UpdateShipCodeAndStateMutationResult = Apollo.MutationResult<UpdateShipCodeAndStateMutation>;
export type UpdateShipCodeAndStateMutationOptions = Apollo.BaseMutationOptions<UpdateShipCodeAndStateMutation, UpdateShipCodeAndStateMutationVariables>;
export const VerifyPendingOrderByBillCodeDocument = gql`
    mutation verifyPendingOrderByBillCode($billCode: String!) {
  verifyPendingOrderByBillCode(billCode: $billCode) {
    ...OrderData
  }
}
    ${OrderDataFragmentDoc}`;
export type VerifyPendingOrderByBillCodeMutationFn = Apollo.MutationFunction<VerifyPendingOrderByBillCodeMutation, VerifyPendingOrderByBillCodeMutationVariables>;

/**
 * __useVerifyPendingOrderByBillCodeMutation__
 *
 * To run a mutation, you first call `useVerifyPendingOrderByBillCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPendingOrderByBillCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPendingOrderByBillCodeMutation, { data, loading, error }] = useVerifyPendingOrderByBillCodeMutation({
 *   variables: {
 *      billCode: // value for 'billCode'
 *   },
 * });
 */
export function useVerifyPendingOrderByBillCodeMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPendingOrderByBillCodeMutation, VerifyPendingOrderByBillCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPendingOrderByBillCodeMutation, VerifyPendingOrderByBillCodeMutationVariables>(VerifyPendingOrderByBillCodeDocument, options);
      }
export type VerifyPendingOrderByBillCodeMutationHookResult = ReturnType<typeof useVerifyPendingOrderByBillCodeMutation>;
export type VerifyPendingOrderByBillCodeMutationResult = Apollo.MutationResult<VerifyPendingOrderByBillCodeMutation>;
export type VerifyPendingOrderByBillCodeMutationOptions = Apollo.BaseMutationOptions<VerifyPendingOrderByBillCodeMutation, VerifyPendingOrderByBillCodeMutationVariables>;
export const MarkAllAsReadNotificationDocument = gql`
    mutation MarkAllAsReadNotification {
  markAllAsReadNotification
}
    `;
export type MarkAllAsReadNotificationMutationFn = Apollo.MutationFunction<MarkAllAsReadNotificationMutation, MarkAllAsReadNotificationMutationVariables>;

/**
 * __useMarkAllAsReadNotificationMutation__
 *
 * To run a mutation, you first call `useMarkAllAsReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllAsReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllAsReadNotificationMutation, { data, loading, error }] = useMarkAllAsReadNotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllAsReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllAsReadNotificationMutation, MarkAllAsReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllAsReadNotificationMutation, MarkAllAsReadNotificationMutationVariables>(MarkAllAsReadNotificationDocument, options);
      }
export type MarkAllAsReadNotificationMutationHookResult = ReturnType<typeof useMarkAllAsReadNotificationMutation>;
export type MarkAllAsReadNotificationMutationResult = Apollo.MutationResult<MarkAllAsReadNotificationMutation>;
export type MarkAllAsReadNotificationMutationOptions = Apollo.BaseMutationOptions<MarkAllAsReadNotificationMutation, MarkAllAsReadNotificationMutationVariables>;
export const MarkReadNotificationDocument = gql`
    mutation markReadNotification($id: Int!) {
  markReadNotification(id: $id)
}
    `;
export type MarkReadNotificationMutationFn = Apollo.MutationFunction<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>;

/**
 * __useMarkReadNotificationMutation__
 *
 * To run a mutation, you first call `useMarkReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReadNotificationMutation, { data, loading, error }] = useMarkReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>(MarkReadNotificationDocument, options);
      }
export type MarkReadNotificationMutationHookResult = ReturnType<typeof useMarkReadNotificationMutation>;
export type MarkReadNotificationMutationResult = Apollo.MutationResult<MarkReadNotificationMutation>;
export type MarkReadNotificationMutationOptions = Apollo.BaseMutationOptions<MarkReadNotificationMutation, MarkReadNotificationMutationVariables>;
export const AdminDocument = gql`
    query Admin {
  admin {
    ...AdminData
  }
}
    ${AdminDataFragmentDoc}`;

/**
 * __useAdminQuery__
 *
 * To run a query within a React component, call `useAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminQuery(baseOptions?: Apollo.QueryHookOptions<AdminQuery, AdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminQuery, AdminQueryVariables>(AdminDocument, options);
      }
export function useAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminQuery, AdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminQuery, AdminQueryVariables>(AdminDocument, options);
        }
export type AdminQueryHookResult = ReturnType<typeof useAdminQuery>;
export type AdminLazyQueryHookResult = ReturnType<typeof useAdminLazyQuery>;
export type AdminQueryResult = Apollo.QueryResult<AdminQuery, AdminQueryVariables>;
export const GetFldfaqListDocument = gql`
    query GetFLDFAQList {
  getFLDFAQList {
    id
    category
    question
    answer
  }
}
    `;

/**
 * __useGetFldfaqListQuery__
 *
 * To run a query within a React component, call `useGetFldfaqListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFldfaqListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFldfaqListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFldfaqListQuery(baseOptions?: Apollo.QueryHookOptions<GetFldfaqListQuery, GetFldfaqListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFldfaqListQuery, GetFldfaqListQueryVariables>(GetFldfaqListDocument, options);
      }
export function useGetFldfaqListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFldfaqListQuery, GetFldfaqListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFldfaqListQuery, GetFldfaqListQueryVariables>(GetFldfaqListDocument, options);
        }
export type GetFldfaqListQueryHookResult = ReturnType<typeof useGetFldfaqListQuery>;
export type GetFldfaqListLazyQueryHookResult = ReturnType<typeof useGetFldfaqListLazyQuery>;
export type GetFldfaqListQueryResult = Apollo.QueryResult<GetFldfaqListQuery, GetFldfaqListQueryVariables>;
export const GetFldSettingsDocument = gql`
    query GetFLDSettings {
  getFLDSettings {
    shipCityAndState {
      key
      value
    }
    privacyPolicy {
      key
      value
    }
    isMaintenance {
      key
      value
    }
  }
}
    `;

/**
 * __useGetFldSettingsQuery__
 *
 * To run a query within a React component, call `useGetFldSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFldSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFldSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFldSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetFldSettingsQuery, GetFldSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFldSettingsQuery, GetFldSettingsQueryVariables>(GetFldSettingsDocument, options);
      }
export function useGetFldSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFldSettingsQuery, GetFldSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFldSettingsQuery, GetFldSettingsQueryVariables>(GetFldSettingsDocument, options);
        }
export type GetFldSettingsQueryHookResult = ReturnType<typeof useGetFldSettingsQuery>;
export type GetFldSettingsLazyQueryHookResult = ReturnType<typeof useGetFldSettingsLazyQuery>;
export type GetFldSettingsQueryResult = Apollo.QueryResult<GetFldSettingsQuery, GetFldSettingsQueryVariables>;
export const HomepageBannersDocument = gql`
    query HomepageBanners {
  homepageBanners {
    ...HomepageBannerData
  }
}
    ${HomepageBannerDataFragmentDoc}`;

/**
 * __useHomepageBannersQuery__
 *
 * To run a query within a React component, call `useHomepageBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomepageBannersQuery(baseOptions?: Apollo.QueryHookOptions<HomepageBannersQuery, HomepageBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomepageBannersQuery, HomepageBannersQueryVariables>(HomepageBannersDocument, options);
      }
export function useHomepageBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomepageBannersQuery, HomepageBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomepageBannersQuery, HomepageBannersQueryVariables>(HomepageBannersDocument, options);
        }
export type HomepageBannersQueryHookResult = ReturnType<typeof useHomepageBannersQuery>;
export type HomepageBannersLazyQueryHookResult = ReturnType<typeof useHomepageBannersLazyQuery>;
export type HomepageBannersQueryResult = Apollo.QueryResult<HomepageBannersQuery, HomepageBannersQueryVariables>;
export const OrderDocument = gql`
    query Order($id: Int!) {
  order(id: $id) {
    ...OrderData
  }
}
    ${OrderDataFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderStatusCountDocument = gql`
    query OrderStatusCount {
  orderStatusCount {
    totalOrders
    successfulOrders
    pendingOrders
    failedOrders
    confirmedOrders
    canceledOrders
    shippedOrders
  }
}
    `;

/**
 * __useOrderStatusCountQuery__
 *
 * To run a query within a React component, call `useOrderStatusCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatusCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatusCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderStatusCountQuery(baseOptions?: Apollo.QueryHookOptions<OrderStatusCountQuery, OrderStatusCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderStatusCountQuery, OrderStatusCountQueryVariables>(OrderStatusCountDocument, options);
      }
export function useOrderStatusCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderStatusCountQuery, OrderStatusCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderStatusCountQuery, OrderStatusCountQueryVariables>(OrderStatusCountDocument, options);
        }
export type OrderStatusCountQueryHookResult = ReturnType<typeof useOrderStatusCountQuery>;
export type OrderStatusCountLazyQueryHookResult = ReturnType<typeof useOrderStatusCountLazyQuery>;
export type OrderStatusCountQueryResult = Apollo.QueryResult<OrderStatusCountQuery, OrderStatusCountQueryVariables>;
export const OrdersDocument = gql`
    query Orders($option: OrderListInput!) {
  orders(option: $option) {
    ...OrderListData
  }
}
    ${OrderListDataFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      option: // value for 'option'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const ProductDocument = gql`
    query Product($id: Int!) {
  product(id: $id) {
    ...ProductData
  }
}
    ${ProductDataFragmentDoc}`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductCategoriesDocument = gql`
    query ProductCategories {
  productCategories {
    ...ProductCategoryData
  }
}
    ${ProductCategoryDataFragmentDoc}`;

/**
 * __useProductCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
      }
export function useProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
        }
export type ProductCategoriesQueryHookResult = ReturnType<typeof useProductCategoriesQuery>;
export type ProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductCategoriesLazyQuery>;
export type ProductCategoriesQueryResult = Apollo.QueryResult<ProductCategoriesQuery, ProductCategoriesQueryVariables>;
export const ProductsDocument = gql`
    query Products {
  products {
    ...ProductData
  }
}
    ${ProductDataFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const PromoCodeListDocument = gql`
    query PromoCodeList {
  promoCodeList {
    ...PromoCodeData
  }
}
    ${PromoCodeDataFragmentDoc}`;

/**
 * __usePromoCodeListQuery__
 *
 * To run a query within a React component, call `usePromoCodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromoCodeListQuery(baseOptions?: Apollo.QueryHookOptions<PromoCodeListQuery, PromoCodeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoCodeListQuery, PromoCodeListQueryVariables>(PromoCodeListDocument, options);
      }
export function usePromoCodeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeListQuery, PromoCodeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoCodeListQuery, PromoCodeListQueryVariables>(PromoCodeListDocument, options);
        }
export type PromoCodeListQueryHookResult = ReturnType<typeof usePromoCodeListQuery>;
export type PromoCodeListLazyQueryHookResult = ReturnType<typeof usePromoCodeListLazyQuery>;
export type PromoCodeListQueryResult = Apollo.QueryResult<PromoCodeListQuery, PromoCodeListQueryVariables>;
export const SecondaryBannersDocument = gql`
    query SecondaryBanners {
  secondaryBanners {
    ...SecondaryBannerData
  }
}
    ${SecondaryBannerDataFragmentDoc}`;

/**
 * __useSecondaryBannersQuery__
 *
 * To run a query within a React component, call `useSecondaryBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecondaryBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecondaryBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSecondaryBannersQuery(baseOptions?: Apollo.QueryHookOptions<SecondaryBannersQuery, SecondaryBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SecondaryBannersQuery, SecondaryBannersQueryVariables>(SecondaryBannersDocument, options);
      }
export function useSecondaryBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SecondaryBannersQuery, SecondaryBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SecondaryBannersQuery, SecondaryBannersQueryVariables>(SecondaryBannersDocument, options);
        }
export type SecondaryBannersQueryHookResult = ReturnType<typeof useSecondaryBannersQuery>;
export type SecondaryBannersLazyQueryHookResult = ReturnType<typeof useSecondaryBannersLazyQuery>;
export type SecondaryBannersQueryResult = Apollo.QueryResult<SecondaryBannersQuery, SecondaryBannersQueryVariables>;
export const DashboardDataDocument = gql`
    query DashboardData {
  dashboardData {
    FLDData {
      orders {
        currentTotalOrder
        currentMonthTotalOrder
      }
      payments {
        currentTotalPayment
        currentMonthTotalPayment
      }
      averagePricePerOrder
      earnings {
        currentEarnings
        currentMonthEarnings
        earningPerDay
      }
    }
    FKLData {
      payments {
        currentTotalPayment
        currentMonthTotalPayment
      }
      totalTeams
    }
    FKAData {
      payments {
        currentTotalPayment
        currentMonthTotalPayment
      }
      totalStudents
    }
    totalTransactions {
      currentTotalPayments
      currentMonthPayments
    }
    notifications {
      ...NotificationData
    }
    salesAnalytics {
      fkaTotalPayment
      fklTotalPayment
      fldTotalPayment
    }
    latestTransactions {
      id
      category
      paymentName
      date
      totalPayment
      paymentStatus
      paymentMethod
    }
  }
}
    ${NotificationDataFragmentDoc}`;

/**
 * __useDashboardDataQuery__
 *
 * To run a query within a React component, call `useDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardDataQuery(baseOptions?: Apollo.QueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
      }
export function useDashboardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
        }
export type DashboardDataQueryHookResult = ReturnType<typeof useDashboardDataQuery>;
export type DashboardDataLazyQueryHookResult = ReturnType<typeof useDashboardDataLazyQuery>;
export type DashboardDataQueryResult = Apollo.QueryResult<DashboardDataQuery, DashboardDataQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($option: NotificationListInput!) {
  notifications(option: $option) {
    ...NotificationListData
  }
}
    ${NotificationListDataFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      option: // value for 'option'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const UnreadNotificationsDocument = gql`
    query UnreadNotifications {
  unreadNotifications {
    totalUnread
    notifications {
      ...NotificationData
    }
  }
}
    ${NotificationDataFragmentDoc}`;

/**
 * __useUnreadNotificationsQuery__
 *
 * To run a query within a React component, call `useUnreadNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>(UnreadNotificationsDocument, options);
      }
export function useUnreadNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>(UnreadNotificationsDocument, options);
        }
export type UnreadNotificationsQueryHookResult = ReturnType<typeof useUnreadNotificationsQuery>;
export type UnreadNotificationsLazyQueryHookResult = ReturnType<typeof useUnreadNotificationsLazyQuery>;
export type UnreadNotificationsQueryResult = Apollo.QueryResult<UnreadNotificationsQuery, UnreadNotificationsQueryVariables>;
export const NewNotificationDocument = gql`
    subscription NewNotification {
  newNotification {
    ...NotificationData
  }
}
    ${NotificationDataFragmentDoc}`;

/**
 * __useNewNotificationSubscription__
 *
 * To run a query within a React component, call `useNewNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NewNotificationSubscription, NewNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewNotificationSubscription, NewNotificationSubscriptionVariables>(NewNotificationDocument, options);
      }
export type NewNotificationSubscriptionHookResult = ReturnType<typeof useNewNotificationSubscription>;
export type NewNotificationSubscriptionResult = Apollo.SubscriptionResult<NewNotificationSubscription>;