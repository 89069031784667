import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { UncontrolledTooltip } from 'reactstrap';
import Preloader from '../../../../components/Layout/Preloader';
import { notification } from '../../../../components/Utils/Notification';
import {
    HomepageBannerDataFragment,
    useDeleteHomepageBannerMutation,
    useHomepageBannersQuery,
    useToggleIsPublishHomepageBannerMutation
} from '../../../../generated/graphql';
import { serveImage } from '../../../../util/Helper';

interface HomepageBannerListTableProps {}

export const HomepageBannerListTable: React.FC<HomepageBannerListTableProps> = () => {
    const { data: homepageBannerData, loading: homepageBannerLoading } = useHomepageBannersQuery();
    const [deleteHomepageBanner] = useDeleteHomepageBannerMutation();
    const [toggleIsPublishHomepageBanner] = useToggleIsPublishHomepageBannerMutation();

    const handleDeleteHomepageBanner = async (id: number) => {
        const response = await deleteHomepageBanner({
            variables: { id },
            update: (cache: any) => {
                cache.evict({ id: 'HomepageBanner:' + id });
            },
        });

        if (response.data?.deleteHomepageBanner) {
            notification('success', 'Successful Delete!');
        }
    };

    if (homepageBannerLoading) {
        return <Preloader />;
    }

    const togglePublishHomepageBanner = async (id: number, isPublished: boolean) => {
        try {
            await toggleIsPublishHomepageBanner({
                variables: { id },
                update: (cache: any) => {
                    cache.writeFragment({
                        id: 'HomepageBanner:' + id,
                        fragment: gql`
                            fragment __ on HomepageBanner {
                                isPublished
                            }
                        `,
                        data: { isPublished: !isPublished },
                    });
                },
            });
        } catch (error) {
            notification('error', error.message);
        }
    };

    return (
        <div className='table-rep-plugin'>
            <div className='table-responsive mb-0' data-pattern='priority-columns'>
                <Table id='tech-companies-1' className='table'>
                    <Thead className='table-light'>
                        <Tr>
                            <Th width='15%'>Full Width Image</Th>
                            <Th width='10%'>Mobile Image</Th>
                            <Th width='25%'>Title & Description</Th>
                            <Th width='25%' data-priority='2'>
                                Product
                            </Th>
                            <Th width='10%' data-priority='3'>
                                Created At
                            </Th>
                            <Th width='10%' data-priority='6' className='text-center'>
                                Actions
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {homepageBannerData?.homepageBanners ? (
                            homepageBannerData.homepageBanners.map((homepageBanner: HomepageBannerDataFragment, index: number) => (
                                <Tr key={index}>
                                    <Th>
                                        <img
                                            src={serveImage(homepageBanner.imagePath || '')}
                                            alt={homepageBanner.imageName}
                                            className='img-fluid mx-auto rounded'
                                            style={{ maxHeight: '85px' }}
                                        />
                                    </Th>
                                    <Th>
                                        <img
                                            src={serveImage(homepageBanner.imageMobilePath || '')}
                                            alt={homepageBanner.imageName}
                                            className='img-fluid mx-auto rounded'
                                            style={{ maxHeight: '85px' }}
                                        />
                                    </Th>
                                    <Th>
                                        <Link to='#'>
                                            <h5 className='font-size-14 mb-1 text-dark'>{homepageBanner.title}</h5>
                                            <div style={{ maxWidth: '75ch', overflow: 'hidden' }}>
                                                <p className='text-muted mb-0'>
                                                    {homepageBanner.description ? homepageBanner.description.substr(0, 200) : ''}
                                                </p>
                                            </div>
                                        </Link>
                                    </Th>
                                    <Th>{homepageBanner.hasProductButton ? homepageBanner.product?.name : 'No product'}</Th>
                                    <Th>{dayjs(parseInt(homepageBanner.createdAt)).format('LLL')}</Th>
                                    <Th className='text-center'>
                                        <ul className='list-inline font-size-20 contact-links mb-0'>
                                            {homepageBanner.isPublished ? null : (
                                                <li className='list-inline-item px-2'>
                                                    <Link
                                                        to='#'
                                                        id={'delete' + homepageBanner.id}
                                                        onClick={() => handleDeleteHomepageBanner(homepageBanner.id)}
                                                    >
                                                        <i className='bx bxs-trash' />
                                                        <UncontrolledTooltip placement='top' target={'delete' + homepageBanner.id}>
                                                            Delete
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                </li>
                                            )}
                                            <li className='list-inline-item px-2'>
                                                <Link
                                                    to='#'
                                                    id={'publish' + homepageBanner.id}
                                                    onClick={() =>
                                                        togglePublishHomepageBanner(homepageBanner.id, homepageBanner.isPublished)
                                                    }
                                                >
                                                    <i
                                                        className={`bx bx-check-circle ${homepageBanner.isPublished ? 'color-green' : ''}`}
                                                    />
                                                    <UncontrolledTooltip placement='top' target={'publish' + homepageBanner.id}>
                                                        Publish
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                        </ul>
                                    </Th>
                                </Tr>
                            ))
                        ) : (
                            <p className='text-center'>No homepage banner available.</p>
                        )}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
};
