import Axios from 'axios';
import { notification } from '../../components/Utils/Notification';

const interceptor = (store: any) => {
    Axios.interceptors.request.use(
        (conf) => {
            // you can add some information before send it.
            // conf.headers['Auth'] = 'some token'
            return conf;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    Axios.interceptors.response.use(
        (next) => {
            return Promise.resolve(next);
        },
        (error) => {
            if (error.response.status === 500) {
                if (error.response.data && error.response.data.message) {
                    if (error.response.data.message === '') {
                        notification('error', 'Internal Server Error');
                    } else {
                        notification('error', error.response.data.message);
                    }
                } else {
                    if (error.response.data) notification('error', error.response.data);
                }
            } else if (error.response.status === 404) {
                notification('error', error.response.data);
            } else {
                notification('error', error.response.data);
            }
            return Promise.reject(error);
        }
    );
};
export default interceptor;
