import { isEmpty, SecondaryBannerInitialValues, SecondaryBannerSchema } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';
import { InputField } from '../../../components/Common/InputField';
import { ModalPopup } from '../../../components/Common/ModalPopup';
import PageWrapper from '../../../components/Common/PageWrapper';
import { notification } from '../../../components/Utils/Notification';
import { useCreateSecondaryBannerMutation } from '../../../generated/graphql';
import { toErrorMap } from '../../../util/toErrorMap';
import SecondaryBannerListTable from './Components/SecondaryBannerListTable';

interface SecondaryBannerProps {}

const SecondaryBanner: React.FC<SecondaryBannerProps> = () => {
    const [isSecondaryBannerModalOpen, setIsSecondaryBannerModalOpen] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>('No file chosen');
    const [imageToUpload, setImageToUpload] = useState<any>(undefined);

    const [createSecondaryBanner, { loading: createSecondaryBannerLoading }] = useCreateSecondaryBannerMutation();

    const handleClosed = () => {
        setImageName('No file chosen');
        setImageToUpload(undefined);
        setIsSecondaryBannerModalOpen(false);
    };

    const imageupload = (e: any) => {
        if (!isEmpty(e.target.files)) {
            setImageName(e.target.files[0].name);
            setImageToUpload(e.target.files[0]);
        } else {
            setImageName('No file chosen');
            setImageToUpload(undefined);
        }
    };

    return (
        <PageWrapper pageTitle='Secondary Banner' pageCategory='FLD Store' breadCrumbTitle='Banner' breadCrumbItem='Secondary Banner'>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>FLD Store Secondary Banner</CardTitle>
                            <CardSubtitle className='mb-2'>List of secondary banner to use in FLD Store homepage.</CardSubtitle>
                            <SecondaryBannerListTable />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ModalPopup isOpen={isSecondaryBannerModalOpen} handleClosed={handleClosed} size='md' title='Add New Secondary Banner'>
                <Formik
                    enableReinitialize
                    initialValues={SecondaryBannerInitialValues}
                    validationSchema={SecondaryBannerSchema}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            if (imageToUpload === undefined) {
                                return notification('error', 'Secondary Banner image cannot be empty');
                            }
                            const response = await createSecondaryBanner({
                                variables: {
                                    input: {
                                        title: values.title,
                                        image: imageToUpload,
                                    },
                                },
                                update: (cache) => {
                                    cache.evict({ fieldName: 'secondaryBanners' });
                                },
                            });

                            if (response?.data?.createSecondaryBanner.errors) {
                                const errors = response.data.createSecondaryBanner.errors;
                                setErrors(toErrorMap(errors));
                                errors.forEach((err) => {
                                    notification('error', err.message);
                                });
                            } else if (response.data?.createSecondaryBanner) {
                                notification('success', 'Secondary Banner created!');
                                handleClosed();
                            }
                        } catch (error) {
                            notification('error', error.message);
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form action='#' method='POST'>
                            <InputField
                                name='title'
                                label='Secondary Banner Title'
                                placeholder='Secondary Banner Title'
                                type='text'
                                maxLength={30}
                                required
                            />
                            <InputField
                                name='image'
                                label='Secondary Banner Image - Size (1200x240)'
                                placeholder='Secondary Banner Image'
                                type='file'
                                fileInput
                                fileInputLabel={imageName}
                                accept='image/*'
                                onChange={imageupload}
                                required
                            />
                            <div className='modal-footer p-0'>
                                <Button type='submit' color='primary' disabled={isSubmitting || createSecondaryBannerLoading}>
                                    {isSubmitting ? (
                                        <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                                    ) : (
                                        'Save Banner'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalPopup>
            <div className='text-sm-right save-changes-button'>
                <Button
                    type='button'
                    color='primary'
                    className='btn-rounded me-1 waves-effect waves-light'
                    onClick={() => setIsSecondaryBannerModalOpen(true)}
                >
                    <i className='mdi mdi-plus me-1' />
                    Add New Secondary Banner
                </Button>
            </div>
        </PageWrapper>
    );
};

export default SecondaryBanner;
