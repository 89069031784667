import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

interface CardWrapperProps {
    title: string;
    subtitle: string;
}

const CardWrapper: React.FC<CardWrapperProps> = ({ ...props }) => {
    return (
        <Card>
            <CardBody>
                <CardTitle>{props.title}</CardTitle>
                <CardSubtitle className='mb-3'>{props.subtitle}</CardSubtitle>
                {props.children}
            </CardBody>
        </Card>
    );
};

export default CardWrapper;
