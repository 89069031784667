import { FLDFAQInitialValues, FLDFAQSchema, sortKeys, trimStringInObject } from '@footykids/common';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from '../../../components/Common/InputField';
import { ModalPopup } from '../../../components/Common/ModalPopup';
import PageWrapper from '../../../components/Common/PageWrapper';
import { notification } from '../../../components/Utils/Notification';
import { useCreateFldfaqMutation, useGetFldfaqListQuery } from '../../../generated/graphql';
import { toErrorMap } from '../../../util/toErrorMap';
import FAQComponent from './Components/FAQComponent';

interface FLDFAQProps {}

const FLDFAQ: React.FC<FLDFAQProps> = () => {
    const [isFLDFAQModalOpen, setIsFLDFAQModalOpen] = useState<boolean>(false);
    const { loading, data } = useGetFldfaqListQuery();
    const [faqList, setFAQList] = useState<any>(undefined);

    const [createFLDFAQ, { loading: createFLDFAQLoading }] = useCreateFldfaqMutation();

    useEffect(() => {
        if (data && data.getFLDFAQList) {
            let grouped = Object.create(null);
            data.getFLDFAQList.forEach((faq) => {
                grouped[faq.category] = grouped[faq.category] || [];
                grouped[faq.category].push(faq);
            });
            setFAQList(sortKeys(grouped));
        }
    }, [data]);

    const handleClosed = () => {
        setIsFLDFAQModalOpen(false);
    };

    return (
        <PageWrapper pageTitle='FAQ Settings' pageCategory='FLD Store' breadCrumbTitle='FLD' breadCrumbItem='FAQ'>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>FLD Store FAQ</CardTitle>
                            <CardSubtitle className='mb-2'>Setup FAQ questions here</CardSubtitle>
                            <Row className='pt-3'>{!loading && faqList && <FAQComponent FAQList={faqList} />}</Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div className='text-sm-right save-changes-button'>
                <Button
                    type='button'
                    color='primary'
                    className='btn-rounded me-1 waves-effect waves-light'
                    onClick={() => setIsFLDFAQModalOpen(true)}
                >
                    <i className='mdi mdi-plus me-1' />
                    Add FAQ
                </Button>
            </div>
            <ModalPopup isOpen={isFLDFAQModalOpen} handleClosed={handleClosed} size='md' title='Add New FAQ'>
                <Formik
                    enableReinitialize
                    initialValues={FLDFAQInitialValues}
                    validationSchema={FLDFAQSchema}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            const response = await createFLDFAQ({
                                variables: {
                                    input: trimStringInObject(values),
                                },
                                update: (cache) => {
                                    cache.evict({ fieldName: 'getFLDFAQList' });
                                },
                            });

                            if (response?.data?.createFLDFAQ.errors) {
                                const errors = response.data.createFLDFAQ.errors;
                                setErrors(toErrorMap(errors));
                                errors.forEach((err) => {
                                    notification('error', err.message);
                                });
                            } else if (response.data?.createFLDFAQ.FLDFAQCreated) {
                                notification('success', 'FAQ Created!');
                                handleClosed();
                            }
                        } catch (error) {
                            notification('error', error.message);
                        }
                    }}
                >
                    {({ setFieldValue, isSubmitting, errors, touched }) => (
                        <Form action='#' method='POST'>
                            <FormGroup className='mb-3'>
                                <Label htmlFor='category' className='form-label'>
                                    Category <span className='text-danger'>*</span>
                                </Label>
                                <CustomInput
                                    name='category'
                                    id='category'
                                    type='select'
                                    className='form-select'
                                    onChange={(e) => {
                                        setFieldValue('category', e.target.value);
                                    }}
                                >
                                    <option value='General'>General</option>
                                    <option value='Orders & Payments'>Orders & Payments</option>
                                    <option value='Shipping & Delivery'>Shipping & Delivery</option>
                                    <option value='Returns & Refunds'>Returns & Refunds</option>
                                    <option value='Selling & Billing'>Selling & Billing</option>
                                    <option value='Discount & Promo Code'>Discount & Promo Code</option>
                                    <option value='Others'>Others</option>
                                </CustomInput>
                                <div>{errors.category && touched.category && <span className='text-danger'>{errors}</span>}</div>
                            </FormGroup>
                            <InputField name='question' label='Question' placeholder='Question' type='text' maxLength={160} required />
                            <InputField name='answer' label='Answer' placeholder='Answer' type='text' textarea rows={4} required />
                            <div className='modal-footer p-0'>
                                <Button type='submit' color='primary' disabled={isSubmitting || createFLDFAQLoading}>
                                    {isSubmitting ? (
                                        <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                                    ) : (
                                        'Save FAQ'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalPopup>
        </PageWrapper>
    );
};

export default FLDFAQ;
