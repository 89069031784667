import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import digitalocean from '../../assets/images/brands/digitalocean.svg';
import footykids from '../../assets/images/brands/fk.svg';
import fka from '../../assets/images/brands/fka.svg';
import fkl from '../../assets/images/brands/fkl.svg';
import fkoriginal from '../../assets/images/brands/fkoriginal.svg';
import fld from '../../assets/images/brands/fld.svg';
// Redux Store
import { changeSidebarType, toggleLeftmenu } from '../../store/layout/actions';
// Import menuDropdown
import LanguageDropdown from '../Common/TopbarDropdown/LanguageDropdown';
import NotificationDropdown from '../Common/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../Common/TopbarDropdown/ProfileMenu';

interface HeaderProps {
    leftSideBarType?: any;
    toggleLeftmenu?: any;
    leftMenu?: any;
    toggleMenuCallback?: any;
    changeSidebarType?: any;
}

const Header: React.FC<HeaderProps> = ({ leftSideBarType, toggleLeftmenu, leftMenu, changeSidebarType }) => {
    const [search, setsearch] = useState(false);
    const [socialDrp, setsocialDrp] = useState(false);

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function tToggle() {
        toggleLeftmenu(!leftMenu);
        if (leftSideBarType === 'default') {
            changeSidebarType('condensed', isMobile);
        } else if (leftSideBarType === 'condensed') {
            changeSidebarType('default', isMobile);
        }
    }

    return (
        <React.Fragment>
            <header id='page-topbar'>
                <div className='navbar-header'>
                    <div className='d-flex'>
                        <button
                            type='button'
                            onClick={() => {
                                tToggle();
                            }}
                            className='btn btn-sm px-3 font-size-16 header-item waves-effect'
                            id='vertical-menu-btn'
                        >
                            <i className='fa fa-fw fa-bars' />
                        </button>
                    </div>
                    <div className='d-flex'>
                        <div className='dropdown d-inline-block d-lg-none ms-2'>
                            <button
                                onClick={() => {
                                    setsearch(!search);
                                }}
                                type='button'
                                className='btn header-item noti-icon waves-effect'
                                id='page-header-search-dropdown'
                            >
                                <i className='mdi mdi-magnify' />
                            </button>
                            <div
                                className={
                                    search
                                        ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show'
                                        : 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'
                                }
                                aria-labelledby='page-header-search-dropdown'
                            >
                                <form className='p-3'>
                                    <div className='form-group m-0'>
                                        <div className='input-group'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search ...'
                                                aria-label="Recipient's username"
                                            />
                                            <div className='input-group-text'>
                                                <button className='btn btn-primary' type='submit'>
                                                    <i className='mdi mdi-magnify' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <LanguageDropdown />

                        <Dropdown
                            className='d-none d-lg-inline-block ms-1'
                            isOpen={socialDrp}
                            toggle={() => {
                                setsocialDrp(!socialDrp);
                            }}
                        >
                            <DropdownToggle className='btn header-item noti-icon ' tag='button'>
                                <i className='bx bx-customize' />
                            </DropdownToggle>
                            <DropdownMenu className='dropdown-menu-lg dropdown-menu-end'>
                                <div className='px-lg-2'>
                                    <Row className='no-gutters'>
                                        <Col>
                                            <a
                                                className='dropdown-icon-item'
                                                href='https://footykids.com.my'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <img src={footykids} alt='Footykids' />
                                                <span>Footykids</span>
                                            </a>
                                        </Col>
                                        <Col>
                                            <a
                                                className='dropdown-icon-item'
                                                href='https://academy.footykids.com.my'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <img src={fka} alt='FKA' />
                                                <span>FKA</span>
                                            </a>
                                        </Col>
                                        <Col>
                                            <a
                                                className='dropdown-icon-item'
                                                href='https://league.footykids.com.my'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <img src={fkl} alt='FKL' />
                                                <span>FKL</span>
                                            </a>
                                        </Col>
                                    </Row>

                                    <Row className='no-gutters'>
                                        <Col>
                                            <a
                                                className='dropdown-icon-item'
                                                href='https://fld.footykids.com.my'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <img src={fld} alt='FLD Store' />
                                                <span>FLD Store</span>
                                            </a>
                                        </Col>
                                        <Col>
                                            <a
                                                className='dropdown-icon-item'
                                                href='https://footykids.com.my'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <img src={fkoriginal} alt='FK Original' />
                                                <span>FK Original</span>
                                            </a>
                                        </Col>
                                        <Col>
                                            <a
                                                className='dropdown-icon-item'
                                                href='https://digitalocean.com'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                <img src={digitalocean} alt='Digital Ocean' />
                                                <span>Digital Ocean</span>
                                            </a>
                                        </Col>
                                    </Row>
                                </div>
                            </DropdownMenu>
                        </Dropdown>

                        <NotificationDropdown />
                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

const mapStatetoProps = (state: any) => {
    const { leftMenu, leftSideBarType } = state.Layout;
    return { leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
    toggleLeftmenu,
    changeSidebarType,
})(Header);
