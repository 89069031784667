import { convertCentsToPrice } from '@footykids/common';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FldData } from '../../generated/graphql';
import FLDEarning from './FLDEarning';

interface FLDDataProps {
    FLDData: FldData;
}

const FLDData: React.FC<FLDDataProps> = ({ FLDData }) => {
    return (
        <React.Fragment>
            <Row>
                <Col sm='4'>
                    <Card>
                        <CardBody>
                            <div className='d-flex align-items-center mb-3'>
                                <div className='avatar-xs me-3'>
                                    <span className='avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18'>
                                        <i className='bx bx-receipt' />
                                    </span>
                                </div>
                                <h5 className='font-size-14 mb-0'>FLD Total Order</h5>
                            </div>
                            <div className='text-muted mt-4'>
                                <h4>{FLDData.orders.currentTotalOrder} Order</h4>
                                <div className='d-flex'>
                                    <span className='text-truncate'>Total Order For Current Month:</span>
                                    <span className='ms-2'>{FLDData.orders.currentMonthTotalOrder} Order</span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm='4'>
                    <Card>
                        <CardBody>
                            <div className='d-flex align-items-center mb-3'>
                                <div className='avatar-xs me-3'>
                                    <span className='avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18'>
                                        <i className='far fa-credit-card' />
                                    </span>
                                </div>
                                <h5 className='font-size-14 mb-0'>FLD Total Sales</h5>
                            </div>
                            <div className='text-muted mt-4'>
                                <h4>RM {convertCentsToPrice(FLDData.payments.currentTotalPayment)}</h4>
                                <div className='d-flex'>
                                    <span className='text-truncate'>Total Sales For Current Month:</span>
                                    <span className='ms-2'>RM {convertCentsToPrice(FLDData.payments.currentMonthTotalPayment)}</span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm='4'>
                    <Card>
                        <CardBody>
                            <div className='d-flex align-items-center mb-3'>
                                <div className='avatar-xs me-3'>
                                    <span className='avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18'>
                                        <i className='fas fa-dollar-sign' />
                                    </span>
                                </div>
                                <h5 className='font-size-14 mb-0'>FLD Average Price</h5>
                            </div>
                            <div className='text-muted mt-4'>
                                <h4>RM {convertCentsToPrice(FLDData.averagePricePerOrder)}</h4>
                                <div className='d-flex'>
                                    <span className='text-truncate'>Average Price Per Order</span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <FLDEarning FLDData={FLDData} />
        </React.Fragment>
    );
};

export default FLDData;
