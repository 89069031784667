import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Col, Card, CardBody, Row } from 'reactstrap';
import { convertCentsToPrice, convertToDecimalPrice } from '@footykids/common';
import { SalesAnalyticData } from '../../generated/graphql';

interface SalesAnalyticProps {
    salesAnalytics: SalesAnalyticData;
}

const SalesAnalytic: React.FC<SalesAnalyticProps> = ({ salesAnalytics }) => {
    const series = [
        convertToDecimalPrice(salesAnalytics.fldTotalPayment),
        convertToDecimalPrice(salesAnalytics.fklTotalPayment),
        convertToDecimalPrice(salesAnalytics.fkaTotalPayment),
    ];
    const options = {
        labels: ['FLD Store', 'FK League', 'FK Academy'],
        colors: ['#222222', '#FFF200', '#006838'],
        legend: { show: false },
        plotOptions: {
            pie: {
                donut: {
                    size: '50%',
                },
            },
        },
    };
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className='card-title mb-4'>Sales Analytics</h4>

                    <div>
                        <div id='donut-chart'>
                            <ReactApexChart options={options} series={series} type='donut' height={245} className='apex-charts' />
                        </div>
                    </div>

                    <div className='text-center text-muted'>
                        <Row>
                            <Col xs='4'>
                                <div className='mt-4'>
                                    <p className='mb-2 text-truncate'>FLD Store</p>
                                    <h5>RM {convertCentsToPrice(salesAnalytics.fldTotalPayment)}</h5>
                                </div>
                            </Col>
                            <Col xs='4'>
                                <div className='mt-4'>
                                    <p className='mb-2 text-truncate'>FK League</p>
                                    <h5>RM {convertCentsToPrice(salesAnalytics.fklTotalPayment)}</h5>
                                </div>
                            </Col>
                            <Col xs='4'>
                                <div className='mt-4'>
                                    <p className='mb-2 text-truncate'>FK Academy</p>
                                    <h5>RM {convertCentsToPrice(salesAnalytics.fkaTotalPayment)}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default SalesAnalytic;
