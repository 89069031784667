import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import CardWrapper from '../../../../components/Common/CardWrapper';
import Preloader from '../../../../components/Layout/Preloader';
import { notification } from '../../../../components/Utils/Notification';
import {
    useCreateProductImagesMutation,
    useDeleteProductImageMutation,
    useProductQuery,
    useSetDefaultImageMutation
} from '../../../../generated/graphql';
import { UPLOAD_PRODUCT_IMAGES_RESET } from '../../../../store/FKStore/product/constants';
import { ProductImageState } from '../../../../store/FKStore/product/state';
import { AppDispatch, RootState } from '../../../../store/store';
import { serveImage } from '../../../../util/Helper';

interface ProductImageProps {
    productId: string;
}

const ProductImage: React.FC<ProductImageProps> = ({ productId }) => {
    const [pendingImages, setPendingImages] = useState<File[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const imageUploadDetails = useSelector<RootState, ProductImageState>((state: RootState) => state.uploadProductImage);

    const [setDefaultImage] = useSetDefaultImageMutation();
    const [deleteImage] = useDeleteProductImageMutation();
    const [createProductImages, { loading: createProductImageLoading }] = useCreateProductImagesMutation();
    const {
        data,
        loading: productLoading,
        refetch,
    } = useProductQuery({
        variables: {
            id: Number(productId),
        },
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (!productLoading && imageUploadDetails.images === true && imageUploadDetails.success === true) {
            setPendingImages([]);
            refetch();
            dispatch({ type: UPLOAD_PRODUCT_IMAGES_RESET });
        }
    }, [data, productLoading, refetch, imageUploadDetails, dispatch]);

    const handleAcceptedFiles = (files: File[]) => {
        files.map((file: File) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        const tempImages = [...pendingImages];
        files.map((file: File) => tempImages.push(file));
        setPendingImages(tempImages);
    };

    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const handleRemoveImage = (event: any, i: number) => {
        event.preventDefault();
        const tempImages = [...pendingImages];
        tempImages.splice(i, 1);
        setPendingImages(tempImages);
    };

    const handleUploadImages = async (event: any) => {
        event.preventDefault();
        // const imagesForm = new FormData();
        // imagesForm.append('productId', productId);
        // imagesForm.append('productName', data?.product?.name || 'null');
        // imagesForm.append('type', 'images/product/');
        // pendingImages.forEach((img) => imagesForm.append('pendingImages', img));
        // dispatch(uploadProductImages(imagesForm));

        try {
            const response = await createProductImages({
                variables: {
                    input: {
                        productId: Number(productId),
                        productName: data?.product?.name || 'null',
                        type: 'images/product/',
                        images: pendingImages,
                    },
                },
                update: (cache: any) => {
                    cache.evict({ fieldName: 'product' });
                },
            });

            if (response.data?.createProductImages) {
                notification('success', 'Successful Uploaded!');
                setPendingImages([]);
            }
        } catch (error) {
            notification('error', error.message);
        }
    };

    const defaultImageChange = async (image: any) => {
        const imageId = image.id;
        await setDefaultImage({
            variables: { productId: Number(productId), imageId },
            update: (cache) => {
                cache.evict({ fieldName: 'product' });
            },
        });
    };

    const deleteProductImage = async (imageId: number) => {
        try {
            const response = await deleteImage({
                variables: { productId: Number(productId), imageId },
                update: (cache: any) => {
                    cache.evict({ id: 'ProductImage:' + imageId });
                },
            });

            if (response.data?.deleteProductImage) {
                notification('success', 'Successful Delete!');
            }
        } catch (error) {
            notification('error', error.message);
        }
    };

    if (productLoading) {
        return <Preloader />;
    }

    return (
        <CardWrapper title='Product Images' subtitle='Upload multiple product images here. Image size 1:1 recommended (e.g.600px x 600px)'>
            {data?.product?.images && data?.product?.images.length > 0 ? (
                <Row className='mb-4 image-selector'>
                    {data?.product?.images.map((image: any, index: number) => (
                        <Col key={index + image.name} md={2} xs={6} className='mb-2'>
                            <input
                                type='radio'
                                name='image'
                                value={image.id}
                                defaultChecked={image.isDefault}
                                onChange={() => defaultImageChange(image)}
                            />
                            <label htmlFor={image.name} className='square-image'>
                                <img alt={image.name} src={serveImage(image.path)} />
                            </label>
                            {image.isDefault ? null : (
                                <>
                                    <Button id={image.id} className='btn-sm w-100' color='primary' type='button'>
                                        Set as default
                                    </Button>
                                    <Button
                                        className='btn-sm w-100 mt-2'
                                        color='danger'
                                        type='button'
                                        onClick={() => deleteProductImage(image.id)}
                                    >
                                        Delete
                                    </Button>
                                </>
                            )}
                        </Col>
                    ))}
                </Row>
            ) : null}
            <Dropzone onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                    <div className='dropzone'>
                        <div className='dz-message needsclick mt-2' {...getRootProps()}>
                            <input {...getInputProps()} accept='image/jpeg,image/png' />
                            <div className='mb-3'>
                                <i className='display-4 text-muted bx bxs-cloud-upload' />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                        </div>
                    </div>
                )}
            </Dropzone>
            {/* <div>{pendingImages ? <span className='span-error'>{errors.pendingImages}</span> : null}</div> */}
            <div className='dropzone-previews mt-3' id='file-previews'>
                {pendingImages?.map((file: any, i: any) => {
                    return (
                        <Card
                            className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                            key={i + '-file'}
                        >
                            <div className='p-2'>
                                <Row className='align-items-center'>
                                    <Col className='col-auto'>
                                        <img
                                            data-dz-thumbnail=''
                                            height='80'
                                            className='avatar-sm rounded bg-light'
                                            alt={file.name}
                                            src={file.preview}
                                        />
                                    </Col>
                                    <Col>
                                        <Link to='#' className='text-muted fw-bold'>
                                            {file.name}
                                        </Link>
                                        <p className='mb-0'>
                                            <strong>{file.formattedSize}</strong>
                                        </p>
                                    </Col>
                                    <Col className='text-end'>
                                        <Button className='btn-sm mb-0' color='danger' onClick={(e) => handleRemoveImage(e, i)}>
                                            <i className='bx bx-trash-alt me-1' />
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    );
                })}
                {pendingImages.length !== 0 ? (
                    <Button color='success' className='w-100' onClick={handleUploadImages}>
                        {imageUploadDetails.loading || createProductImageLoading ? (
                            <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                        ) : (
                            'Upload'
                        )}
                    </Button>
                ) : null}
            </div>
        </CardWrapper>
    );
};

export default ProductImage;
