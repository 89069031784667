import React from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
// Import Scss
import './assets/scss/theme.scss';
// Import Layout
import Layout from './components/Layout';
import NonAuthLayout from './components/NonAuthLayout';
// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';
// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

const App = () => {
    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {authRoutes.map((route, idx) => (
                        <Authmiddleware
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={false}
                        />
                    ))}

                    {userRoutes.map((route, idx) => (
                        <Authmiddleware
                            key={idx}
                            path={route.path}
                            component={route.component}
                            roles={route.roles}
                            layout={Layout}
                            isAuthProtected={true}
                            exact
                        />
                    ))}
                </Switch>
            </Router>
        </React.Fragment>
    );
};

export default App;
