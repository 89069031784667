import { Form, Formik } from 'formik';
import React from 'react';
import Loader from 'react-loader-spinner';
import { Button, Col, Row } from 'reactstrap';
import { InputField } from '../../../components/Common/InputField';
import { ModalPopup } from '../../../components/Common/ModalPopup';
import { notification } from '../../../components/Utils/Notification';
import { CreateAdminInitialValues } from '@footykids/common';
import { CreateAdminSchema } from '@footykids/common';

interface AddUserModalProps {
    isOpen: boolean;
    handleClosed: any;
}

const AddUserModal: React.FC<AddUserModalProps> = ({ isOpen, handleClosed }) => {
    return (
        <ModalPopup isOpen={isOpen} handleClosed={handleClosed} size='md' title='Add New Support Admin'>
            <Formik
                enableReinitialize
                initialValues={CreateAdminInitialValues}
                validationSchema={CreateAdminSchema}
                onSubmit={async (values, { setErrors }) => {
                    try {
                        console.log(values);
                    } catch (error) {
                        notification('error', error.message);
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Row>
                            <Col>
                                <InputField name='username' label='Username' placeholder='Username' type='text' required />
                                <InputField name='email' label='Email' placeholder='Email' type='email' required />
                                <span className='text-danger'>Password link will be sent to this email. Make sure email is correct!</span>
                            </Col>
                        </Row>

                        <div className='modal-footer p-0'>
                            <Button type='submit' color='primary' disabled={isSubmitting}>
                                {isSubmitting ? <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} /> : 'Save User'}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalPopup>
    );
};

export default AddUserModal;
