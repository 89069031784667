import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import { withRouter } from 'react-router';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PageWrapper from '../../../components/Common/PageWrapper';
import { useOrdersQuery } from '../../../generated/graphql';
import OrderCount from './Components/OrderCount';
import OrderDetailsModal from './Components/OrderDetailsModal';
import OrderListTable from './Components/OrderListTable';
import OrderTableFilter from './Components/OrderTableFilter';
import queryString from 'query-string';

interface OrderListProps {}

const OrderList: React.FC<OrderListProps & RouteComponentProps> = ({ ...props }) => {
    const { search } = props.location;
    const currentQuery = queryString.parse(search);
    const history = useHistory();
    const [pageCount, setPageCount] = useState<number>(0);
    const [sizePerPage] = useState<number>(15);
    const [searchInput, setSearchInput] = useState<string>('');
    const [detailsModal, setDetailsModal] = useState<boolean>(false);
    const [modalOrderId, setModalOrderId] = useState<number>(0);

    const orderStatus = currentQuery.orderStatus?.toString() || 'ALL';
    const paymentStatus = currentQuery.paymentStatus?.toString() || 'ALL';
    const pageNumber = Number(currentQuery.pageNumber?.toString()) || 0;
    const searchKeyword = currentQuery.searchKeyword?.toString() || searchInput;
    const dateFilter = (currentQuery.dateFilter as any) || [];

    const [options, setOptions] = useState({
        orderStatus: orderStatus,
        paymentStatus: paymentStatus,
        pageNumber: pageNumber,
        searchKeyword: searchKeyword,
        sizePerPage: sizePerPage,
        dateFilter: dateFilter,
    });

    const {
        data: orderList,
        loading: orderListLoading,
        refetch,
    } = useOrdersQuery({
        variables: {
            option: options,
        },
    });

    useEffect(() => {
        history.push({ pathname: 'orders', search: queryString.stringify(options) });
    }, [options, history]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, searchKeyword]);

    useEffect(() => {
        if (orderList && orderList.orders.pageCount) {
            setPageCount(orderList.orders.pageCount);
        }
    }, [orderList]);

    const handlePageClick = (page: any) => {
        // setPageNumber(page.selected);
        setOptions({ ...options, pageNumber: page.selected });
    };

    const toggleDetailsModal = () => {
        setDetailsModal(!detailsModal);
    };

    const onSearchKeyword = (event: any) => {
        if (event.key === 'Enter') {
            setOptions({ ...options, searchKeyword: searchInput });
        }
    };

    return (
        <PageWrapper pageTitle='Orders' breadCrumbTitle='Orders' breadCrumbItem='List' pageCategory='FLD Store'>
            <Row>
                <Col lg='12'>
                    <Card>
                        <CardBody>
                            <OrderTableFilter
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                onSearchKeyword={onSearchKeyword}
                                options={options}
                                setOptions={setOptions}
                            />
                            <Row>
                                {orderList && orderList.orders ? (
                                    <>
                                        <OrderDetailsModal orderId={modalOrderId} isOpen={detailsModal} toggle={toggleDetailsModal} />
                                        <div className='table-rep-plugin p-2 w-100'>
                                            <OrderListTable
                                                orderList={orderList}
                                                orderListLoading={orderListLoading}
                                                setModalOrderId={setModalOrderId}
                                                toggleDetailsModal={toggleDetailsModal}
                                            />
                                            <OrderCount />
                                            <div className='pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination col'>
                                                <ReactPaginate
                                                    initialPage={options.pageNumber}
                                                    forcePage={options.pageNumber}
                                                    previousLabel={'Prev'}
                                                    previousClassName='page-item'
                                                    previousLinkClassName='page-link'
                                                    nextLabel={'Next'}
                                                    nextClassName='page-item'
                                                    nextLinkClassName='page-link'
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={'pagination react-bootstrap-table-page-btns-ul'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName='page-link'
                                                    activeClassName={'active'}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='text-center mt-4'>
                                        <Loader type='ThreeDots' color='primary' height={80} width={80} timeout={0} />
                                    </div>
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default withRouter(OrderList);
