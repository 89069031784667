import { useApolloClient } from '@apollo/client';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { InputField } from '../../components/Common/InputField';
import { notification } from '../../components/Utils/Notification';
import { AdminDocument, useAdminLoginMutation, useAdminQuery } from '../../generated/graphql';
import { toErrorMap } from '../../util/toErrorMap';
import CarouselPage from './CarouselPage';
import AuthCardWrapper from './Components/AuthCardWrapper';
import { LoginSchema } from './Utils/FormSchemaValidation';

interface LoginProps {
    t: any;
}

interface StateType {
    from: { pathname: string };
}

const Login: React.FC<LoginProps & WithTranslationProps> = ({ t }) => {
    const history = useHistory();
    const [adminLogin] = useAdminLoginMutation();
    const { data } = useAdminQuery();
    const apolloClient = useApolloClient();
    const { state: location } = useLocation<StateType>();

    useEffect(() => {
        if (data !== undefined && data?.admin !== null) {
            if (data?.admin?.isAdmin) {
                history.push('/dashboard');
            }
        }
    }, [data, history]);

    return (
        <React.Fragment>
            <div>
                <Container fluid className='p-0'>
                    <Row className='g-0'>
                        <CarouselPage />
                        <Col xl={3}>
                            {/* <Col sm={12} md={4} className='offset-md-4'> */}
                            <AuthCardWrapper>
                                <Formik
                                    initialValues={{ email: '', password: '' }}
                                    validationSchema={LoginSchema}
                                    onSubmit={async (values, { setErrors }) => {
                                        await apolloClient.resetStore();
                                        try {
                                            const response = await adminLogin({
                                                variables: values,
                                                update: (cache, { data }) => {
                                                    cache.writeQuery({
                                                        query: AdminDocument,
                                                        data: {
                                                            __typename: 'Query',
                                                            admin: data?.adminLogin.user,
                                                        },
                                                    });
                                                },
                                            });
                                            if (response?.data?.adminLogin.errors) {
                                                const errors = response.data.adminLogin.errors;
                                                setErrors(toErrorMap(errors));
                                                errors.forEach((err) => {
                                                    notification('error', err.message);
                                                });
                                            } else if (response?.data?.adminLogin.user) {
                                                if (location.from.pathname) {
                                                    history.push(location.from.pathname);
                                                } else {
                                                    history.push('/dashboard');
                                                }
                                            }
                                        } catch (error) {
                                            notification('error', error.message);
                                        }
                                    }}
                                >
                                    {({ errors, touched, dirty, isValid, isSubmitting }) => (
                                        <Form>
                                            <InputField name='email' label='Email' placeholder='Admin Email' type='text' />
                                            <InputField name='password' label='Password' placeholder='Enter Password' type='password' />

                                            <div className='mt-3 d-grid text-center'>
                                                <Button
                                                    color='primary'
                                                    className='btn-block waves-effect waves-light'
                                                    type='submit'
                                                    disabled={!(dirty && isValid) || isSubmitting}
                                                >
                                                    {isSubmitting ? (
                                                        <Loader type='ThreeDots' color='white' height={20} width={20} timeout={0} />
                                                    ) : (
                                                        'Login'
                                                    )}
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </AuthCardWrapper>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Login);
