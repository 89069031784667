import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { CardImg, UncontrolledTooltip } from 'reactstrap';
import Preloader from '../../../../components/Layout/Preloader';
import { notification } from '../../../../components/Utils/Notification';
import {
    SecondaryBannerDataFragment,
    useDeleteSecondaryBannerMutation,
    useSecondaryBannersQuery,
    useToggleIsPublishSecondaryBannerMutation,
} from '../../../../generated/graphql';
import { serveImage } from '../../../../util/Helper';

interface SecondaryBannerListTableProps {}

const SecondaryBannerListTable: React.FC<SecondaryBannerListTableProps> = () => {
    const { data: secondaryBannerData, loading: secondaryBannerLoading } = useSecondaryBannersQuery();
    const [deleteSecondaryBanner] = useDeleteSecondaryBannerMutation();
    const [toggleIsPublishSecondaryBanner] = useToggleIsPublishSecondaryBannerMutation();

    const handleDeleteSecondaryBanner = async (id: number) => {
        const response = await deleteSecondaryBanner({
            variables: { id },
            update: (cache: any) => {
                cache.evict({ id: 'SecondaryBanner:' + id });
            },
        });

        if (response.data?.deleteSecondaryBanner) {
            notification('success', 'Successful Delete!');
        }
    };

    const togglePublishSecondaryBanner = async (id: number, isPublished: boolean) => {
        try {
            await toggleIsPublishSecondaryBanner({
                variables: { id },
                update: (cache: any) => {
                    cache.writeFragment({
                        id: 'SecondaryBanner:' + id,
                        fragment: gql`
                            fragment __ on SecondaryBanner {
                                isPublished
                            }
                        `,
                        data: { isPublished: !isPublished },
                    });
                },
            });
        } catch (error) {
            notification('error', error.message);
        }
    };

    if (secondaryBannerLoading) {
        return <Preloader />;
    }

    return (
        <div className='table-rep-plugin'>
            <div className='table-responsive mb-0' data-pattern='priority-columns'>
                <Table id='tech-companies-1' className='table'>
                    <Thead className='table-light'>
                        <Tr>
                            <Th>Banner Image</Th>
                            <Th>Banner Title</Th>
                            <Th data-priority='3'>Created At</Th>
                            <Th data-priority='6'>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {secondaryBannerData?.secondaryBanners ? (
                            secondaryBannerData.secondaryBanners.map((secondaryBanner: SecondaryBannerDataFragment, index: number) => (
                                <Tr key={index}>
                                    <Th style={{ width: '15%' }}>
                                        <CardImg
                                            src={serveImage(secondaryBanner.imagePath || '')}
                                            alt={secondaryBanner.title}
                                            className='img-fluid mx-auto rounded'
                                        />
                                    </Th>
                                    <Th style={{ width: '45%' }}>
                                        <Link to='#'>
                                            <h5 className='font-size-14 mb-1 text-dark'>{secondaryBanner.title}</h5>
                                        </Link>
                                    </Th>
                                    <Th style={{ width: '20%' }}>{dayjs(parseInt(secondaryBanner.createdAt)).format('LLL')}</Th>
                                    <Th style={{ width: '10%' }}>
                                        <ul className='list-inline font-size-20 contact-links mb-0'>
                                            {secondaryBanner.isPublished ? null : (
                                                <li className='list-inline-item px-2'>
                                                    <Link
                                                        to='#'
                                                        id={'delete' + secondaryBanner.id}
                                                        onClick={() => handleDeleteSecondaryBanner(secondaryBanner.id)}
                                                    >
                                                        <i className='bx bxs-trash' />
                                                        <UncontrolledTooltip placement='top' target={'delete' + secondaryBanner.id}>
                                                            Delete
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                </li>
                                            )}
                                            <li className='list-inline-item px-2'>
                                                <Link
                                                    to='#'
                                                    id={'publish' + secondaryBanner.id}
                                                    onClick={() =>
                                                        togglePublishSecondaryBanner(secondaryBanner.id, secondaryBanner.isPublished)
                                                    }
                                                >
                                                    <i
                                                        className={`bx bx-check-circle ${secondaryBanner.isPublished ? 'color-green' : ''}`}
                                                    />
                                                    <UncontrolledTooltip placement='top' target={'publish' + secondaryBanner.id}>
                                                        Publish
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                        </ul>
                                    </Th>
                                </Tr>
                            ))
                        ) : (
                            <p className='text-center'>No secondary banner available.</p>
                        )}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
};

export default SecondaryBannerListTable;
