import { ProductFormValues } from '@footykids/common';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import CardWrapper from '../../../../components/Common/CardWrapper';
import CKEditorComponent from '../../../../components/Common/CKEditorComponent';
import { InputField } from '../../../../components/Common/InputField';
import { ProductCategoryDataFragment, useProductCategoriesQuery } from '../../../../generated/graphql';

const ProductInfo: React.FC<{}> = () => {
    const [productCategoryList, setProductCategoryList] = useState<Array<any>>([]);
    const { data: productCategoriesData, loading: productCategoriesLoading } = useProductCategoriesQuery();

    const { values, setFieldValue, errors } = useFormikContext<ProductFormValues>();

    useEffect(() => {
        if (!productCategoriesLoading && productCategoriesData && productCategoriesData.productCategories) {
            handleProductCategories(productCategoriesData.productCategories);
        }
    }, [productCategoriesData, productCategoriesLoading]);

    const handleProductCategories = (data: ProductCategoryDataFragment[]) => {
        let productCategoriesArray: Array<any> = [];
        data.map((productCategory) => {
            return productCategoriesArray.push({ label: productCategory.name, value: productCategory.name });
        });
        setProductCategoryList(productCategoriesArray);
    };

    return (
        <CardWrapper title='Product Information' subtitle='Fill all information below'>
            <Row>
                <Col sm='6'>
                    <InputField name='name' placeholder='Product Name' label='Product Name' type='text' required />
                    <div className='mb-3'>
                        <label className='control-label'>
                            Product Category <span className='span-error'>*</span>
                        </label>
                        <Select
                            value={values.category}
                            isMulti={true}
                            onChange={(values) => {
                                setFieldValue('category', values);
                            }}
                            options={productCategoryList}
                            placeholder='Select Product Category'
                        />
                        <div>{errors.category ? <span className='span-error'>{errors.category}</span> : null}</div>
                    </div>
                    <InputField name='brand' placeholder='Product Brand' label='Product Brand' type='text' required />
                    <InputField
                        switches
                        name='isDiscount'
                        placeholder='Enable Product Discount?'
                        label='Enable Product Discount?'
                        switchLabel='Toggle to enable/disable discount for this product'
                        type='checkbox'
                    />
                    <InputField
                        name='discount'
                        placeholder={values.isDiscount ? 'Enter Discount Amount' : 'Discount Disabled'}
                        label='Product Discount'
                        type='text'
                        disabled={!values.isDiscount}
                    />
                    <InputField name='sku' placeholder='Product SKU' label='Product SKU' type='text' />
                </Col>
                <Col sm='6'>
                    <InputField name='metaTitle' placeholder='Product Meta Title' label='Product Meta Title' type='text' required />
                    <InputField
                        name='metaKeywords'
                        placeholder='Separate meta with comma ,'
                        label='Product Meta Keywords'
                        type='text'
                        required
                    />
                    <InputField
                        textarea
                        rows={5}
                        name='description'
                        placeholder='Product Description'
                        label='Product Description'
                        required
                    />
                    <InputField
                        textarea
                        rows={5}
                        name='metaDescription'
                        placeholder='Product Meta Description'
                        label='Product Meta Description'
                        maxLength={160}
                        required
                    />
                </Col>
                <Col sm='12'>
                    <CKEditorComponent name='longDescription' label='Product Long Description' required />
                </Col>
            </Row>
        </CardWrapper>
    );
};

export default ProductInfo;
