import { AdminType } from '@footykids/common';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Preloader from '../../components/Layout/Preloader';
import { useAdminQuery } from '../../generated/graphql';

interface AuthmiddlewareProps {
    isAuthProtected: boolean;
    component: any;
    location?: any;
    layout: any;
    path: any;
    exact?: boolean;
    roles?: AdminType[];
}

const Authmiddleware: React.FC<AuthmiddlewareProps> = ({ component: Component, layout: Layout, isAuthProtected, roles, ...rest }) => {
    const { data, loading } = useAdminQuery();

    useEffect(() => {}, [data]);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthProtected) {
                    if (data === undefined || data.admin === null || !data?.admin?.isAdmin) {
                        if (loading) {
                            return <Preloader />;
                        }
                        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
                    } else if (data.admin.isAdmin) {
                        // Check for Admin roles
                        if (roles && roles.indexOf(data.admin.adminType as AdminType) === -1) {
                            return <Redirect to={{ pathname: '/' }} />;
                        } else {
                            // Proceed to component
                            return (
                                <Layout>
                                    <Component {...props} />
                                </Layout>
                            );
                        }
                    }
                }

                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                );
            }}
        />
    );
};

export default Authmiddleware;
