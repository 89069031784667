import { FLDPrivacyPolicyType } from '@footykids/common';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Col, FormGroup, Label } from 'reactstrap';
import { useUpdatePrivacyPolicyMutation } from '../../../../generated/graphql';

interface PrivacyPolicyFormProps {
    privacyPolicyData?: any;
}

const PrivacyPolicyForm: React.FC<PrivacyPolicyFormProps> = ({ privacyPolicyData }) => {
    const [setPrivacyPolicy] = useUpdatePrivacyPolicyMutation();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [onEdit, setOnEdit] = useState<boolean>(false);

    useEffect(() => {
        if (privacyPolicyData && privacyPolicyData.value) {
            const data: FLDPrivacyPolicyType = JSON.parse(privacyPolicyData.value);
            if (data && data.content) {
                const blocksFromHTML = convertFromHTML(data.content);
                setEditorState(
                    EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap))
                );
            }
        }
    }, [privacyPolicyData]);

    const savePrivacyPolicy = async () => {
        await setPrivacyPolicy({
            variables: { text: getHtmlEditorContent() },
            update: (cache: any) => {
                cache.evict({ fieldName: 'getFLDSettings' });
            },
        }).then(() => setOnEdit(false));
    };

    const getHtmlEditorContent = () => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };

    return (
        <React.Fragment>
            <Col xs={12} className='mb-4'>
                <FormGroup row>
                    <Label className='col-lg-2'>
                        <span className='fw-bold'>Privacy Policy</span>
                        <br />
                        <small className='text-danger'>Set all privacy policy here.</small>
                    </Label>
                    <Col lg='10'>
                        <Editor
                            toolbarClassName='toolbarClassName'
                            wrapperClassName='wrapperClassName'
                            editorClassName='editorClassName'
                            placeholder='Place Your Content Here...'
                            editorState={editorState}
                            onEditorStateChange={(e) => setEditorState(e)}
                            readOnly={!onEdit}
                            toolbarHidden={!onEdit}
                        />
                        <div className='d-flex justify-content-between'>
                            {onEdit ? (
                                <span className='align-self-end text-danger'>Please save after made changes</span>
                            ) : (
                                <span className='align-self-end text-danger'>Currently on read-mode</span>
                            )}
                            {onEdit ? (
                                <Button color='success' className='mt-2 float-end' onClick={savePrivacyPolicy}>
                                    Save
                                </Button>
                            ) : (
                                <Button color='primary' className='mt-2 float-end' onClick={() => setOnEdit(true)}>
                                    Edit Privacy Policy
                                </Button>
                            )}
                        </div>
                    </Col>
                </FormGroup>
            </Col>
        </React.Fragment>
    );
};

export default PrivacyPolicyForm;
